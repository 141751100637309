import React, { useEffect, useContext, useState } from 'react';
import SkeletonResponsive from "../../../../../general/SkeletonResponsive";
import { getPacienteActual, getPerfilPsicologo, getTerapiaActual } from "../../../../../../reducers/terapeutaReducer";
import { bindActionCreators } from "redux";
import {
    consultarPerfilPaciente,
    consultarTareasPaciente,
    cambiarEstadoTarea,
    consultarTerapiaActiva,
    finalizarTerapia
} from "../../../../../../actions/terapeutaActions";
import { withRouter } from "react-router-dom";
import Switch from "react-switch";
import { connect } from "react-redux";
import { CargandoContext } from "../../../../../general/CargandoContext/CargandoContext";
import DialogoDosBotones from "../../../../../general/DialogoDosBotones";
import DialogoUnBoton from "../../../../../general/DialogoUnBoton";
import { OK } from "../../../../../../utilidades/Constantes";

function RevisionTerapia({ paciente, terapiaActiva, consultarPerfilPaciente, consultarTareas, consultarTerapia, match, history, perfil }) {
    let idPaciente = match.params.idPaciente
    let idTerapia = match.params.idTerapiaActiva
    let { correrIndicadorCarga, quitarIndicadorCarga } = useContext(CargandoContext)

    const [terapiaConsultada, setTerapiaConsultada] = useState(false)
    const [dialogoDosBotones, setDialogoDosBotones] = useState(false)
    const [dialogoUnBoton, setDialogoUnBoton] = useState(false)
    const [infoDialogo, setInfoDialogo] = useState({
        titulo: '',
        descripcion: '',
        boton: '',
        click: null,
        cerrar: null
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if ((!paciente || (paciente && !paciente.correo)))
            consultarPerfilPaciente({
                idPaciente: match.params.idPaciente
            })
        if (paciente && terapiaActiva && !terapiaConsultada) {
            consultarTerapia({
                idTerapia,
                idPaciente
            })
            setTerapiaConsultada(true)
        }
        if(terapiaActiva && !terapiaActiva.tareas){
            consultarTareas({
                idTerapia,
                idPaciente
            })
        }
    })

    const finalizarTerapiaPaciente = () => {
        setDialogoDosBotones(true)
    }

    const aceptarTerapia = async () => {
        correrIndicadorCarga()
        let res = await finalizarTerapia(idTerapia, idPaciente, perfil.uidDocumento)
        setDialogoDosBotones(false)
        quitarIndicadorCarga()
        if (res === OK) {
            history.push(`/terapeuta/pacientes/${idPaciente}`);
        }
        else {
            setInfoDialogo({
                titulo: 'Inténtalo de nuevo',
                descripcion: 'Parece que tenemos inconvenientes para finalizar la terapia, vuelvelo a intentar',
                cerrar: () => setDialogoUnBoton(false),
                boton: 'Aceptar',
                click: () => setDialogoUnBoton(false)
            })
        }
    }

    const pintarTareas = (listaTareas) => (
        listaTareas.map((tareas, i) => {
            return <div
                className="con-tareas" key={`tarea-${i}`}>
                {terapiaActiva.revisar && tareas.uidDoc === terapiaActiva.uidTarea && <div className="circulo-revisar" />}
                <p className="texto"
                    onClick={() => history.push(`/terapeuta/pacientes/${idPaciente}/terapia/${idTerapia}/tareas/${tareas.uidDoc}`)}>{tareas.nombre}</p>
                <div className="estado">
                    {tareas.finalizada ?
                        <p className="tarea-revisada">Tarea Revisada</p>
                        : <Switch
                            onColor="#FF7D51"
                            width={40}
                            height={20}
                            uncheckedIcon={false}
                            className={"switch-activo"}
                            checkedIcon={false}
                            activeBoxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
                            onChange={async () => {
                                correrIndicadorCarga()
                                await cambiarEstadoTarea(
                                    idTerapia, tareas.uidDoc, {
                                    nombrePaciente: paciente.nombre,
                                    nombreTarea: tareas.nombre,
                                    nombreTerapia: terapiaActiva.nombre,
                                    nombrePsicologo: perfil.nombre,
                                    correo: paciente.correo,
                                }
                                )
                                quitarIndicadorCarga()
                            }}
                            checked={tareas.uidDoc === terapiaActiva.uidTarea} />
                    }
                </div>
            </div>
        }
        ))


    let ready = terapiaActiva && terapiaActiva.nombre && terapiaConsultada && paciente && paciente.nombre

    return <div className="contenedor-revision-terapia">
        {dialogoDosBotones && <DialogoDosBotones titulo={'Finalizar terapia'}
            color={'naranja'}
            boton2={'Aceptar'}
            click2={aceptarTerapia}
            boton1={'Cancelar'}
            click1={() => (setDialogoDosBotones(false))}
            cerrar={() => (setDialogoDosBotones(false))}
            descripcion={'¿Estas seguro de que quieres finalizar la terapia del paciente?'} />}
        {dialogoUnBoton && <DialogoUnBoton titulo={infoDialogo.titulo}
            color={'naranja'}
            click1={infoDialogo.click}
            boton1={infoDialogo.boton}
            cerrar={infoDialogo.cerrar}
            descripcion={infoDialogo.descripcion} />}
        <div className="fila-atras" onClick={() => {
            history.push(`/terapeuta/pacientes/${idPaciente}`);
        }}>
            {ready ? <button className="btn-arketipos btn-terapeuta btn-atras">←</button>
                : <SkeletonResponsive tipo={'rect'} className='sk-btn-atras' />}
            {ready ? <h5 className="texto-atras">{"Perfil " + paciente.nombre}</h5>
                : <SkeletonResponsive tipo={'rect'} className='sk-texto-atras' />}
        </div>
        <div className="titulo-terapia">
            {ready ? <h1 className="nombre-terapia">{terapiaActiva.nombre}</h1>
                : <SkeletonResponsive tipo={'rect'} className='sk-titulo-terapias' />}
        </div>
        <div className="contenedor-tareas">
            {(ready && terapiaActiva.tareas) ?
                terapiaActiva.tareas.length > 0 ? pintarTareas(terapiaActiva.tareas)
                    :
                    <div className="sin-tareas">
                        <p className="texto">No hay tareas creadas</p>
                    </div>
                : <div>
                    <SkeletonResponsive tipo={'rect'} className='sk-tarea' />
                    <SkeletonResponsive tipo={'rect'} className='sk-tarea' />
                    <SkeletonResponsive tipo={'rect'} className='sk-tarea' />
                    <SkeletonResponsive tipo={'rect'} className='sk-tarea' />
                    <SkeletonResponsive tipo={'rect'} className='sk-tarea' />
                </div>
            }
        </div>
        <button
            className="btn-terapeuta btn-arketipos btn-finalizar"
            onClick={finalizarTerapiaPaciente}>
            Finalizar terapia
            </button>
    </div>
}

RevisionTerapia.propTypes = {};

const mapStateToProps = (state, props) => ({
    paciente: getPacienteActual(state, props.match.params.idPaciente),
    perfil: getPerfilPsicologo(state),
    terapiaActiva: getTerapiaActual(state, props.match.params.idPaciente, props.match.params.idTerapiaActiva)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    consultarPerfilPaciente: consultarPerfilPaciente,
    consultarTareas: consultarTareasPaciente,
    consultarTerapia: consultarTerapiaActiva
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RevisionTerapia));