import { createSelector } from "reselect"
import {
    GET_ADS, GET_HOME, GET_NOSOTROS, GET_SERVICIOS, GET_TEAM, GUARDANDO_CAMBIO,
    LOADING_HOME, LOADING_NOSOTROS, LOADING_SERVICIOS, GET_CONSULTANCY, GET_FORMATION,
    GET_SOCIAL_WORK, GET_PSYCHOLOGY
} from "../actions/landingActions"

const INITIAL_STATE = {

    home: null,
    anuncios: [],
    equipo: [],
    cargandoAnuncios: false,
    servicios: null,
    cargandoServicios: false,
    nosotros: null,
    cargandoNosotros: false,
    guardandoCambio: false,

}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) =>{
    switch (action.type){
        case GET_HOME:
            return {...state, home: {...state.home, ...action.payload}, cargandoHome: false}
        case GET_TEAM:
            return {...state, equipo: action.payload}
        case GET_ADS:
            return {...state, anuncios: action.payload}
        case GET_SERVICIOS:
            return {...state, servicios: {...state.servicios, ...action.payload}, cargandoServicios: false}
        case GET_CONSULTANCY:
            return {...state, servicios: {...state.servicios, consultoria: action.payload}}
        case GET_FORMATION:
            return {...state, servicios: {...state.servicios, formacion: action.payload}}
        case GET_SOCIAL_WORK:
            return {...state, servicios: {...state.servicios, laborSocial: action.payload}}
        case GET_PSYCHOLOGY:
            return {...state, servicios: {...state.servicios, psicologia: action.payload}}
        case GET_NOSOTROS:
            return {...state, nosotros: action.payload, cargandoNosotros: false}
        case LOADING_HOME:
            return {...state, cargandoHome: action.payload}
        case LOADING_SERVICIOS:
            return {...state, cargandoServicios: action.payload}
        case LOADING_NOSOTROS:
            return {...state, cargandoNosotros: action.payload}
        case GUARDANDO_CAMBIO:
            return {...state, guardandoCambio: action.payload}
        default: return {...state}
    }
}

export const getHomeState = createSelector(state => state.landingReducer, landingReducer => landingReducer.home);
export const getAdsState = createSelector(state => state.landingReducer, landingReducer => landingReducer.anuncios);
export const getEquipoState = createSelector(state => state.landingReducer, landingReducer => landingReducer.equipo);

export const getServiciosState = createSelector(state => state.landingReducer, landingReducer => landingReducer.servicios);
export const getNosotrosState = createSelector(state => state.landingReducer, landingReducer => landingReducer.nosotros);

export const getLoadingHome = createSelector(state => state.landingReducer, landingReducer => landingReducer.cargandoHome);

export const getLoadingServicios = createSelector(state => state.landingReducer, landingReducer => landingReducer.cargandoServicios);
export const getLoadingNosotros = createSelector(state => state.landingReducer, landingReducer => landingReducer.cargandoNosotros);
export const getGuardandoCambio = createSelector(state => state.landingReducer, landingReducer => landingReducer.guardandoCambio);
