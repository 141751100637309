import React, { useState, useEffect, useContext } from 'react';
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getActividadesTarea, getDetalleTerapia, crearActividad, finalizarCreacion, editarActividad, eliminarActividad, apagarListenersActividades } from '../../../../actions/terapeutaActions';
import { getTerapia, getEstadoCreacion } from '../../../../reducers/terapeutaReducer';
import { animateScroll } from 'react-scroll'
import Actividad from './Actividad';
import NuevaActividad from './NuevaActividad';
import { CANCELAR } from '../../../../utilidades/Constantes';
import DialogoAdjuntar from './DialogoAdjuntar';
import { CargandoContext } from '../../../general/CargandoContext/CargandoContext';
import SkeletonResponsive from '../../../general/SkeletonResponsive';

const DetalleTarea = (props) => {
    const { history, idTerapia, idTarea, traerTerapia, detalleTerapia, traerActividades,
        guardarActividad, finalizarCreacion, estadoCreacion, editarActividad, apagarListeners
    } = props
    const [tarea, setTarea] = useState(null)
    const [actividades, setActividades] = useState(null)
    const [crearActividad, setCrearActividad] = useState(0)
    const [actividadEditar, setActividadEditar] = useState(null)
    const [seleccionarTipo, setSeleccionarTipo] = useState(false)
    const { correrIndicadorCarga, quitarIndicadorCarga } = useContext(CargandoContext)
    const tareas = detalleTerapia === null ? [] : detalleTerapia.tareas ?? []

    const seleccionarTarea = () => {
        tareas.forEach((t) => {
            if (t.uidDoc === idTarea) {
                setTarea(t)
                return
            }
        })
    }

    const agregarActividades = () => {
        tareas.forEach((t) => {
            if (t.uidDoc === idTarea) {
                if (t.actividades) {
                    setActividades(t.actividades)
                }
                return
            }
        })
    }

    useEffect(() => {
        if (detalleTerapia === null) {
            traerTerapia(idTerapia)
        }

        if (detalleTerapia !== null) {
            if (tarea === null && tareas.length > 0) {
                seleccionarTarea()
            }
        }

        if (detalleTerapia !== null && tareas.length > 0 && tarea !== null) {
            if (actividades === null && !tarea.actividades) {
                traerActividades({ terapia: idTerapia, tarea: idTarea })
                setActividades([])
            }
            agregarActividades();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detalleTerapia, tarea]);

    useEffect(() => (() => {
        apagarListeners()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [])

    const guardarNuevaActividad = (e, actividad) => {
        e.preventDefault()
        let tipo = crearActividad
        if (actividad === CANCELAR) {
            setCrearActividad(0)
            return
        }
        guardarActividad(idTerapia, idTarea, { ...actividad, tipo: tipo, index: actividades && actividades.length ? actividades.length : 0 })
    }

    const editarActividadActual = (e, actividad) => {
        e.preventDefault()
        if (actividad === CANCELAR) {
            setActividadEditar(null)
            return
        }
        editarActividad(idTerapia, idTarea, actividad)
    }

    const crear = (n) => {
        setSeleccionarTipo(false)
        setCrearActividad(n)
        animateScroll.scrollToBottom()
    }

    const eliminar = async (id) => {
        correrIndicadorCarga()
        await eliminarActividad(idTerapia, idTarea, id)
        quitarIndicadorCarga()
    }

    return (
        <div className="contenedor-detalle-terapia">
            {seleccionarTipo && <DialogoAdjuntar
                cerrar={() => setSeleccionarTipo(false)}
                guardarRecurso={crear}
                seleccionarTipo={true}
            />}
            <div className="fila-atras" onClick={() => {
                history.goBack()
            }}>
                <button className="btn-arketipos btn-terapeuta btn-atras">←</button>
                <h5 className="texto-atras">Tus tareas</h5>
            </div>
            <div className="titulo-tarea">
                <div className="espacio-vacio" />
                {tarea === null ? <SkeletonResponsive className="sk-titulo-tarea" tipo="rect" />
                    :
                    <h1 className="titulo-terapias">{tarea !== null && tarea.nombre}</h1>
                }
                {tarea === null ?
                    <SkeletonResponsive className="sk-boton-crear" tipo="rect" />
                    : <button className="btn-arketipos fondo-borde-naranja btn-crear-tarea btn-actividades"
                        onClick={() => setSeleccionarTipo(true)}>
                        Crear actividades
                    </button>
                }
            </div>
            <p className="descripcion-terapia">{tarea === null ?
                <SkeletonResponsive className="sk-descripcion-tarea" tipo="rect" />
                : tarea.descripcion ?? ''
            }
            </p>
            <div className="contenedor-tareas">
                <div>
                    {actividades === null ?
                        <div>
                            <SkeletonResponsive className="sk-actividad-tarea" tipo="rect" />
                            <SkeletonResponsive className="sk-actividad-tarea" tipo="rect" />
                            <SkeletonResponsive className="sk-actividad-tarea" tipo="rect" />
                        </div>
                        :
                        actividades.length > 0 ?
                            actividades.sort((a, b) => {
                                if ((a.index ?? 0) > (b.index ?? 0)) return -1
                                return 1
                            }).map((actividad, i) => {

                                if (actividadEditar)
                                    if (actividadEditar.uidDoc === actividad.uidDoc)
                                        return <NuevaActividad
                                            actividadRecibida={actividad}
                                            editar={true}
                                            guardar={editarActividadActual}
                                            index={i}
                                            finalizarCreacion={finalizarCreacion}
                                            estadoCreacion={estadoCreacion}
                                            key={`actividad-${i}`}
                                        />

                                return <Actividad
                                    actividad={actividad}
                                    editar={setActividadEditar}
                                    key={`actividad-${i}`}
                                    index={i}
                                    idTerapia={idTerapia}
                                    idTarea={idTarea}
                                    eliminar={() => eliminar(actividad.uidDoc)}
                                />
                            })
                            : <div className="sin-tareas" style={{ marginBottom: '1em' }}>
                                <p className="texto">No hay actividades creadas</p>
                            </div>
                    }
                    {crearActividad > 0 ?
                        <NuevaActividad
                            actividadRecibida={{}}
                            editar={false}
                            guardar={guardarNuevaActividad}
                            index={actividades.length}
                            finalizarCreacion={finalizarCreacion}
                            estadoCreacion={estadoCreacion}
                        />
                        : ''}
                </div>
            </div>
            <br /><br /><br />
        </div>
    );
}

DetalleTarea.propTypes = {
    idTerapia: PropTypes.string.isRequired,
    idTarea: PropTypes.string.isRequired,
};


const mapStateToProps = state => ({
    detalleTerapia: getTerapia(state),
    estadoCreacion: getEstadoCreacion(state),
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        traerTerapia: getDetalleTerapia,
        traerActividades: getActividadesTarea,
        guardarActividad: crearActividad,
        finalizarCreacion: finalizarCreacion,
        editarActividad: editarActividad,
        apagarListeners: apagarListenersActividades
    }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DetalleTarea));
