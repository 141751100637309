import React from 'react';
import PropTypes from 'prop-types';

const DialogoUnBoton = ({titulo, descripcion, boton1, click1, cerrar, color}) => {
    return (
        <div className="fondo-dialogo-generico">
            <div className="fondo-dialogo-rc" onClick={cerrar}/>
            <div className="dialogo">
                <h1 className="titulo-dialogo">{titulo}</h1>
                <div>
                    <p className="descripcion-dialogo">
                        {descripcion}
                    </p>
                </div>
                <button id="btn-primario" className={`boton boton-dialogo btn-arketipos fondo-borde-${color??'azul'}`} onClick={click1}>
                    {boton1}
                </button>
            </div>
        </div>
    )
};

DialogoUnBoton.propTypes = {
    titulo: PropTypes.string.isRequired,
    descripcion: PropTypes.string.isRequired,
    boton1: PropTypes.string.isRequired,
    click1: PropTypes.func.isRequired,
    cerrar: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired
};

export default DialogoUnBoton;