import React, { useEffect } from 'react'
import Header from '../general/Header'
import Footer from '../general/Footer'
import { Route, Switch, withRouter } from 'react-router-dom'
import Servicios from './servicios/Servicios'
import Nosotros from './nosotros/Nosotros'
import Inicio from './inicio/Inicio'

const Landing = ({match}) => {

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }, [match])

    return (
        <div className="fondo-blanco">
            <Header/>
            <Switch>
                <Route path="/nosotros" component={Nosotros}/>
                <Route path="/servicios" component={Servicios}/>
                <Inicio />
            </Switch>
            <Footer />
        </div>
    )
}

export default withRouter(Landing)