import {
  consultarColeccion,
  consultarColeccionCampoEspecifico,
  consultarDocumento,
  consultarDocumentoConSubcolecciones,
  crearDocumento,
  crearDocumentoAutoID,
  editarDocumento,
  eliminarDocumento,
  escrituraPorLotes,
  incrementarTareasCompletadas,
  obtenerColeccion,
  obtenerColeccionCamposEspecificos,
  obtenerDocumento,
  sumaRestaDocumento,
  apagarListener,
} from "../proxy/FirebaseDB";
import {
  CARGANDO,
  CITA_PROGRAMADA,
  ERROR,
  OK,
  TAREA_ASIGNADA,
  TERAPIA_ASIGNADA,
  UPDATE,
} from "../utilidades/Constantes";
import { subirArchivo } from "../proxy/FirebaseStorage";
import {
  funcionEnviarCorreo,
  funcionEliminarTerapia,
} from "../proxy/FirebaseFunctions";

export const GET_DB_TERAPIAS_PSICOLOGO = "GET_DB_TERAPIAS_PSICOLOGO";
export const GET_DB_PERFIL_PSICOLOGO = "GET_DB_PERFIL_PSICOLOGO";
export const GET_DB_CITAS_PSICOLOGO = "GET_DB_CITAS_PSICOLOGO";
export const GET_DB_PACIENTES_PSICOLOGO = "GET_DB_PACIENTES_PSICOLOGO";
export const CARGANDO_TERAPIAS_PSICOLOGO = "CARGANDO_TERAPIAS_PSICOLOGO";
export const GET_DB_PERFIL_PACIENTE = "GET_DB_PERFIL_PACIENTE";
export const DELETE_TERAPIA_ACTUAL = "DELETE_TERAPIA_ACTUAL";
export const CARGANDO_PERFIL_PACIENTE = "CARGANDO_PERFIL_PACIENTE";
export const GET_DB_DETALLE_TERAPIA_PSICOLOGO =
  "GET_DB_DETALLE_TERAPIA_PSICOLOGO";
export const GET_DB_ACTIVIDADES_TAREA_PSICOLOGO =
  "GET_DB_ACTIVIDADES_TAREA_PSICOLOGO";
export const SET_TAREA_ACTUAL = "SET_TAREA_ACTUAL";
export const GUARDANDO_ACTIVIDAD = "GUARDANDO_ACTIVIDAD";
export const CARGANDO_PERFIL = "CARGANDO_PERFIL";
export const CARGANDO_CITAS_PSICOLOGO = "CARGANDO_CITAS_PSICOLOGO";
export const CARGANDO_PACIENTES = "CARGANDO_PACIENTES";
export const TRAER_TAREAS_PACIENTE = "TRAER_TAREAS_PACIENTE";
export const TRAER_TERAPIA_ACTIVA = "TRAER_TERAPIA_ACTIVA";
export const CONSULTAR_TAREA_ACTIVIDADES = "CONSULTAR_TAREA_ACTIVIDADES";
export const APAGAR_LISTENER_ACTIVIDADES = "APAGAR_LISTENER_ACTIVIDADES";

const meses = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export const getDBTerapiasPsicologo = (payload) => {
  return (dispatch, getState) => {
    let estado = getState().terapeutaReducer;
    if (!estado.cargandoTerapias && !estado.terapias) {
      dispatch({
        type: CARGANDO_TERAPIAS_PSICOLOGO,
        payload: true,
      });
      consultarColeccionCampoEspecifico(
        `terapias`,
        "idPsicologo",
        payload,
        dispatch,
        GET_DB_TERAPIAS_PSICOLOGO
      );
    }
  };
};

export const updateTerapeutaProfileInfo = async (payload) => {
  let uidTerapeuta = payload.uidDocumento;
  delete payload.uidDocumento;

  let res = await escrituraPorLotes([
    {
      ruta: `psicologos/${uidTerapeuta}`,
      tipo: UPDATE,
      data: payload,
    },
    {
      ruta: `usuarios/${uidTerapeuta}`,
      tipo: UPDATE,
      data: payload,
    },
  ]);

  return res;
};

export const getDBGetPerfilPsicologo = (payload) => {
  return (dispatch, getState) => {
    let estado = getState().terapeutaReducer;
    if (!estado.cargandoPerfil && !estado.perfilTerapeuta) {
      dispatch({
        type: CARGANDO_PERFIL,
        payload: true,
      });
      consultarDocumento(
        `psicologos/${payload}`,
        dispatch,
        GET_DB_PERFIL_PSICOLOGO
      );
    }
  };
};

export const getDBCitasPsicologo = (payload) => {
  return (dispatch, getState) => {
    let estado = getState().terapeutaReducer;
    if (!estado.cargandoCitas && !estado.citas) {
      dispatch({
        type: CARGANDO_CITAS_PSICOLOGO,
        payload: true,
      });
      consultarColeccionCampoEspecifico(
        `citas`,
        "idPsicologo",
        payload,
        dispatch,
        GET_DB_CITAS_PSICOLOGO
      );
    }
  };
};

export const getPacientesPsicologo = (payload) => {
  return (dispatch, getState) => {
    let estado = getState().terapeutaReducer;
    if (!estado.cargandoPacientes && !estado.pacientes) {
      dispatch({
        type: CARGANDO_PACIENTES,
        payload: true,
      });
      consultarColeccion(
        `psicologos/${payload.perfil.uidDocumento}/pacientes`,
        dispatch,
        GET_DB_PACIENTES_PSICOLOGO
      );
    }
  };
};

export const consultarPerfilPaciente = (payload) => {
  return async (dispatch, getState) => {
    let state = getState();
    if (state.terapeutaReducer.cargandoPerfilPaciente) return;
    let userSession = state.sesionReducer.user?.uidDocumento;
    if (!userSession) return;
    dispatch({
      type: CARGANDO_PERFIL_PACIENTE,
      payload: true,
    });
    let terapiasActivas = await obtenerColeccionCamposEspecificos(
      `terapiasActivas`,
      [
        { campo: "idPaciente", valor: payload.idPaciente },
        { campo: "idPsicologo", valor: userSession },
      ]
    );
    let paciente = await obtenerDocumento(`pacientes/${payload.idPaciente}`);

    dispatch({
      type: GET_DB_PERFIL_PACIENTE,
      payload: {
        ...paciente,
        terapiasActivas,
      },
    });
  };
};

export const cambiarEstadoTarea = async (idTerapia, idTarea, correo) => {
  let respuesta = await editarDocumento(`terapiasActivas/${idTerapia}`, {
    uidTarea: idTarea,
    tareaActual: true,
  });
  funcionEnviarCorreo({
    tipo: TAREA_ASIGNADA,
    nombrePaciente: correo.nombrePaciente ?? "",
    nombreTarea: correo.nombreTarea ?? "",
    nombreTerapia: correo.nombreTerapia ?? "",
    nombrePsicologo: correo.nombrePsicologo ?? "",
    email: correo.correo,
  });
  return respuesta;
};

export const eliminarTerapia = async (idTerapia) => {
  return funcionEliminarTerapia({ terapia: idTerapia });
};

export const consultarTerapiaActiva = (payload) => {
  return async (dispatch) => {
    await consultarDocumento(
      `terapiasActivas/${payload.idTerapia}`,
      dispatch,
      TRAER_TERAPIA_ACTIVA,
      { idTerapia: payload.idTerapia, idPaciente: payload.idPaciente }
    );
  };
};

export const consultarTareasPaciente = (payload) => {
  return async (dispatch) => {
    await consultarColeccion(
      `terapiasActivas/${payload.idTerapia}/tareas`,
      dispatch,
      TRAER_TAREAS_PACIENTE,
      { idTerapia: payload.idTerapia, idPaciente: payload.idPaciente }
    );
  };
};

export const consultarActividadPaciente = (payload) => {
  return async (dispatch) => {
    consultarColeccion(
      `terapiasActivas/${payload.idTerapia}/tareas`,
      dispatch,
      TRAER_TAREAS_PACIENTE,
      { idTerapia: payload.idTerapia, idPaciente: payload.idPaciente }
    );
  };
};

export const finalizarTerapia = async (idTerapia, idPaciente, idPsicologo) => {
  await editarDocumento(`psicologos/${idPsicologo}/pacientes/${idPaciente}`, {
    revisar: false,
  });
  return await editarDocumento(`terapiasActivas/${idTerapia}`, {
    finalizada: true,
    revisar: false,
  });
};

export const crearCita = (payload) => {
  return async (dispatch, getState) => {
    let state = getState();
    let res = await crearDocumentoAutoID("citas", {
      idPaciente: payload.paciente.uidDoc,
      idPsicologo: state.sesionReducer.user.uidDocumento,
      link: payload.url,
      fecha: payload.fecha,
      inicio: payload.horaInicio,
      fin: payload.horaFin,
    });

    if (res === OK) {
      let correoPaciente = payload.paciente.correo;
      if (!correoPaciente) {
        let paciente = await obtenerDocumento(
          `pacientes/${payload.paciente.uidDoc}`
        );
        correoPaciente = paciente.correo;
      }
      funcionEnviarCorreo({
        tipo: CITA_PROGRAMADA,
        email: correoPaciente,
        nombrePaciente: payload.paciente.nombre,
        nombrePsicologo: state.sesionReducer.user.nombre,
        fechaHora: `${payload.fecha.split("-")[2]} de ${
          meses[parseInt(payload.fecha.split("-")[1])]
        } de ${payload.fecha.split("-")[0]} - ${payload.horaInicio} a ${
          payload.horaFin
        }`,
        enlace: payload.url,
      });
    }
    return res;
  };
};

export const eliminarCita = (payload) => {
  return async (dispatch) => {
    return await eliminarDocumento(`citas/${payload.uidDoc}`);
  };
};

export const editarCita = (payload) => {
  return async (dispatch) => {
    return await editarDocumento(`citas/${payload.id}`, {
      idPaciente: payload.paciente,
      link: payload.url,
      fecha: payload.fecha,
      inicio: payload.horaInicio,
      fin: payload.horaFin,
    });
  };
};

export const getDetalleTerapia = (payload) => {
  return (dispatch) => {
    consultarDocumentoConSubcolecciones(
      `terapias/${payload}`,
      ["tareas"],
      dispatch,
      GET_DB_DETALLE_TERAPIA_PSICOLOGO
    );
  };
};

export const deleteTerapiaActual = () => ({
  type: DELETE_TERAPIA_ACTUAL,
  payload: null,
});

export const getActividadesTarea = (payload) => {
  return (dispatch) => {
    consultarColeccion(
      `terapias/${payload.terapia}/tareas/${payload.tarea}/actividades`,
      (data) => setActividades(dispatch, data, payload.tarea),
      GET_DB_ACTIVIDADES_TAREA_PSICOLOGO,
      null,
      APAGAR_LISTENER_ACTIVIDADES
    );
  };
};

export const apagarListenersActividades = () => {
  return (dispatch, getState) => {
    let estadoListener = getState().terapeutaReducer.listenerActividades;
    if (estadoListener) {
      apagarListener(estadoListener, dispatch, APAGAR_LISTENER_ACTIVIDADES);
    }
  };
};

const setActividades = (dispatch, data, tarea) => {
  if (data.isListener) {
    dispatch({
      ...data,
      payload: data.payload,
    });
  } else {
    dispatch({
      ...data,
      payload: {
        payload: data.payload,
        tarea: tarea,
      },
    });
  }
};

export const updateOrderSession = async (terapia, tarea, index) => {
  await editarDocumento(`terapias/${terapia}/tareas/${tarea}`, {
    index,
  });
};

export const updateOrderActivity = async (terapia, tarea, actividad, index) => {
  await editarDocumento(
    `terapias/${terapia}/tareas/${tarea}/actividades/${actividad}`,
    {
      index,
    }
  );
};

export const updateOrderActiveActivity = async (
  terapia,
  tarea,
  actividad,
  index
) => {
  await editarDocumento(
    `terapiasActivas/${terapia}/tareas/${tarea}/actividades/${actividad}`,
    {
      index,
    }
  );
};

export const crearTarea = (tarea, idTerapia) => {
  return async (dispatch, getState) => {
    let state = getState();
    let incrementar = await sumaRestaDocumento(
      `terapias/${idTerapia}`,
      1,
      "numeroTareas"
    );
    let res = await crearDocumentoAutoID(
      `terapias/${idTerapia}/tareas/`,
      tarea,
      true
    );
    if (res === ERROR || incrementar === "ERROR") return res;
    let terapia = state.terapeutaReducer.terapia;
    dispatch({
      type: GET_DB_DETALLE_TERAPIA_PSICOLOGO,
      payload: {
        ...terapia,
        tareas: [...terapia.tareas, { ...tarea, uidDoc: res.id }],
      },
    });
    return res;
  };
};

export const crearTerapia = (terapia, idTerapeuta) => {
  let terapiaNueva = { ...terapia, idPsicologo: idTerapeuta };
  return crearDocumentoAutoID("terapias", terapiaNueva);
};

export const asignarTerapia = (paciente, terapia) => {
  return async (dispatch, getState) => {
    let state = getState();

    let res = await editarDocumento(
      `psicologos/${terapia.idPsicologo}/pacientes/${paciente.uidDoc}`,
      { estado: 1, numeroTerapias: (paciente.numeroTerapias || 0) + 1 }
    );

    if (res === ERROR) return res;

    let terapiaActiva = await crearDocumentoAutoID(
      "terapiasActivas/",
      {
        descripcion: terapia.descripcion,
        idPaciente: paciente.uidDoc,
        idPsicologo: terapia.idPsicologo,
        nombre: terapia.nombre,
        finalizada: false,
        tareaActual: false,
        tareasCompletadas: 0,
        numeroTareas: terapia.numeroTareas,
      },
      true
    );

    if (terapiaActiva === ERROR) return terapiaActiva;

    try {
      let tareas = await obtenerColeccion(`terapias/${terapia.uidDoc}/tareas`);

      let promesasTareas = [];

      await editarDocumento(`terapias/${terapia.uidDoc}`, {
        numeroPacientes: terapia.numeroPacientes + 1,
      });

      tareas.forEach((tarea) => {
        let idTarea = tarea.uidDoc;
        delete tarea.uidDoc;
        promesasTareas.push(
          crearDocumento(
            `terapiasActivas/${terapiaActiva.id}/tareas/${idTarea}`,
            tarea
          )
        );

        const crearActividades = async () => {
          let actividades = await obtenerColeccion(
            `terapias/${terapia.uidDoc}/tareas/${idTarea}/actividades`
          );
          let promesasActividades = [];
          actividades.forEach((actividad) => {
            let idActividad = actividad.uidDoc;
            delete actividad.uidDoc;

            promesasActividades.push(
              crearDocumento(
                `terapiasActivas/${terapiaActiva.id}/tareas/${idTarea}/actividades/${idActividad}`,
                actividad
              )
            );
          });

          return await Promise.all(promesasActividades);
        };

        promesasTareas.push(crearActividades());
      });

      await Promise.all(promesasTareas);

      funcionEnviarCorreo({
        tipo: TERAPIA_ASIGNADA,
        email: paciente.correo,
        nombrePaciente: paciente.nombre,
        nombrePsicologo: state.sesionReducer.user.nombre,
        nombreTerapia: terapia.nombre,
      });

      dispatch({
        type: GET_DB_PERFIL_PACIENTE,
        payload: {
          uidDoc: paciente.uidDoc,
        },
      });
    } catch (error) {
      eliminarDocumento(`terapiasActivas/${terapiaActiva.id}`);
      return ERROR;
    }
  };
};

export const crearActividad = (terapia, tarea, actividad) => {
  return async (dispatch) => {
    dispatch({
      type: GUARDANDO_ACTIVIDAD,
      payload: CARGANDO,
    });

    let recursos,
      archivos = [],
      videos = [],
      imagenes = [];
    if (actividad.recursos) {
      for (let i = 0; i < actividad.recursos.length; i++) {
        const r = actividad.recursos[i];

        let url = "";
        let nombre = "";
        if (r.adjunto) {
          url = await subirArchivo(
            `Terapias/${terapia}/${tarea}/${r.archivo.name}`,
            r.archivo
          );
          nombre = r.archivo.name;
        } else {
          url = r.archivo;
        }
        let recurso = {
          url: url,
          tipo: r.tipo,
          nombre: nombre,
        };
        if (r.tipo === 0) archivos.push(recurso);
        if (r.tipo === 1) videos.push(recurso);
        if (r.tipo === 2) imagenes.push(recurso);
      }
    }
    recursos = [...videos, ...imagenes, ...archivos];
    let actividadCrear = { ...actividad, recursos: recursos };
    console.log("Algo ocurre :(", actividad);
    let respuesta = await crearDocumentoAutoID(
      `terapias/${terapia}/tareas/${tarea}/actividades`,
      actividadCrear
    );
    dispatch({
      type: GUARDANDO_ACTIVIDAD,
      payload: respuesta,
    });
  };
};

export const eliminarActividad = async (terapia, tarea, actividad) => {
  let r = await eliminarDocumento(
    `terapias/${terapia}/tareas/${tarea}/actividades/${actividad}`
  );
  return r;
};

export const editarActividad = (terapia, tarea, actividad) => {
  return async (dispatch) => {
    dispatch({
      type: GUARDANDO_ACTIVIDAD,
      payload: CARGANDO,
    });

    let recursos,
      archivos = [],
      videos = [],
      imagenes = [];
    if (actividad.recursos) {
      for (let i = 0; i < actividad.recursos.length; i++) {
        const r = actividad.recursos[i];

        let url = "";
        let nombre = "";
        if (r.adjunto) {
          url = await subirArchivo(
            `Terapias/${terapia}/${tarea}/${r.archivo.name}`,
            r.archivo
          );
          nombre = r.archivo.name;
        } else {
          if (r.url) {
            nombre = r.nombre ?? "";
            url = r.url;
          } else url = r.archivo;
        }
        let recurso = {
          url: url,
          tipo: r.tipo,
          nombre: nombre,
        };
        if (r.tipo === 0) archivos.push(recurso);
        if (r.tipo === 1) videos.push(recurso);
        if (r.tipo === 2) imagenes.push(recurso);
      }
    }
    recursos = [...videos, ...imagenes, ...archivos];
    let descripcion = actividad.descripcion;
    let actividadCrear = { descripcion: descripcion, recursos: recursos };
    let respuesta = await editarDocumento(
      `terapias/${terapia}/tareas/${tarea}/actividades/${actividad.uidDoc}`,
      actividadCrear
    );
    // let respuesta = OK
    dispatch({
      type: GUARDANDO_ACTIVIDAD,
      payload: respuesta,
    });
  };
};

export const finalizarCreacion = () => {
  return (dispatch) => {
    dispatch({
      type: GUARDANDO_ACTIVIDAD,
      payload: null,
    });
  };
};

export const consultarTareaActividades = ({
  idPaciente,
  idTerapiaActiva,
  idTarea,
}) => {
  return (dispatch) => {
    consultarDocumentoConSubcolecciones(
      `terapiasActivas/${idTerapiaActiva}/tareas/${idTarea}`,
      ["actividades"],
      dispatch,
      CONSULTAR_TAREA_ACTIVIDADES,
      { idPaciente, idTerapiaActiva, idTarea }
    );
  };
};

export const revisarTareaPaciente = async ({
  idTerapiaActiva,
  idTarea,
  idPaciente,
  idPsicologo,
}) => {
  await incrementarTareasCompletadas(idTerapiaActiva);
  await editarDocumento(
    `terapiasActivas/${idTerapiaActiva}/tareas/${idTarea}`,
    { finalizada: true }
  );
  await editarDocumento(`psicologos/${idPsicologo}/pacientes/${idPaciente}`, {
    revisar: false,
  });
};
