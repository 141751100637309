import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import DropdownSelector from '../../../general/dropdownSelector/DropdownSelector'
import { CargandoContext } from '../../../general/CargandoContext/CargandoContext';
import { ERROR } from '../../../../utilidades/Constantes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../../actions/terapeutaActions';

const DialogoAsignarTerapia = ({paciente, terapias, cerrarDialogoHandleClick, asignarTerapia}) => {

    let [terapiaSeleccionada, setTerapiaSeleccionada] = useState('')
    const [error, setError] = useState(null)

    let dialogoRef = useRef()

    let { correrIndicadorCarga, quitarIndicadorCarga } = useContext(CargandoContext)
    
    const handleClickOutside = useCallback( event => {
        if (dialogoRef && !dialogoRef.current.contains(event.target))
            cerrarDialogoHandleClick()
    }, [cerrarDialogoHandleClick])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);            
        }
    }, [handleClickOutside])

    const handleTerapiaSeleccionada = (terapiaSeleccionada) => setTerapiaSeleccionada(terapiaSeleccionada)

    const handleAsignarTerapia = async () => {
        correrIndicadorCarga()
        let res = await asignarTerapia(paciente, terapias.find(terapia => terapia.nombre === terapiaSeleccionada))
        quitarIndicadorCarga()

        if(res === ERROR)
            setError('Hubo un problema, por favor intentalo más tarde')
        else
            cerrarDialogoHandleClick()
    }

    return (
        <div className='fondo-dialogo'>
            <div className='dialogo-asignar-terapia' ref={dialogoRef}>
                <div className='contenido-dialogo'>
                    <div className='titulo-dialogo'>
                        Asignar terapia a paciente
                        <hr />
                    </div>
                    <div className='titulo-dropdown'>
                        Elegir terapia:
                    </div>
                    <DropdownSelector
                        estilo='terapeuta'
                        value={terapiaSeleccionada}
                        selectionHandleClick={handleTerapiaSeleccionada}
                        placeholder={'Seleccionar terapia'}
                        opciones={terapias.map(terapia => terapia.nombre)} />
                    {
                        error &&
                        <div className='error'>
                            {error}
                        </div>
                    }
                    <div className='botones-dialogo'>
                        <button className='btn-arketipos' onClick={cerrarDialogoHandleClick}>Cancelar</button>
                        {
                            terapiaSeleccionada !== '' &&
                            <button
                                onClick={handleAsignarTerapia}
                                className='btn-arketipos btn-asignar'>
                                    Asignar
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);


export default connect(null, mapDispatchToProps)(DialogoAsignarTerapia);
