
const textColors = {
    blue: 'text-azul',
    green: 'text-verde',
    yellow: 'text-amarillo',
    white: 'text-blanco',
}
const bgColors = {
    blue: 'fondo-azul',
    green: 'fondo-verde',
    yellow: 'fondo-amarillo',
    white: 'fondo-blanco',
}

const BotonAgenda = ({ textColor, bgColor = "white" }) => {
    const bg = bgColors[bgColor]
    const text = textColors[textColor]
    return (
        <a href="https://tidycal.com/redespsicoarketipos/sesion-terapia" rel="noreferrer noopener" target='_blank' className={`boton btn-arketipos btn-agenda transicion ${text} ${bg}`}>AGENDATE AHORA</a>
    )
}

export default BotonAgenda