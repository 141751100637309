import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CargandoContext } from '../../../../../general/CargandoContext/CargandoContext'
import DialogoUnBoton from "../../../../../general/DialogoUnBoton";
import { ERROR } from "../../../../../../utilidades/Constantes";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { crearTarea } from '../../../../../../actions/terapeutaActions';

class DialogoCrearTarea extends Component {

    state = {
        nombre: '',
        descripcion: '',
        dialogo: {
            abrir: false,
        }
    }

    handleSubmit = (e, cargando) => {
        e.preventDefault()
        cargando.correrIndicadorCarga()
        let datos = { ...this.state, index: this.props.index ?? 0 };
        delete datos.dialogo

        this.props.crearTarea(datos, this.props.uid)
            .then((res) => {
                if (res !== ERROR) {
                    cargando.quitarIndicadorCarga()
                    this.setState({
                        nombre: "",
                        descripcion: '',
                        dialogo: {
                            abrir: true,
                            titulo: "¡Tarea creada!",
                            descripcion: "Se ha creado tu nueva tarea",
                            boton: "Continuar"
                        }
                    })
                } else {
                    cargando.quitarIndicadorCarga()
                    this.setState({
                        dialogo: {
                            abrir: true,
                            titulo: "¡Lo sentimos!",
                            descripcion: "Parece que hubo un problema al crear la tarea",
                            boton: "Intentar de nuevo"
                        }
                    })
                }
            })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    cerrarDialogo = (cerrar) => {
        this.setState({
            dialogo: {
                abrir: false
            }
        })
        cerrar();
    }

    render() {
        let { cerrar } = this.props;
        return (
            <CargandoContext.Consumer>
                {value =>
                    <div className="fondo-dialogo-crear-tarea">
                        {this.state.dialogo.abrir && <DialogoUnBoton cerrar={this.cerrarDialogo}
                            titulo={this.state.dialogo.titulo}
                            descripcion={this.state.dialogo.descripcion}
                            boton1={this.state.dialogo.boton}
                            click1={() => this.cerrarDialogo(cerrar)}
                            color={'naranja'}
                        />}
                        <div className="fondo-dialogo-rc" onClick={cerrar} />
                        <div className="dialogo-crear-tarea">
                            <h2 className="titulo-dialogo-crear-tarea">
                                Crear nueva tarea
                                <hr />
                            </h2>
                            <form className="form" onSubmit={(e) => this.handleSubmit(e, value)}>
                                <input required className="input" name="nombre" type="text"
                                    value={this.state.nombre}
                                    placeholder={'Nombre de la tarea'}
                                    maxLength={50}
                                    onChange={this.handleChange} />
                                <input required className="input" name="descripcion" type="text"
                                    value={this.state.descripcion}
                                    placeholder={'Descripción'}
                                    maxLength={200}
                                    onChange={this.handleChange} />
                                <div className="fila-btnes">
                                    <button className="btn-arketipos btn-izq" onClick={cerrar}>Cancelar</button>
                                    <button type="submit" className="btn-arketipos btn-terapeuta btn-der">Crear</button>
                                </div>
                            </form>
                        </div>
                    </div>}
            </CargandoContext.Consumer>
        );
    }
}

DialogoCrearTarea.propTypes = {
    cerrar: PropTypes.func.isRequired,
    uid: PropTypes.string.isRequired,
}

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        crearTarea: crearTarea
    }, dispatch);

export default connect(null, mapDispatchToProps)(DialogoCrearTarea);