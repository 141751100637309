import React  from 'react';
import { withRouter } from 'react-router-dom';

const Menu = ({ usuario, history, location }) => {
    if(usuario === 'administrador')
        return (
            <div className='contenedor-opciones menu-administrador'>
                <div 
                    className={`opcion-resumen opcion ${location.pathname === '/administrador' || location.pathname === '/administrador/'? 'opcion-seleccionada':''}`}
                    onClick={() => history.push('/administrador')}>
                    <div className='opcion-menu-admin'>Resumen</div>
                    <hr className='linea-opcion'/>
                </div>
                <div 
                    className={`opcion-pacientes opcion ${location.pathname.includes('pacientes') && !location.pathname.includes('terapeutas') ? 'opcion-seleccionada':''}`}
                    onClick={() => history.push('/administrador/pacientes')}>
                    <div className='opcion-menu-admin'>Pacientes</div>
                    <hr className='linea-opcion'/>
                </div>
                <div 
                    className={`opcion-terapeutas opcion ${location.pathname.includes('terapeutas') ? 'opcion-seleccionada':''}`}
                    onClick={() => history.push('/administrador/terapeutas')}>
                    <div className='opcion-menu-admin'>Terapeutas</div>
                    <hr className='linea-opcion'/>
                </div>
            </div>
        );
    else if(usuario === 'terapeuta')
        return (
            <div className='contenedor-opciones menu-terapeuta'>
                <div 
                    className={`opcion-resumen opcion ${location.pathname === '/terapeuta' || location.pathname === '/terapeuta/'? 'opcion-seleccionada':''}`}
                    onClick={() => history.push('/terapeuta')}>
                    <div className='opcion-menu-terapeuta'>Resumen</div>
                    <hr className='linea-opcion'/>
                </div>
                <div 
                    className={`opcion-terapias opcion ${location.pathname.includes('terapias') ? 'opcion-seleccionada':''}`}
                    onClick={() => history.push('/terapeuta/terapias')}>
                    <div className='opcion-menu-terapeuta'>Terapias</div>
                    <hr className='linea-opcion'/>
                </div>
                <div 
                    className={`opcion-citas opcion ${location.pathname.includes('citas') ? 'opcion-seleccionada':''}`}
                    onClick={() => history.push('/terapeuta/citas')}>
                    <div className='opcion-menu-terapeuta'>Citas</div>
                    <hr className='linea-opcion'/>
                </div>
                <div 
                    className={`opcion-pacientes opcion ${location.pathname.includes('pacientes') ? 'opcion-seleccionada':''}`}
                    onClick={() => history.push('/terapeuta/pacientes')}>
                    <div className='opcion-menu-terapeuta'>Tus pacientes</div>
                    <hr className='linea-opcion'/>
                </div>
                <div
                    className={`opcion-chat opcion ${location.pathname.includes('chat') ? 'opcion-seleccionada':''}`}
                    onClick={() => history.push('/terapeuta/chat')}>
                    <div className='opcion-menu-terapeuta'>Chat</div>
                    <hr className='linea-opcion'/>
                </div>
            </div>
        );
    else if(usuario === 'paciente')
        return (
            <div className='contenedor-opciones menu-paciente'>
                <div 
                    className={`opcion-resumen opcion ${location.pathname === '/paciente' || location.pathname === '/paciente/'? 'opcion-seleccionada':''}`}
                    onClick={() => history.push('/paciente')}>
                    <div className='opcion-menu-paciente'>Resumen</div>
                    <hr className='linea-opcion'/>
                </div>
                <div 
                    className={`opcion-terapias opcion ${location.pathname.includes('terapias') ? 'opcion-seleccionada':''}`}
                    onClick={() => history.push('/paciente/terapias')}>
                    <div className='opcion-menu-paciente'>Tus terapias</div>
                    <hr className='linea-opcion'/>
                </div>
                <div 
                    className={`opcion-citas opcion ${location.pathname.includes('citas') ? 'opcion-seleccionada':''}`}
                    onClick={() => history.push('/paciente/citas')}>
                    <div className='opcion-menu-paciente'>Tus citas</div>
                    <hr className='linea-opcion'/>
                </div>
                <div
                    className={`opcion-chat opcion ${location.pathname.includes('chat') ? 'opcion-seleccionada':''}`}
                    onClick={() => history.push('/paciente/chat')}>
                    <div className='opcion-menu-paciente'>Chat</div>
                    <hr className='linea-opcion'/>
                </div>
            </div>
        );

}

export default withRouter(Menu);