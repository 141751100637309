import React from 'react'
import Integrante from '../../../inicio/components/Integrante'
import PropTypes from 'prop-types'

import ciudador from '../../../../../assets/arketipos/ciudador.svg'
import amigo from '../../../../../assets/arketipos/amigo.svg'
import sonador from '../../../../../assets/arketipos/sonador.svg'
import divertido from '../../../../../assets/arketipos/divertido.svg'
import amante from '../../../../../assets/arketipos/amante.svg'
import heroe from '../../../../../assets/arketipos/heroe.svg'
import rebelde from '../../../../../assets/arketipos/rebelde.svg'
import sabio from '../../../../../assets/arketipos/sabio.svg'
import lider from '../../../../../assets/arketipos/lider.svg'
import mago from '../../../../../assets/arketipos/mago.svg'
import creador from '../../../../../assets/arketipos/creador.svg'
import aventurero from '../../../../../assets/arketipos/aventurero.svg'

const arketipos = {
    creador: creador,
    aventurero: aventurero,
    sabio: sabio,
    ciudador: ciudador,
    amigo: amigo,
    sonador: sonador,
    divertido: divertido,
    amante: amante,
    heroe: heroe,
    rebelde: rebelde,
    lider: lider,
    mago: mago,
}
const Consultoria = ({servicios, cargando}) => {

    return (
        <div className="center flex-column fondo-gris-claro contenedor-consultoria-servicios">
            <h2>Consultoría Psicologica</h2>
            <div className="flex jc-space-around ai-start flex-wrap contenedor-servicios-circulos">
                {cargando && <Integrante integrante={{}} cargando={cargando} servicios={true}/>}
                {cargando && <Integrante integrante={{}} cargando={cargando} servicios={true}/>}
                {cargando && <Integrante integrante={{}} cargando={cargando} servicios={true}/>}
                {servicios.map((servicio)=>{
                    servicio.foto = arketipos[servicio.arketipo]
                    return <Integrante integrante={servicio} cargando={cargando} servicios={true}/>
                })}
            </div>
        </div>
    )
}

Consultoria.propTypes = {
    servicios: PropTypes.array.isRequired,
    cargando: PropTypes.bool.isRequired,
}


export default Consultoria
