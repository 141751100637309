import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getNosotros, saveHomeDocument } from '../../../actions/landingActions'
import { getLoadingNosotros, getNosotrosState } from '../../../reducers/landingReducer'

const FormualrioNosotros = ({home, traerHome, cargando, guardarHome }) => {

    const [documentoHome, setDocumentoHome] = useState({})

    useEffect(() => {
        if(!home && !cargando) traerHome()

        if(home && !cargando){
            setDocumentoHome(home)
        }

        // eslint-disable-next-line
    }, [home])

    const handleChangeHome = (e) => {
        setDocumentoHome({
            ...documentoHome,
            [e.target.name]: e.target.value.replaceAll('\n', '\\n')
        })
    }

    const guardarDocumentoHome = (e) => {
        e.preventDefault()
        guardarHome(documentoHome, 'nosotros')
    }

    return (
        <div className="contenedor-formualrios-landing center ai-start flex-column">
            <form className="flex flex-column" onSubmit={guardarDocumentoHome}>
                <h3>Sección azul</h3>
                <label className="flex flex-column campo-landing">
                    <span>Título </span>
                    <input type="text" name="tituloGrande" onChange={handleChangeHome} value={documentoHome.tituloGrande??''}/>
                </label>
                <br/><br/>
                <label className="flex flex-column campo-landing">
                    <span><b>Quiénes Somos</b></span><br/>
                    <textarea name="quienesSomos" onChange={handleChangeHome} value={(documentoHome.quienesSomos??'').replaceAll("\\n", "\n")}/>
                </label>
                <br/><br/>
                <label className="flex flex-column campo-landing">
                    <span><b>Qué hacemos</b></span><br/>
                    <textarea name="queHacemos" onChange={handleChangeHome} value={(documentoHome.queHacemos??'').replaceAll("\\n", "\n")}/>
                </label>
                <br/><br/>
                <label className="flex flex-column campo-landing">
                    <span><b>Qué queremos</b></span><br/>
                    <textarea name="queQueremos" onChange={handleChangeHome} value={(documentoHome.queQueremos??'').replaceAll("\\n", "\n")}/>
                </label>
                <button className="btn-arketipos fondo-azul">Guardar Cambios</button>
            </form>
            <br/><br/>
            <hr className="separador-landing"/>
            <br/><br/>
        </div>
    )
}

const mapStateToProps = (state) => ({
    home: getNosotrosState(state),
    cargando: getLoadingNosotros(state),
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
    traerHome: getNosotros,
    guardarHome: saveHomeDocument,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(FormualrioNosotros)
