/* eslint-disable import/no-anonymous-default-export */
import {
    CARGANDO_CITAS_PSICOLOGO,
    CARGANDO_PACIENTES,
    CARGANDO_PERFIL,
    CARGANDO_PERFIL_PACIENTE,
    CARGANDO_TERAPIAS_PSICOLOGO,
    CONSULTAR_TAREA_ACTIVIDADES,
    DELETE_TERAPIA_ACTUAL,
    GET_DB_ACTIVIDADES_TAREA_PSICOLOGO,
    GET_DB_CITAS_PSICOLOGO,
    GET_DB_DETALLE_TERAPIA_PSICOLOGO,
    GET_DB_PACIENTES_PSICOLOGO,
    GET_DB_PERFIL_PACIENTE,
    GET_DB_PERFIL_PSICOLOGO,
    GET_DB_TERAPIAS_PSICOLOGO,
    GUARDANDO_ACTIVIDAD,
    SET_TAREA_ACTUAL,
    TRAER_TAREAS_PACIENTE, TRAER_TERAPIA_ACTIVA,
    APAGAR_LISTENER_ACTIVIDADES
} from './../actions/terapeutaActions';
import { createSelector } from 'reselect';
import { CLEAN_TERAPEUTA } from '../utilidades/Constantes';

const INITIAL_STATE = {
    pacientes: null,
    terapias: null,
    cargandoTerapias: false,
    terapia: null,
    citas: null,
    perfilTerapeuta: null,
    tareaActual: null,
    estadoCreacion: null,
    cargandoPerfil: false,
    cargandoCitas: false,
    cargandoPacientes: false,
    listenerActividades: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CARGANDO_TERAPIAS_PSICOLOGO: {
            return { ...state, cargandoTerapias: action.payload };
        }
        case CARGANDO_PERFIL: {
            return { ...state, cargandoPerfil: action.payload };
        }
        case CARGANDO_CITAS_PSICOLOGO: {
            return { ...state, cargandoCitas: action.payload };
        }
        case CARGANDO_PACIENTES: {
            return { ...state, cargandoPacientes: action.payload };
        }
        case CARGANDO_PERFIL_PACIENTE: {
            return { ...state, cargandoPerfilPaciente: action.payload }
        }
        case TRAER_TAREAS_PACIENTE: {
            const tareasPaciente = action.payload;
            let pacientes
            if (state.pacientes)
                pacientes = state.pacientes.map(paciente => {
                    if (action.options.idPaciente === paciente.uidDoc) {
                        let terapiasActivas = paciente.terapiasActivas ?? []
                        let terapias = terapiasActivas.map(terapia => {
                            if (action.options.idTerapia === terapia.uidDoc) {
                                return { ...terapia, tareas: tareasPaciente }
                            }
                            return terapia
                        })
                        return { ...paciente, terapiasActivas: terapias }
                    }
                    return paciente
                })
            return { ...state, pacientes };
        }
        case TRAER_TERAPIA_ACTIVA: {
            if (!state.pacientes)
                return state
            let pacienteActual = state.pacientes.find(paciente => paciente.uidDoc === action.options.idPaciente)
            if (!pacienteActual.terapiasActivas)
                return state
            let pacientes
            if (state.pacientes) {
                pacientes = state.pacientes.map(paciente => {
                    if (action.options.idPaciente === paciente.uidDoc) {
                        let terapiasActivas = paciente.terapiasActivas ?? []
                        let terapias = terapiasActivas.map(terapia => {
                            if (action.options.idTerapia === terapia.uidDoc) {
                                if (terapia.tareas) {
                                    return { ...action.payload, uidDoc: terapia.uidDoc, tareas: terapia.tareas }
                                }
                            }
                            return terapia
                        })
                        return { ...paciente, terapiasActivas: terapias }
                    }
                    return paciente
                })
            }
            return { ...state, pacientes };
        }
        case GET_DB_TERAPIAS_PSICOLOGO: {
            return { ...state, terapias: action.payload, cargandoTerapias: false };
        }
        case GET_DB_PERFIL_PSICOLOGO: {
            const perfilTerapeuta = action.payload;
            return { ...state, perfilTerapeuta, cargandoPerfil: false };
        }
        case GET_DB_PACIENTES_PSICOLOGO: {
            if(!state.pacientes)
                return { ...state, cargandoPacientes: false, pacientes: action.payload }
            else
                return { ...state, cargandoPacientes: false }
        }
        case GET_DB_DETALLE_TERAPIA_PSICOLOGO: {
            const terapia = action.payload;
            return { ...state, terapia };
        }
        case DELETE_TERAPIA_ACTUAL: {
            const terapia = action.payload;
            return { ...state, terapia };
        }
        case SET_TAREA_ACTUAL: {
            return { ...state, tareaActual: action.payload };
        }
        case GET_DB_ACTIVIDADES_TAREA_PSICOLOGO: {
            const actividades = action.payload.payload;
            let tareas = [...state.terapia.tareas]

            tareas.forEach((t, i) => {
                if (t.uidDoc === action.payload.tarea) {
                    tareas[i] = { ...tareas[i], actividades: actividades }
                    return
                }
            })
            const terapia = {
                ...state.terapia,
                tareas: tareas,
            }
            return { ...state, terapia: terapia };
        }
        case APAGAR_LISTENER_ACTIVIDADES: {
            return { ...state, listenerActividades: action.payload};
        }
        case GET_DB_CITAS_PSICOLOGO: {
            const citas = action.payload;
            return { ...state, citas, cargandoCitas: false };
        }
        case GET_DB_PERFIL_PACIENTE: {
            const perfilPaciente = action.payload
            delete perfilPaciente.uidDocumento

            let pacientes

            if (state.pacientes)
                pacientes = state.pacientes.map(paciente => {
                    if (perfilPaciente.uidDoc === paciente.uidDoc)
                        return { ...perfilPaciente, revisar: !!paciente.revisar, terapiasActivas: paciente.terapiasActivas ?? perfilPaciente.terapiasActivas }
                    return paciente
                })
            else
                pacientes = [perfilPaciente]
            return { ...state, pacientes, cargandoPerfilPaciente: false };
        }
        case CONSULTAR_TAREA_ACTIVIDADES: {
            let nuevaTarea = action.payload
            let idPaciente = action.options.idPaciente
            let idTerapia = action.options.idTerapiaActiva

            let pacientes = state.pacientes.map(paciente => {
                if (paciente.uidDoc === idPaciente) {
                    let terapiasActivas = paciente.terapiasActivas?.map(terapia => {
                        if (terapia.uidDoc === idTerapia) {
                            let tareas
                            if (terapia.tareas) {
                                tareas = terapia.tareas?.map(tarea => {
                                    if (tarea.uidDoc === nuevaTarea.uidDoc)
                                        return nuevaTarea
                                    else
                                        return tarea
                                })
                            } else
                                tareas = [nuevaTarea]
                            return { ...terapia, tareas }
                        } else {
                            return terapia
                        }
                    })
                    return { ...paciente, terapiasActivas }
                } else {
                    return paciente
                }
            })
            return { ...state, pacientes }
        }
        case GUARDANDO_ACTIVIDAD: {
            return { ...state, estadoCreacion: action.payload };
        }
        case CLEAN_TERAPEUTA: {
            return {
                pacientes: null,
                terapias: null,
                cargandoTerapias: false,
                terapia: null,
                citas: null,
                perfilTerapeuta: null,
                tareaActual: null,
                estadoCreacion: null,

                cargandoPerfil: false,
                cargandoCitas: false,
                cargandoPacientes: false,

            };
        }
        default:
            return state;
    }
}

export const getEstadoCreacion = createSelector(state => state.terapeutaReducer, terapeutaReducer => terapeutaReducer.estadoCreacion);

export const getTerapiasPsicologo = createSelector(state => state.terapeutaReducer, terapeutaReducer => terapeutaReducer.terapias);

export const getPerfilPsicologo = createSelector(state => state.terapeutaReducer, terapeutaReducer => terapeutaReducer.perfilTerapeuta);

export const getCargandoTerapias = createSelector(state => state.terapeutaReducer, terapeutaReducer => terapeutaReducer.cargandoTerapias)

export const getPacientesPsicologo = createSelector(state => state.terapeutaReducer, terapeutaReducer => {
    if (!terapeutaReducer.pacientes)
        return null

    let pacientesPorRevisar = terapeutaReducer.pacientes.filter(paciente => paciente.revisar)
    let pacientesSinPorRevisar = terapeutaReducer.pacientes.filter(paciente => !paciente.revisar)

    return [...pacientesPorRevisar, ...pacientesSinPorRevisar]
});

export const getTerapia = createSelector(state => state.terapeutaReducer, terapeutaReducer => terapeutaReducer.terapia);

export const getCargandoPerfil = createSelector(state => state.terapeutaReducer, terapeutaReducer => terapeutaReducer.cargandoPerfil)
export const getCargandoCitas = createSelector(state => state.terapeutaReducer, terapeutaReducer => terapeutaReducer.cargandoCitas)
export const getCargandoPacientes = createSelector(state => state.terapeutaReducer, terapeutaReducer => terapeutaReducer.cargandoPacientes)

export const getCitasPsicologo = createSelector(state => state.terapeutaReducer, terapeutaReducer => {
    if (!terapeutaReducer.citas || !terapeutaReducer.pacientes)
        return null

    let today = new Date()

    let citasVigentes = terapeutaReducer.citas.filter(cita => new Date(cita.fecha) >= new Date(today.toJSON().slice(0, 10)))

    return citasVigentes.map(cita => {
        let paciente = terapeutaReducer.pacientes.filter(paciente => paciente.uidDoc === cita.idPaciente)[0] ?? {}
        return { ...cita, nombrePaciente: paciente.nombre ?? "El paciente no existe" }
    }).sort((a, b) => {
        let dateA = new Date(`${a.fecha}T${a.inicio}`),
            dateB = new Date(`${b.fecha}T${b.inicio}`);
        return dateA - dateB;
    })
});

export const getPacienteActual = createSelector((state, id) => ({
    terapeutaReducer: state.terapeutaReducer,
    id
}), ({ terapeutaReducer, id }) => {
    if (!terapeutaReducer.pacientes)
        return null

    let pacienteActual = terapeutaReducer.pacientes.filter(paciente => paciente.uidDoc === id)[0]

    let terapiasActivas = pacienteActual.terapiasActivas ?? []

    let terapiasPorRevisar = terapiasActivas.filter(terapia => terapia.revisar)
    let terapiasSinPorRevisar = terapiasActivas?.filter(terapia => !terapia.revisar)

    return { ...pacienteActual, terapiasActivas: [...terapiasPorRevisar, ...terapiasSinPorRevisar] };
});

export const getTerapiaActual = createSelector((state, idPaciente, idTerapia) => ({
    terapeutaReducer: state.terapeutaReducer,
    idPaciente,
    idTerapia
}),
    ({ terapeutaReducer, idPaciente, idTerapia }) => {
        if (!terapeutaReducer.pacientes)
            return null
        let pacienteActual = terapeutaReducer.pacientes.find(paciente => paciente.uidDoc === idPaciente)
        if (!pacienteActual.terapiasActivas)
            return null
        return pacienteActual.terapiasActivas.find(terapia => terapia.uidDoc === idTerapia);
    });

export const getTareaActual = createSelector((state, idPaciente, idTerapiaActiva, idTarea) => ({ terapeutaReducer: state.terapeutaReducer, idPaciente, idTerapiaActiva, idTarea }), ({ terapeutaReducer, idPaciente, idTerapiaActiva, idTarea }) => {

    if (!terapeutaReducer.pacientes)
        return null

    let pacienteActual = terapeutaReducer.pacientes.find(paciente => paciente.uidDoc === idPaciente)

    if (!pacienteActual.terapiasActivas)
        return null

    let terapiaActiva = pacienteActual.terapiasActivas.find(terapia => terapia.uidDoc === idTerapiaActiva)

    if (!terapiaActiva || (terapiaActiva && !terapiaActiva.tareas))
        return null

    let tareaActual = terapiaActiva.tareas.find(tarea => tarea.uidDoc === idTarea)

    return tareaActual
})