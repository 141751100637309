import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { deleteUser } from '../../../../actions/adminActions';
import { ERROR, SIN_FOTO } from '../../../../utilidades/Constantes';
import { CargandoContext } from '../../../general/CargandoContext/CargandoContext';
import PerfilImg from './../../../../assets/ic_foto_perfil_azul.svg';

const DialogoEliminar = ({paciente, usuario_por_eliminar, cerrarDialogoHandleClick}) => {
    let dialogoRef = useRef()
    let history = useHistory()
    const [error, setError] = useState(null)

    const handleClickOutside = useCallback( event => {
        if (dialogoRef && !dialogoRef.current.contains(event.target))
            cerrarDialogoHandleClick()
    }, [cerrarDialogoHandleClick])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);            
        }
    }, [handleClickOutside])

    let { correrIndicadorCarga, quitarIndicadorCarga } = useContext(CargandoContext)

    const eliminarUsuario = async () => {
        setError(null)
        correrIndicadorCarga()
        let res = await deleteUser({
            uid: usuario_por_eliminar.uidDoc?usuario_por_eliminar.uidDoc:usuario_por_eliminar.uidDocumento,
            esPaciente: usuario_por_eliminar.paciente
        })
        quitarIndicadorCarga()
        if(res.estado === ERROR) {
            setError(res.mensaje)
        } else {
            cerrarDialogoHandleClick()
            history.push('/administrador')
        }
    }

    return (
        <div className='fondo-dialogo' style={{ display: "grid"}}>
            <div className='dialogo-eliminar' ref={dialogoRef}>
                <div className='dialogo-titulo'>
                    {`Eliminar ${paciente?"paciente":"terapeuta"}`}
                    <hr className='linea-dialogo' />

                </div>
                {
                    usuario_por_eliminar&&
                    <div className='eliminar-nombre-img'>
                            <img
                                className='profile-eliminar-img'
                                src={
                                    usuario_por_eliminar.urlFotoPerfil ? 
                                        usuario_por_eliminar.urlFotoPerfil === SIN_FOTO ? PerfilImg : usuario_por_eliminar.urlFotoPerfil:
                                        PerfilImg
                                }
                                alt="Imagen de perfil" />
                            <div>{usuario_por_eliminar.nombre}</div>
                    </div>
                }
                {
                    error &&
                    <div className="error"> 
                        {error}
                    </div>
                }
                {
                    usuario_por_eliminar&&
                    <div className='btns-dialogo-eliminar'>
                        <button className='btn-arketipos' onClick={cerrarDialogoHandleClick}>Cancelar</button>
                        <button className='btn-arketipos btn-azul' onClick={eliminarUsuario}>Aceptar</button>
                    </div>
                }
            </div>
        </div>
    );
}

export default DialogoEliminar;