import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import fotoDefault from '../../../../assets/ic_foto_perfil_naranja.svg'
import {SIN_FOTO} from "../../../../utilidades/Constantes";
import SkeletonResponsive from "../../../general/SkeletonResponsive";

const Resumen = ({nombre, pacientes, history, setPacientePorAsignarTerapia}) => {

    const revisarPaciente = (history, idPaciente) => {
        history.push("/terapeuta/pacientes/" + idPaciente);
    }

    const handleSetPacientePorAsignarTerapia = (paciente) => {
        setPacientePorAsignarTerapia(paciente)
    }

    const pintarPacientes = (listaPacientes) => (
        listaPacientes.map((paciente) =>
            <div className="fila-info" key={paciente.uidDoc}>
                <img className='foto-persona' src={
                    paciente.urlFotoPerfil ?
                        paciente.urlFotoPerfil === SIN_FOTO ? fotoDefault : paciente.urlFotoPerfil :
                        fotoDefault
                } alt="Imagen Terapeuta"/>
                <p className='nombre-paciente'>{paciente.nombre}</p>
                <button
                    className="btn-arketipos btn-naranja"
                    onClick={() => handleSetPacientePorAsignarTerapia(paciente)}>
                    Asignar terapia
                </button>
            </div>
        )
    )

    const pintarTareas = (listaTareas) => (
        listaTareas.map((tarea) => {
            return <div className="fila-info" key={tarea.uidDoc}>
                <img className='foto-persona' src={tarea.urlFoto ?
                    tarea.urlFoto === SIN_FOTO ? fotoDefault : tarea.urlFoto :
                    fotoDefault} alt="Imagen Terapeuta"/>
                <p className='nombre-paciente'>{tarea.nombre}</p>
                <button className="btn-arketipos btn-naranja" onClick={() => revisarPaciente(history, tarea.uidDoc)}>
                    Revisar
                </button>
            </div>
        })
    )
    let pacientesNuevos, pacientesTareas
    if (pacientes) {
        pacientesNuevos = pacientes.filter(paciente => paciente.estado === 0)
        pacientesTareas = pacientes.filter(paciente => paciente.estado === 2)
    }
    return (
        <div className="resumen-terapeuta">
            {nombre?<h1 className="titulo-bienvenida">{"¡Hola " + nombre + "!"}</h1>
                : <SkeletonResponsive tipo={'rect'} className='sk-titulo-bienvenida'/>}
            <div className="contenedor-resumen">
                <div className="contenedor-blanco">
                    <div className="espacio-texto">
                        {pacientesNuevos?<h4 className="subtitulo">Pacientes nuevos</h4>
                            :<SkeletonResponsive tipo={'rect'} className='sk-subtitulo'/>}
                        {pacientesNuevos ?
                            pacientesNuevos.length > 0 ? pintarPacientes(pacientesNuevos) :
                                <button className="btn-arketipos btn-terapeuta-sin-datos">No tienes pacientes
                                    nuevos</button>
                            : <div className='fila-info'>
                                <SkeletonResponsive tipo={'circle'} className='sk-foto-persona'/>
                                <SkeletonResponsive tipo={'rect'} className='sk-nombre-paciente'/>
                                <SkeletonResponsive tipo={'rect'} className='sk-btn-naranja'/>
                            </div>}
                    </div>
                </div>
                <div className="contenedor-blanco">
                    <div className="espacio-texto">
                        {pacientesTareas ?<h4 className="subtitulo">Tareas por revisar</h4>
                            :<SkeletonResponsive tipo={'rect'} className='sk-subtitulo'/>}
                        {pacientesTareas ?
                            pacientesTareas.length > 0 ? pintarTareas(pacientesTareas) :
                                <button className="btn-arketipos btn-terapeuta-sin-datos">No tienes tareas por
                                    revisar</button>
                        : <div className='fila-info'>
                                <SkeletonResponsive tipo={'circle'} className='sk-foto-persona'/>
                                <SkeletonResponsive tipo={'rect'} className='sk-nombre-paciente'/>
                                <SkeletonResponsive tipo={'rect'} className='sk-btn-naranja'/>
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}


Resumen.propTypes = {
    nombre: PropTypes.string.isRequired,
    pacientes: PropTypes.array.isRequired
};

export default withRouter(Resumen);