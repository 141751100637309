import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import CarruselPublicaciones from '../general/carrusel/Carrusel'
import Equipo from './components/Equipo'
import CarruselArketipos from './components/carruselArketipos/CarruselArketipos'
import { connect } from 'react-redux'
import { getHomeLanding } from '../../../actions/landingActions'
import { getHomeState, getLoadingHome } from '../../../reducers/landingReducer'
import SkeletonResponsive from '../../general/SkeletonResponsive'

import { bindActionCreators } from 'redux'
import BotonAgenda from '../general/BotonAgenda'

const textoFinal = (textoAuste) => {
    let texto = textoAuste.split("\\n")

    return texto.map((tx, i) => {

        return (<span key={`texto-${i}`}>{tx}<br /></span>)
    })
}


const Inicio = ({ home, traerHome, cargando }) => {

    useEffect(() => {
        if (!home && !cargando) traerHome()
        // eslint-disable-next-line
    }, [])

    const info = home ?? {}

    return (
        <div className="contenedor-landing">
            <div className="primera-division fondo-azul">
                <div>
                    {cargando ? <SkeletonResponsive tipo='text' className="sk-letra-grande" /> : <div className="letra-grande letra-blanca">
                        {info.tituloGrande ?? ''}
                    </div>}
                    {cargando ? <SkeletonResponsive tipo='text' className="sk-texto-sentido" /> : <p className="letra-blanca texto-sentido">
                        {info.tituloPequeno ?? ''}
                    </p>}
                    <BotonAgenda textColor="blue" />
                </div>
                <CarruselArketipos />
            </div>
            <div className="segunda-division fondo-blanco flex center">
                <div className="contenedor-texto-seccion">
                    {cargando ? <SkeletonResponsive tipo='text' className="sk-letra-titulos" /> : <div className="letra-titulos letra-azul bold">
                        {info.tituloSegundaSeccion ?? ''}
                    </div>}
                    {cargando ? <SkeletonResponsive tipo='text' className="sk-parrafo" /> : <p className="letra-secciones bold-300">
                        {textoFinal(info.textoSegundaSeccion ?? '')}
                    </p>}
                    <Link to="/login" className="btn-arketipos btn-administrador fondo-azul boton-home">Ingresa</Link>
                </div>
                {cargando ? <SkeletonResponsive tipo='text' className="imagen-seccion" /> :
                    <img className="imagen-seccion" src={info.imagenSegundaSeccion ?? ''} alt="imagen" />}
            </div>
            <div className="fondo-gris-claro">
                <CarruselPublicaciones arreglo={info.anuncios ?? []} izquierda={true} cargando={cargando} />
            </div>
            <Equipo integrantes={info.equipo ?? []} cargando={cargando} />
        </div>
    )

}

const mapStateToProps = (state) => ({
    home: getHomeState(state),
    cargando: getLoadingHome(state)
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    traerHome: getHomeLanding
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Inicio)