import React, { Component } from 'react'
import TextareaAutosize from 'react-autosize-textarea';
import Proptypes from 'prop-types'
import {getCargando, getChats, getError} from '../../reducers/chatReducer'
import * as actions from '../../actions/chatActions'
import { connect } from 'react-redux'
import { getPacientesPsicologo, getPerfilPsicologo } from '../../reducers/terapeutaReducer';
import { SIN_FOTO } from '../../utilidades/Constantes';
import sinFotoAzul from '../../assets/ic_foto_perfil_verde.svg'
import cerrar from '../../assets/ic_cerrar_gris.svg'
import sinFotoNaranja from '../../assets/ic_foto_perfil_naranja.svg'
import {mapToArray} from '../../utilidades/fechas'
import { getPaciente } from '../../reducers/pacienteReducer';

class Chat extends Component {

    state={
        texto:' ',
        buscar: '',
        pacienteActual: null
    }

    componentDidMount(){

        // consultarDatos('chats/fdasdkbgjahsf/', (datos)=>{
        //     let arreglo = []
        //     for(let key in datos.mensajes){
        //         arreglo.push({...datos.mensajes[key], uid:key})
        //     }
        //     this.setState({
        //         chat: {...datos, mensajes: arreglo}
        //     }, ()=>{
        //         var element = document.getElementById("contenedor-mensajes");
        //         element.scrollTop = element.scrollHeight;
        //     })
        // })
    }

    componentDidUpdate(prevProps, prevState) {
        const {perfil, pacientes, chats, traerChats, usuario, paciente} = this.props
        if(usuario === 'terapeuta'){
            if(perfil && pacientes){
                if(!chats)
                    traerChats(perfil.uidDocumento, true)
            }
        }
        if(usuario === 'paciente'){
            if(paciente){
                if(!chats)
                    traerChats(paciente.uidDoc, false)
            }
        }
    }

    handleChange = (e) =>{
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit = (e, chat) =>{
        const {crearChat, enviarMensaje, perfil, paciente, usuario} = this.props
        e.preventDefault()
        
        if(this.state.texto === "" || this.state.texto === " "){
            return
        }
        
        if(!chat){

            let pacienteActual = null
            let psicologo = null

            if(usuario === "terapeuta"){
                pacienteActual = this.state.pacienteActual.uidDoc
                psicologo = perfil.uidDocumento
            }

            if(usuario === "paciente"){
                pacienteActual = paciente.uidDoc
                psicologo = this.state.pacienteActual.uidDoc
            }

            if(pacienteActual === null || psicologo === null) return

            crearChat({
                paciente: pacienteActual,
                psicologo: psicologo,
                mensajes: {
                    [Date.now()]:{
                        mensaje: this.state.texto,
                        fecha: Date.now(),
                        psicologo: usuario === "terapeuta"
                    }
                }
            })
        }
        else{
            enviarMensaje(chat.id, {
                mensaje: this.state.texto,
                fecha: Date.now(),
                psicologo: usuario === "terapeuta"
            })
        }

        this.setState({
            texto:''
        },()=>{
            var element = document.getElementById("contenedor-mensajes");
            element.scrollTop = element.scrollHeight;
        })
    }

    handleKeyPress = (e, chat) =>{
        if(e.which === 13 && !e.shiftKey){
            e.preventDefault()
            this.handleSubmit(e, chat)
        }
    }

    ajustarFecha = (f) =>{
        let hora
        let meridiano
        let horas = f.getHours()
        if(horas <= 12){
            if(horas === 12)
                meridiano = 'Pm'
            else
                meridiano = 'Am'

            if(horas < 10)
                horas = `0${f.getHours()}`
            else
                horas = `${f.getHours()}`
        }
        else{
            meridiano = 'Pm'
            horas = `${f.getHours()-12}`
        }

        let minutos = f.getMinutes()
        if(minutos <  10){
            minutos = `0${f.getMinutes()}`
        }

        hora = `${horas}:${minutos} ${meridiano}`
        return hora
    }


    compararFecha = (f) =>{
        let fc = new Date()

        let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

        if(
            fc.getDay() === f.getDay()
            && fc.getMonth() === f.getMonth()
            && fc.getFullYear() === f.getFullYear()
        )
            return 'Hoy'
        else if(fc.getFullYear() > f.getFullYear())
            return [f.getDay(), f.getMonth(), f.getFullYear()].join(' de ')
        else
            return [f.getDay(), meses[f.getMonth()]].join(' de ')
            
    }

    fechaActual = null

    seleccionarChat = (paciente) => {
        this.setState({
            pacienteActual: paciente
        }, ()=>{
            var element = document.getElementById("contenedor-mensajes");
            element.scrollTop = element.scrollHeight;
        })
    }

    render() {

        const usuario = this.props.usuario


        const claseMensaje = (tipo) =>{
            if(usuario === "paciente"){
                if(tipo) return 'mensaje-paciente'
                else return `mensaje-psicologo fondo-${this.props.usuario}`
            }
            if(usuario === "terapeuta"){
                if(tipo) return `mensaje-psicologo fondo-${this.props.usuario}`
                else return 'mensaje-paciente'
            }
            return 'mensaje-paciente'
        }

        let pacientes = this.props.pacientes??[]
        let perfil = this.props.perfil??{}
        let paciente = this.props.paciente??{}
        let sinFoto = sinFotoAzul
        let chats = mapToArray(this.props.chats??{})
        let chat = null
        let mensajes = []
        let pacienteActual = this.state.pacienteActual
        if(usuario === 'paciente'){
            pacientes = paciente.psicologos??[]
        }
        if(pacienteActual){
            chats.forEach((c)=>{
                if(usuario === "terapeuta")
                    if(c.psicologo === perfil.uidDocumento && c.paciente === pacienteActual.uidDoc){
                        chat = c
                        mensajes = mapToArray(c.mensajes??{})
                        return
                    }
                if(usuario === "paciente")
                    if(c.paciente === paciente.uidDoc && c.psicologo === pacienteActual.uidDoc){
                        chat = c
                        mensajes = mapToArray(c.mensajes??{})
                        return
                    }
            })
        }

        if(this.props.usuario === 'terapeuta') sinFoto = sinFotoNaranja

        return (
            <div className={`contenedor-chat ${window.innerWidth<800&&'chat-movil'}`}>
                <div className={`contenedor-chats ${pacienteActual&&'lista-chat-seleccionado'}`}>
                    <div className="contenedor-buscar">
                        <input type="text" className={`input-${this.props.usuario}`} name="buscar" value={this.state.buscar} 
                            onChange={(e)=>this.setState({buscar: e.target.value})}
                            placeholder="Buscar"
                        />
                    </div>
                    <div className="lista-chats">
                        {pacientes.map((paciente, i) =>(
                            <div className="usuario-chat" key={`paciente-${i}`} onClick={()=>this.seleccionarChat(paciente)}>
                                <div className="contenedor-imagen">
                                    <img src={
                                        paciente.urlFotoPerfil === SIN_FOTO?
                                        sinFoto:
                                        paciente.urlFotoPerfil
                                    }
                                    alt="imagen chat"/>
                                    <nav/>
                                </div>
                                <span>{paciente.nombre}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={`chat ${pacienteActual&&'chat-seleccionado'}`}>
                    {pacienteActual === null?
                    <div className="informacion-chat">
                        <img src={sinFoto} alt="imagen chat"/>
                        <span>No ha seleccionado ningún chat</span>
                    </div>
                    :
                    <div className="informacion-chat">
                        <div className="informacion-chat">
                            <img src={
                                pacienteActual.urlFotoPerfil === SIN_FOTO?
                                sinFoto:
                                pacienteActual.urlFotoPerfil
                            } alt="imagen chat"/>
                            <span>{pacienteActual.nombre??''}</span>
                        </div>
                        <img src={cerrar} alt="cerrar" className="cerrar-chat" onClick={()=>this.setState({pacienteActual: null})}/>
                    </div>
                    }
                    <div className="contenedor-mensajes" id="contenedor-mensajes">
                        {
                            mensajes.map((m, i)=>{

                                let f = new Date(m.fecha)

                                // let fecha = this.ajustarFecha(f)

                                let fechaDia = this.compararFecha(f)

                                if(this.fechaActual !== fechaDia){
                                    this.fechaActual = fechaDia
                                    return (
                                        <div className="contenedor-fecha" key={`mensaje-${i}`}>
                                            <div className="fecha-mensaje">{fechaDia}</div>
                                            <div className={`${claseMensaje(m.psicologo)} contenedor-mensaje`}>
                                                <span className="mensaje">
                                                    {m.mensaje}
                                                    {/* <span className="hora-mensaje">{fecha}</span> */}
                                                </span>
                                            </div>
                                        </div>
                                    )
                                }

                                return (
                                    <div className={`${claseMensaje(m.psicologo)} contenedor-mensaje`} key={`mensaje-${i}`}>
                                        <span className="mensaje">
                                            {m.mensaje}
                                            {/* <span className="hora-mensaje">{fecha}</span> */}
                                        </span>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <form className="contenedor-enviar-mensaje" onSubmit={(e)=>this.handleSubmit(e, chat)}>
                        <TextareaAutosize
                            value={this.state.texto}
                            onChange={this.handleChange}
                            placeholder="Escribe un mensaje"
                            name="texto"
                            className={`textarea-${this.props.usuario}`}
                            onKeyPress={(e)=>this.handleKeyPress(e, chat)}
                            disabled={this.state.pacienteActual===null}
                            required
                        />
                        <button 
                            className={`btn-arketipos fondo-${this.props.usuario}`}
                            disabled={this.state.pacienteActual===null}
                        >Enviar</button>
                    </form>
                </div>
            </div>
        )
    }
}

Chat.propTypes = {
    usuario: Proptypes.string.isRequired
}

const mapStateToProps = state => ({
    chats: getChats(state),
    cargando: getCargando(state),
    erro: getError(state),
    pacientes: getPacientesPsicologo(state),
    perfil: getPerfilPsicologo(state),
    paciente: getPaciente(state)
});

export default connect(mapStateToProps, actions)(Chat);