import React from 'react'
import PropTypes from 'prop-types'

const TextoInterlineado = ({texto, className, numero}) => {
    return (
        <p className={className}>
            {numero && `${numero??''}. `}
           {texto.split('\\n').map((seccion)=><span key={seccion}>{seccion}<br/></span>)} 
        </p>
    )
}

TextoInterlineado.propTypes = {
    texto: PropTypes.string.isRequired,
    className: PropTypes.string,
    numero: PropTypes.number,
}

export default TextoInterlineado
