import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import './App.css'
import './screens/general/main.sass'
import Administrador from './screens/administrador/Administrador';
import Paciente from "./screens/paciente/Paciente";
import Terapeuta from "./screens/terapeuta/Terapeuta";
import RegistroTerapeuta from './screens/registro/RegistroTerapeuta';
import Chat from './screens/general/Chat';
import Landing from './screens/landing/Landing';
import AdministradorLanding from './screens/administradorLanding/AdministradorLanding';

const App = (props) =>(
    <BrowserRouter>
        <Switch>
            <Route path='/registro-terapeuta' component={RegistroTerapeuta}/>
            <Route path='/administrador-landing' component={AdministradorLanding}/>
            <Route path='/administrador' component={Administrador}/>
            <Route path='/paciente' component={Paciente}/>
            <Route path='/terapeuta' component={Terapeuta}/>
            <Route path='/chat' component={Chat}/>
            <Route path='/' component={Landing}/>
        </Switch>
    </BrowserRouter>
)

export default App;