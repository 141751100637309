import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { aprobarPaciente, rechazarPaciente } from '../../../../actions/adminActions'
import { ERROR } from '../../../../utilidades/Constantes'
import { CargandoContext } from '../../../general/CargandoContext/CargandoContext'

const DialogoAprobar = ({paciente, cerrarDialogoHandleClick}) => {
    
    const [error, setError] = useState(null)
    
    let dialogoRef = useRef()

    let { correrIndicadorCarga, quitarIndicadorCarga } = useContext(CargandoContext)

    const handleClickOutside = useCallback( event => {
        if (dialogoRef && !dialogoRef.current.contains(event.target))
            cerrarDialogoHandleClick()
    }, [cerrarDialogoHandleClick])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);            
        }
    }, [handleClickOutside])

    const handleAprobarPaciente = async () => {
        setError(null)
        correrIndicadorCarga()
        let res = await aprobarPaciente(paciente)
        quitarIndicadorCarga()

        if(res === ERROR)
            setError('Hubo un inconveniente, por favor intentalo más tarde')
        else
            cerrarDialogoHandleClick()
    }

    const handleRechazarPaciente = async () => {
        setError(null)
        correrIndicadorCarga()
        let res = await rechazarPaciente({
            uid: paciente.uidDoc
        }, paciente)
        quitarIndicadorCarga()

        if(res.estado === ERROR) {
            setError(res.mensaje)
        } else {
            cerrarDialogoHandleClick()
        }
    }

    return (
        <div className='fondo-dialogo'>
            <div className='dialogo-aprobar-paciente' ref={dialogoRef}>
                <div className='contenido-dialogo'>
                    <div className='titulo-dialogo'>
                        {paciente.nombre}
                        <hr />
                    </div>
                    <div className='contenedor-dato'>
                        <div className='nombre-dato'>
                            Correo:
                        </div>
                        <div className='valor'>
                            {paciente.correo}
                        </div>
                    </div>
                    <div className='contenedor-dato'>
                        <div className='nombre-dato'>
                            Celular:
                        </div>
                        <div className='valor'>
                            {paciente.telefono}
                        </div>
                    </div>
                    <div className='contenedor-dato'>
                        <div className='nombre-dato'>
                            Información:
                        </div>
                        <div className='valor informacion'>
                            {paciente.descripcion}
                        </div>
                    </div>
                    {
                        error && 
                        <div className='error'>{error}</div>
                    }
                    <div className='botones'>
                        <button 
                            className='btn-arketipos'
                            onClick={handleRechazarPaciente}>Rechazar</button>
                        <button 
                            className='btn-arketipos btn-aprobar'
                            onClick={handleAprobarPaciente}>Aprobar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DialogoAprobar