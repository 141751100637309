import React, { Component } from 'react';
import ResumenAdministrador from './components/ResumenAdministrador/ResumenAdministrador';
import DialogoAsignar from './components/DialogoAsignar/DialogoAsignar';
import Gestionar from './components/Gestionar/Gestionar';
import HeaderApp from '../general/HeaderApp';
import DialogoEliminar from './components/DialogoEliminar/DialogoEliminar';
import { Route, Switch, withRouter } from 'react-router-dom';
import Perfil from './../general/Perfil/Perfil';
import { connect } from 'react-redux';
import * as actions from '../../actions/adminActions';
import { getUserInfo } from '../../reducers/sesionReducer';
import { bindActionCreators } from 'redux';
import { getPsicologos, getPacientes, getPacientesSinAsignar, getPacientesSinAprobar } from '../../reducers/adminReducer';
import PerfilPacienteAdmin from './components/PerfilUsuario/PerfilPacienteAdmin';
import PerfilTerapeutaAdmin from './components/PerfilUsuario/PerfilTerapeutaAdmin';
import Menu from '../general/Menu/Menu';
import { CargandoContext } from './../general/CargandoContext/CargandoContext';
import LoadingIndicator from '../general/loadingIndicator/LoadingIndicator';
import DialogoAprobar from './components/dialogoAprobar/DialogoAprobar';
import DialogoAgregarTerapeuta from './components/dialogoAgregarTerapeuta/DialogoAgregarTerapeuta';
import PacientesTerapeuta from './components/pacientesPsicologo/PacientesTerapeuta';
import TerapiasPaciente from './components/terapiasPaciente/TerapiasPaciente'
import TareasTerapia from './components/terapiasPaciente/tareasPaciente/TareasTerapia'
import ActividadesPaciente from './components/actividadesPacientes/ActividadesPaciente';
class Administrador extends Component {

    state = {
        asignando_terapeuta: false,
        usuario_por_eliminar: null,
        pacientePorAsignar: null,
        pacientePorAprobar: null,
        agregarTerapeuta: false,
        cargando: false
    }

    componentDidMount() {
        const { getDBUsers } = this.props;
        getDBUsers();
    }

    setPacientePorAsignar = (paciente) => {
        this.setState({
            pacientePorAsignar: paciente
        })
    }

    quitarPacientePorAsignar = () => {
        this.setState({
            pacientePorAsignar: null
        })
    }

    quitarPacientePorAprobar = () => {
        this.setState({
            pacientePorAprobar: null
        })
    }

    setPacientePorAsignar = (paciente) => {
        this.setState({
            pacientePorAsignar: paciente
        })
    }

    setPacientePorAprobar = (paciente) => {
        this.setState({
            pacientePorAprobar: paciente
        })
    }

    setUsuarioPorEliminar = (user, paciente) => {
        this.setState({ usuario_por_eliminar: { ...user, paciente } })
    }

    quitarUsuarioPorEliminar = () => {
        this.setState({ usuario_por_eliminar: null })
    }

    render() {

        return (
            <CargandoContext.Provider value={{
                correrIndicadorCarga: () => this.setState({ cargando: true }),
                quitarIndicadorCarga: () => this.setState({ cargando: false }),
            }}>
                <div className='pagina-administrador'>
                    <HeaderApp />
                    <Menu usuario='administrador' />
                    <hr className='linea-menu' />
                    <div className='contenedor-administrador'>
                        <Switch>
                            <Route path='/administrador/perfil'>
                                <Perfil
                                    usuario='administrador'
                                    profileInfo={this.props.profileInfo}
                                    updateInfo={this.props.updateAdminProfileInfo} />
                            </Route>
                            <Route path={["/administrador/pacientes/:idPaciente/terapeutas/:idTerapeuta/terapias/:idTerapiaActiva/tareas/:idTarea"]} >
                                <ActividadesPaciente esPsicologo={false}/>
                            </Route>
                            <Route path={["/administrador/terapeutas/:idTerapeuta/pacientes/:idPaciente/terapias/:idTerapiaActiva/tareas/:idTarea"]} >
                                <ActividadesPaciente esPsicologo={true}/>
                            </Route>
                            <Route path={"/administrador/pacientes/:idPaciente/terapeutas/:idTerapeuta/terapias/:idTerapia"} >
                                <TareasTerapia esPsicologo={false}/>
                            </Route>
                            <Route path={"/administrador/terapeutas/:idTerapeuta/pacientes/:idPaciente/terapias/:idTerapia"} >
                                <TareasTerapia esPsicologo={true}/>
                            </Route>
                            <Route path="/administrador/pacientes/:idPaciente/terapeutas/:idTerapeuta" >
                                <TerapiasPaciente esPsicologo={false} />
                            </Route>
                            <Route path="/administrador/pacientes/:id">
                                <PerfilPacienteAdmin
                                    setUsuarioPorEliminar={this.setUsuarioPorEliminar} />
                            </Route>
                            <Route path='/administrador/pacientes'>
                                <Gestionar
                                    usuarios={this.props.pacientes}
                                    pacientes={true}
                                    setUsuarioPorEliminar={this.setUsuarioPorEliminar}
                                    asignarTerapeutaHandleClick={this.setPacientePorAsignar} />
                            </Route>
                            <Route path="/administrador/terapeutas/:idTerapeuta/pacientes/:idPaciente" >
                                <TerapiasPaciente esPsicologo={true} />
                            </Route>
                            <Route path="/administrador/terapeutas/:id/pacientes" >
                                <PacientesTerapeuta />
                            </Route>
                            <Route path="/administrador/terapeutas/:id" >
                                <PerfilTerapeutaAdmin
                                    setUsuarioPorEliminar={this.setUsuarioPorEliminar} />
                            </Route>
                            <Route path='/administrador/terapeutas'>
                                <Gestionar
                                    usuarios={this.props.psicologos}
                                    pacientes={false}
                                    setUsuarioPorEliminar={this.setUsuarioPorEliminar}
                                    eliminarUsuarioHandleClick={this.eliminarUsuario}
                                    handleAgregarTerapeuta={() => this.setState({ agregarTerapeuta: true })} />
                            </Route>
                            <Route path='/administrador'>
                                <ResumenAdministrador
                                    nombre={this.props.profileInfo ? this.props.profileInfo.nombre.split(" ")[0] : ""}
                                    pacientes_sin_asignar={this.props.pacientes_sin_asignar}
                                    pacientesSinAprobar={this.props.pacientesSinAprobar}
                                    asignarTerapeutaHandleClick={this.setPacientePorAsignar}
                                    setUsuarioPorEliminar={this.setUsuarioPorEliminar}
                                    setPacientePorAprobar={this.setPacientePorAprobar} />
                            </Route>
                        </Switch>
                    </div>
                    {
                        this.state.pacientePorAsignar &&
                        <DialogoAsignar
                            paciente={this.state.pacientePorAsignar}
                            psicologos={this.props.psicologos}
                            visible={this.state.pacientePorAsignar}
                            cerrarDialogoHandleClick={this.quitarPacientePorAsignar} />
                    }
                    {
                        this.state.pacientePorAprobar &&
                        <DialogoAprobar
                            paciente={this.state.pacientePorAprobar}
                            cerrarDialogoHandleClick={this.quitarPacientePorAprobar} />
                    }
                    {
                        this.state.agregarTerapeuta &&
                        <DialogoAgregarTerapeuta
                            cerrarDialogoHandleClick={() => this.setState({ agregarTerapeuta: false })} />
                    }
                    {
                        this.state.usuario_por_eliminar &&
                        <DialogoEliminar
                            paciente={this.state.usuario_por_eliminar && this.state.usuario_por_eliminar.paciente}
                            usuario_por_eliminar={this.state.usuario_por_eliminar}
                            cerrarDialogoHandleClick={this.quitarUsuarioPorEliminar} />
                    }
                    {
                        this.state.cargando &&
                        <LoadingIndicator
                            color='rgb(65,142,238)' />
                    }
                </div>
            </CargandoContext.Provider>
        );
    }
}

const mapStateToProps = state => ({
    profileInfo: getUserInfo(state),
    pacientes_sin_asignar: getPacientesSinAsignar(state),
    pacientes: getPacientes(state),
    psicologos: getPsicologos(state),
    pacientesSinAprobar: getPacientesSinAprobar(state)
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Administrador))