import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createAd, updateService, deleteAd, createService, getServicios, saveHomeDocument, updateAd, deleteService } from '../../../actions/landingActions'
import { getLoadingServicios, getServiciosState } from '../../../reducers/landingReducer'
import FormualrioCarrusel from '../formualrioHome/componentes/FormualrioCarrusel'
import FormularioServicio from './componentes/FormularioServicio'

const FormularioServicios = ({
    servicios, traerHome, cargando, guardarHome, 
    actualizarAnuncio, crearAnuncio, eliminarAnuncio,
    actualizarIntegrante, crearIntegrante, eliminarIntegrante
}) => {

    const [documentoServicios, setdocumentoServicios] = useState({})
    const [psicologia, setPsicologia] = useState([])
    const [laborSocial, setLaborSocial] = useState([])
    const [formacion, setFormacion] = useState([])
    const [consultoria, setConsultoria] = useState([])
    const [agregarPsicologia, setAgregarPsicologia] = useState(false)
    const [agregarLaborSocial, setAgregarLaborSocial] = useState(false)
    const [agregarFormacion, setAgregarFormacion] = useState(false)
    const [agregarConsultoria, setAgregarConsultoria] = useState(false)
    const [seccionActual, setSeccionActual] = useState(0)
    

    useEffect(() => {
        if(!servicios && !cargando) traerHome()

        if(servicios && !cargando){
            setdocumentoServicios({
                tituloGrande: servicios.tituloGrande,
                aliados: servicios.aliados,
            })
            setPsicologia(servicios.psicologia??[])
            setLaborSocial(servicios.laborSocial??[])
            setFormacion(servicios.formacion??[])
            setConsultoria(servicios.consultoria??[])
        }

        // eslint-disable-next-line
    }, [servicios])

    const handleChangeHome = (e) => {
        setdocumentoServicios({
            ...documentoServicios,
            [e.target.name]: e.target.value.replaceAll('\n', '\\n')
        })
    }

    const guardardocumentoServicios = (e) => {
        e.preventDefault()
        guardarHome(documentoServicios, 'servicios')
    }

    const guardarAnuncio = (e, id, documento, servicio) => {
        e.preventDefault()
        actualizarAnuncio(id, documento, servicio)
    }

    const crearNuevoAnuncio = (e, id, documento, servicio) => {
        e.preventDefault()
        crearAnuncio(documento, servicio)
        if(servicio === "formacion")
            setAgregarFormacion(false)
        if(servicio === "psicologia")
            setAgregarPsicologia(false)
        if(servicio === "laborSocial")
            setAgregarLaborSocial(false)
    }
    const eliminarAd = (id, documento, servicio) => {
        eliminarAnuncio(id, documento, servicio)
    }

    const guardarIntegrante = (e, id, documento) => {
        delete documento.imagen
        e.preventDefault()
        actualizarIntegrante(id, documento)
    }

    const crearNuevoIntegrante = (e, id, documento) => {
        e.preventDefault()
        crearIntegrante(documento)
        setAgregarConsultoria(false)
    }
    const eliminarMember = (id) => {
        eliminarIntegrante(id)
    }

    return (
        <div className="contenedor-formualrios-landing center ai-start flex-column">
            <hr className="separador-landing"/>

            <div className="center contenedor-opciones-admin-landing opciones-opciones">
                <button onClick={()=>setSeccionActual(0)} className={`opciones-admin-landing base-boton ${seccionActual === 0 && 'seleccionada'}`}>Sección verde</button>
                <button onClick={()=>setSeccionActual(1)} className={`opciones-admin-landing base-boton ${seccionActual === 1 && 'seleccionada'}`}>Psicología</button>
                <button onClick={()=>setSeccionActual(2)} className={`opciones-admin-landing base-boton ${seccionActual === 2 && 'seleccionada'}`}>Formación</button>
                <button onClick={()=>setSeccionActual(3)} className={`opciones-admin-landing base-boton ${seccionActual === 3 && 'seleccionada'}`}>Labor social</button>
                <button onClick={()=>setSeccionActual(4)} className={`opciones-admin-landing base-boton ${seccionActual === 4 && 'seleccionada'}`}>Consultoría</button>
            </div>

            <hr className="separador-landing"/>
            {seccionActual === 0 && <form className="flex flex-column" onSubmit={guardardocumentoServicios}>
                <h3>Sección verde</h3>
                <label className="flex flex-column campo-landing">
                    <span>Título </span>
                    <input type="text" name="tituloGrande" onChange={handleChangeHome} value={documentoServicios.tituloGrande??''}/>
                </label>
                <br/><br/>
                <h3>Aliados</h3>
                ...
                <button className="btn-arketipos fondo-azul">Guardar Cambios</button>
            </form>}
            {/* <br/><br/>
            <hr className="separador-landing"/>
            <br/><br/> */}
            {seccionActual === 1 && <div>
                <h3>Carrusel psicología</h3>
                {psicologia.map((anuncio, i) => {
                    return <FormualrioCarrusel 
                        key={`anuncio-${i}`}
                        anuncio={anuncio}
                        nuevo={false}
                        onSubmit={(e, id, documento)=>guardarAnuncio(e, id, documento, 'psicologia')}
                        eliminar={(id, documento)=>eliminarAd(id, documento, 'psicologia')}
                    />
                })}
                {agregarPsicologia && <FormualrioCarrusel 
                        anuncio={{}}
                        nuevo={true}
                        onSubmit={(e, id, documento) => crearNuevoAnuncio(e, id, documento, 'psicologia')}
                        eliminar={()=>{}}
                    />}
                <button className="btn-arketipos fondo-azul" onClick={()=>setAgregarPsicologia(!agregarPsicologia)}>
                    {!agregarPsicologia ? 'Agregar nuevo anuncio' : 'Cancelar'}
                </button>
            </div>}

            {/* <br/><br/>
            <hr className="separador-landing"/>
            <br/><br/> */}
            {seccionActual === 2 && <div>
                <h3>Carrusel formación</h3>
                {formacion.map((anuncio, i) => {
                    return <FormualrioCarrusel 
                        key={`anuncio-${i}`}
                        anuncio={anuncio}
                        nuevo={false}
                        onSubmit={(e, id, documento)=>guardarAnuncio(e, id, documento, 'formacion')}
                        eliminar={(id, documento)=>eliminarAd(id, documento, 'formacion')}
                    />
                })}
                {agregarFormacion && <FormualrioCarrusel 
                        anuncio={{}}
                        nuevo={true}
                        onSubmit={(e, id, documento) => crearNuevoAnuncio(e, id, documento, 'formacion')}
                        eliminar={()=>{}}
                    />}
                <button className="btn-arketipos fondo-azul" onClick={()=>setAgregarFormacion(!agregarFormacion)}>
                    {!agregarFormacion ? 'Agregar nuevo anuncio' : 'Cancelar'}
                </button>
            </div>}

            {/* <br/><br/>
            <hr className="separador-landing"/>
            <br/><br/> */}
            {seccionActual === 3 && <div>
                <h3>Carrusel labor social</h3>
                {laborSocial.map((anuncio, i) => {
                    return <FormualrioCarrusel 
                        key={`anuncio-${i}`}
                        anuncio={anuncio}
                        nuevo={false}
                        onSubmit={(e, id, documento)=>guardarAnuncio(e, id, documento, 'laborSocial')}
                        eliminar={(id, documento)=>eliminarAd(id, documento, 'laborSocial')}
                    />
                })}
                {agregarLaborSocial && <FormualrioCarrusel 
                        anuncio={{}}
                        nuevo={true}
                        onSubmit={(e, id, documento) => crearNuevoAnuncio(e, id, documento, 'laborSocial')}
                        eliminar={()=>{}}
                    />}
                <button className="btn-arketipos fondo-azul" onClick={()=>setAgregarLaborSocial(!agregarLaborSocial)}>
                    {!agregarLaborSocial ? 'Agregar nuevo anuncio' : 'Cancelar'}
                </button>
            </div>}

            {/* <br/><br/>
            <hr className="separador-landing"/>
            <br/><br/> */}


            {seccionActual === 4 && <div>
                <h3>Consultoría</h3>
                {consultoria.map((integrante, i) => {
                    return <FormularioServicio 
                        key={`anuncio-${i}`}
                        integrante={integrante}
                        nuevo={false}
                        onSubmit={guardarIntegrante}
                        eliminar={eliminarMember}
                    />
                })}
                {agregarConsultoria && <FormularioServicio 
                        integrante={{}}
                        nuevo={true}
                        onSubmit={crearNuevoIntegrante}
                        eliminar={()=>{}}
                    />}
                <button className="btn-arketipos fondo-azul" onClick={()=>setAgregarConsultoria(!agregarConsultoria)}>
                    {!agregarConsultoria ? 'Agregar nuevo integrante' : 'Cancelar'}
                </button>
            </div>}

        </div>
    )
}

const mapStateToProps = (state) => ({
    servicios: getServiciosState(state),
    cargando: getLoadingServicios(state),
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
    traerHome: getServicios,
    guardarHome: saveHomeDocument,
    actualizarAnuncio: updateAd,
    crearAnuncio: createAd,
    eliminarAnuncio: deleteAd,
    actualizarIntegrante: updateService,
    crearIntegrante: createService,
    eliminarIntegrante: deleteService,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(FormularioServicios)
