//Constantes para diferenciar las repuestas de Firebase
export const OK = 'OK'
export const OK_ACTUALIZACION = 'OK_ACTUALIZACION'
export const ERROR = 'ERROR'
export const CANCELAR = 'CANCELAR'
export const ERROR_USUARIO_DESCONOCIDO = 'auth/user-not-found'
export const ERROR_PASSWORD_INCORRECTO = 'auth/wrong-password'
export const ERROR_CORREO_INVALIDO = 'auth/invalid-email'

//Constantes para diferenciar los types en los reducers
export const SESION = 'SESION'
export const UID_USER = 'UID_USER'
export const CLEAN_USER = 'CLEAN_USER'
export const CARGANDO_USER = 'CARGANDO_USER'
export const SIN_SESION = 'SIN_SESION'
export const CAMBIAR = 'CAMBIAR'
export const CARGANDO = 'CARGANDO'
export const TRAER_CHATS = 'TRAER_CHATS'
export const CARGANDO_CHATS = 'CARGANDO_CHATS'
export const ERROR_CHATS = 'ERROR_CHATS'

export const CLEAN_ADMIN = 'CLEAN_ADMIN'
export const CLEAN_TERAPEUTA = 'CLEAN_TERAPEUTA'
export const CLEAN_PACIENTE = 'CLEAN_PACIENTE'


// Constantes para identificar el rol del usuarios
export const ROL_ADMIN = 0;
export const ROL_PACIENTE = 1;
export const ROL_TERAPEUTA = 2;

// Constantes para diferenciar el estado del paciente con respecto a un psicologo
export const PACIENTE_NUEVO = 0;

// Constante que representa que el usuario no tiene foto de perfil
export const SIN_FOTO = "SIN_FOTO";

// Constantes para los mensajes de error
export const ERROR_FORMATO_IMAGEN = "Solo se permiten imagenes en formato jpg, jpeg, png, svg o webp."

// Constantes para distinguir los diferentes tipos de correo
export const TERAPEUTA_ASIGNADO = 'TERAPEUTA_ASIGNADO'
export const TERAPEUTA_REGISTRADO = 'TERAPEUTA_REGISTRADO'
export const PACIENTE_REGISTRADO = 'PACIENTE_REGISTRADO'
export const USUARIO_CONGELADO = 'USUARIO_CONGELADO'
export const PACIENTE_APROBADO_RECHAZADO = 'PACIENTE_APROBADO_RECHAZADO'
export const TERAPIA_ASIGNADA = 'TERAPIA_ASIGNADA'
export const TAREA_POR_REVISAR = 'TAREA_POR_REVISAR'
export const CITA_PROGRAMADA = 'CITA_PROGRAMADA'
export const TAREA_ASIGNADA = 'TAREA_ASIGNADA'

// Constantes para tipos en la escritura por lotes
export const UPDATE = 'UPDATE'
export const DELETE = 'DELETE'
export const SET = 'SET'
