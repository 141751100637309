import React from 'react'
import { Link, withRouter } from 'react-router-dom'
// import logo_azul from '../../assets/logo_azul.svg'
// import logo_amarillo from '../../assets/logo_amarillo.svg'
// import logo_verde from '../../assets/logo_verde.svg'
import mail from '../../assets/ic_email.svg'
import instagram from '../../assets/ic_instagram.svg'
import facebook from '../../assets/ic_facebook.svg'
import LogoColor from './LogoColor'

const Header = ({location}) => {

    const ruta = location.pathname
    // let logo = logo_azul
    let color = 'azul'

    if(ruta === "/servicios") {
        color = 'verde'
        // logo = logo_verde
    }
    if(ruta === "/nosotros"){ 
        color = 'amarillo'
        // logo = logo_amarillo
    }

    return (
        <footer>
            <Link to="/" className="imagen-header">
                <LogoColor color={color}/>
                {/* <img src={logo} alt="logo"/> */}
            </Link>
            <div className="flex flex-column">
                <div className="flex flex-column letra-gris letra-nombres">
                    Recursos
                    <hr className={color}/>
                </div>
                <Link to="/contacto" className="circulo center jc-start">Ayuda</Link>
                <Link to="#" className="circulo center jc-start">Términos y Condiciones</Link>
                <Link to="/contacto" className="circulo center jc-start">Contacto</Link>
            </div>
            <div className="flex flex-column">
                <div className="flex flex-column letra-gris letra-nombres">
                    Síguenos
                    <hr className={`hr-${color}`}/>
                </div>
                <a href="https://www.instagram.com/" target="noopener noreferrer" className="circulo center jc-start">
                    <img src={instagram} alt="red social"/>
                    <span>Instagram</span>
                </a>
                <a href="https://www.facebook.com/" target="noopener noreferrer" className="circulo center jc-start">
                    <img src={facebook} alt="red social"/>
                    <span>Facebook</span>
                </a>
                <a href="mailto:consultoria@psicoarketipos.com" target="noopener noreferrer" className="circulo center jc-start">
                    <img src={mail} alt="red social"/>
                    <span>consultoria@psicoarketipos.com</span>
                </a>
            </div>
        </footer>
    )
}

export default withRouter(Header)