import React, {Component} from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import DialogoUnBoton from '../general/DialogoUnBoton'
import Loading from '../general/Loading'
import { ERROR, OK } from '../../utilidades/Constantes'
import {registrarPaciente} from '../../actions/sesionActions'


class Registro extends Component{

    state={
        nombre:'',
        correo:'',
        telefono:'',
        password:'',
        passwordConfirm:'',
        descripcion:'',
        cargando: false,
        dialogo: 0,
        registroFinalizado: false
    }

    handleChange=(e)=>{
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    handleSubmit= async(e)=>{
        e.preventDefault()

        if(this.state.password !== this.state.passwordConfirm){
            this.setState({
                dialogo: 3
            })
            return
        }

        this.setState({
            cargando: true
        })
        let r = await registrarPaciente(this.state)
        this.setState({
            cargando: false,
        })
        if(r === OK){
            this.setState({
                dialogo: 1
            })
        }
        if(r === ERROR){
            this.setState({
                dialogo: 2
            })
        }
    }

    cerrarDialogo = ()=>{
        this.setState({dialogo:0},)
    }

    abrirDialogo = (tipo) =>{
        let titulo
        let descripcion
        let funcion
        
        if(tipo === 1){
            titulo="¡Tu registro fue exitoso!"
            descripcion="Ya estas registrado, revisaremos tu información y te notificaremos cuando estes aprobado."
            funcion = ()=>this.props.history.push('/')
        }

        if(tipo === 2){
            titulo="Algo falló"
            descripcion="Parece que algo está fallando con tu registro, inténtalo más tarde."
            funcion = this.cerrarDialogo
        }

        if(tipo === 3){
            titulo="Las contraseñas no coinciden"
            descripcion="Las contraseñas que ingresaste no son iguales, inténtalo nuevamente."
            funcion = this.cerrarDialogo
        }
        return this.mostrarDialogo(titulo, descripcion, funcion)
    }

    mostrarDialogo = (titulo, descripcion, funcion) =>{
        return <DialogoUnBoton
            titulo={titulo}
            descripcion={descripcion}
            boton1="Entendido"
            cerrar={funcion}
            click1={funcion}
        />
    }

    render(){
        if(this.state.registroFinalizado)
            return <Redirect to="/"/>

        if(this.state.dialogo === 1) return this.abrirDialogo(this.state.dialogo)

        return(
            <div className="contenedor-dialogo-login">
                <div className="parte-atras-dialogo" onClick={()=>this.props.history.push('/')}/>
                <div className="contenedor-login">
                    <form onSubmit={this.handleSubmit}>
                        <div><span>Regístrate</span><hr/></div>
                        <input type="text" name="nombre" placeholder="Tu nombre" onChange={this.handleChange} value={this.state.nombre} required/>
                        <input type="email" name="correo" placeholder="Tu correo" onChange={this.handleChange} value={this.state.correo} required/>
                        <input type="tel" name="telefono" placeholder="Tu celular" onChange={this.handleChange} value={this.state.telefono} required/>
                        <input type="password" name="password" placeholder="Crea una contraseña" onChange={this.handleChange} value={this.state.password} required/>
                        <input type="password" name="passwordConfirm" placeholder="Confirma tu contraseña" onChange={this.handleChange} value={this.state.passwordConfirm} required/>
                        <textarea name="descripcion" placeholder="Cuéntanos un poco acerca de ti..." onChange={this.handleChange} value={this.state.descripcion} required/>
                        <div className="botones-registro">
                            <button type="submit" className="btn-arketipos boton-login btn-registro-usuario">Crear cuenta</button>
                            <button type="button" onClick={()=>this.props.history.push('/')} className="btn-arketipos boton-login btn-registro-blanco">Cancelar</button>
                        </div>
                    </form>
                </div>
                {this.state.dialogo===0?'':this.abrirDialogo(this.state.dialogo)}
                {this.state.cargando?<Loading />:''}
            </div>
        )
    }
}

export default withRouter(Registro)