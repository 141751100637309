import React from 'react';
import { withRouter } from 'react-router-dom';
import SkeletonResponsive from '../../../general/SkeletonResponsive';
import PerfilImg from '../../../../assets/ic_foto_perfil_azul.svg';
import { SIN_FOTO } from '../../../../utilidades/Constantes';

const ResumenAdministrador = ({ pacientes_sin_asignar, pacientesSinAprobar, nombre, asignarTerapeutaHandleClick, setPacientePorAprobar ,history }) => {

    let datosCargados = nombre && pacientes_sin_asignar && pacientesSinAprobar
    return (
        <div className='resumen-panel'>
            {
                datosCargados ?
                <div className='texto-bienvenido'>{`¡Hola ${nombre}!`}</div>:
                <SkeletonResponsive tipo='rect' className='sk-texto-bienvenido'/>
            }
            <div className='contenedor-paneles'>
                <div className='resumen-con-pacientes-admin'>
                        {
                            datosCargados?
                                <div className='pacientes-sin-asignar-admin'>
                                    <div className='titulo-pacientes-sin-asignar'>
                                        Pacientes sin asignar terapeuta
                                    </div>
                                    {
                                        pacientes_sin_asignar.length === 0 ?
                                            <button id='btn-sin-pacientes' className='btn-arketipos btn-azul'>No tienes pacientes nuevos</button>:
                                            pacientes_sin_asignar.map((paciente, index) => (
                                                <div key={paciente.nombre} className='item-paciente-por-asignar' style={{borderBottom: pacientes_sin_asignar.length-1 === index?'none': '1px solid #C6C6C6'}}>
                                                    <div className='img-nombre-paciente'>
                                                        <img
                                                            className='paciente-profile-img'
                                                            src={
                                                                // TODO: Quitar verificacion de null
                                                                paciente.urlFotoPerfil ?
                                                                    paciente.urlFotoPerfil === SIN_FOTO ? PerfilImg : paciente.urlFotoPerfil:
                                                                    PerfilImg
                                                            }
                                                            alt="Imagen de perfil" />
                                                        <div className='nombre-item'>{paciente.nombre}</div>
                                                    </div>
                                                    <div className='btns-paciente-por-asignar'>
                                                        <button className='btn-arketipos btn-borde-negro' onClick={
                                                            () => history.push(`/administrador/pacientes/${paciente.uidDoc}?origen=Resumen`)
                                                        }>Ver perfil</button>
                                                        <button id='btn-asignar-resumen'className='btn-arketipos btn-azul' onClick={() => asignarTerapeutaHandleClick(paciente)}>Asignar</button>
                                                    </div>
                                                </div>
                                            ))
                                    }
                                </div>:
                                <div className='pacientes-sin-asignar-admin'>
                                    <SkeletonResponsive tipo='rect' className='sk-titulo-pacientes-sin-asignar'/>
                                    {
                                        Array.from(Array(5).keys()).map( (value, index) => (
                                            <div key={index} className='item-paciente-por-asignar' style={{borderBottom: 9 === index?'none': '1px solid #C6C6C6'}}>
                                                <div className='img-nombre-paciente'>
                                                    <SkeletonResponsive tipo='circle' className='sk-paciente-profile-img'/>
                                                    <SkeletonResponsive tipo='rect' className='sk-nombre-item'/>
                                                </div>
                                                <div className='btns-paciente-por-asignar'>
                                                    <SkeletonResponsive tipo='rect' className='sk-btn-borde-negro'/>
                                                    <SkeletonResponsive id='btn-asignar-resumen' tipo='rect' className='sk-btn-borde-negro'/>
                                                </div>
                                            </div>
                                        )) 
                                    }
                                </div>
                        }
                </div>
                <div className='resumen-con-pacientes-admin'>
                        {
                            datosCargados?
                                <div className='pacientes-sin-asignar-admin'>
                                    <div className='titulo-pacientes-sin-asignar'>
                                        Pacientes sin aprobar
                                    </div>
                                    {
                                        pacientesSinAprobar.length === 0 ?
                                            <button id='btn-sin-pacientes' className='btn-arketipos btn-azul'>No tienes pacientes sin aprobar</button>:
                                            pacientesSinAprobar.map((paciente, index) => (
                                                <div key={paciente.nombre} className='item-paciente-por-asignar' style={{borderBottom: pacientes_sin_asignar.length-1 === index?'none': '1px solid #C6C6C6'}}>
                                                    <div className='img-nombre-paciente'>
                                                        <img 
                                                            className='paciente-profile-img'
                                                            src={PerfilImg}
                                                            alt="Imagen de perfil" />
                                                        <div className='nombre-item'>{paciente.nombre}</div>
                                                    </div>
                                                    <button id='btn-asignar-resumen'className='btn-arketipos btn-azul' onClick={() => setPacientePorAprobar(paciente)}>Más info</button>
                                                </div>
                                            ))
                                    }
                                </div>:
                                <div className='pacientes-sin-asignar-admin'>
                                    <SkeletonResponsive tipo='rect' className='sk-titulo-pacientes-sin-asignar'/>
                                    {
                                        Array.from(Array(5).keys()).map( (value, index) => (
                                            <div key={index} className='item-paciente-por-asignar' style={{borderBottom: 9 === index?'none': '1px solid #C6C6C6'}}>
                                                <div className='img-nombre-paciente'>
                                                    <SkeletonResponsive tipo='circle' className='sk-paciente-profile-img'/>
                                                    <SkeletonResponsive tipo='rect' className='sk-nombre-item'/>
                                                </div>
                                                <div className='btns-paciente-por-asignar'>
                                                    <SkeletonResponsive id='btn-asignar-resumen' tipo='rect' className='sk-btn-borde-negro'/>
                                                </div>
                                            </div>
                                        )) 
                                    }
                                </div>
                        }
                </div>  
            </div>
        </div>
    );
}

export default withRouter(ResumenAdministrador);