import React, { useEffect, useState } from 'react'
import flecha from '../../../../assets/ic_flecha.svg'
import PropTypes from 'prop-types'
import SkeletonResponsive from '../../../general/SkeletonResponsive'

const textoFinal = (textoAuste) => {
    let texto = textoAuste.split("\\n")

    return texto.map((tx, i) => {
        
        return (<span key={`texto-${i}`}>{tx}<br/></span>)
    })
}

const Carrusel = ({arreglo, izquierda, actual, cargando}) => {

    const [posicion, setPosicion] = useState(0)

    const ancho_izquierda = window.innerWidth > 800

    const cambiarPosicion = (posicion) => {
        if(cargando) return
        if(posicion > arreglo.length - 1) posicion = 0
        if(posicion < 0) posicion = arreglo.length - 1
        setPosicion(posicion)
    }

    useEffect(() => {
        const timer = setTimeout(()=>cambiarPosicion(posicion + 1), 10000)
        return () => {
            if(timer) clearTimeout(timer)
        }
    })

    useEffect(() => {
        if(actual)
            setPosicion(0)
    }, [actual])

    return (
        <div className={`segunda-division flex center ${!izquierda && 'flex-reverse'}`}>
            <div className={`flex flex-column ${izquierda ? 'ai-end' : 'ai-start'} carrusel`}>
                <div className={`imagenes-carrusel flex ${izquierda && 'flex-reverse'}`} style={{
                    transform: izquierda ? `translateX(${((ancho_izquierda ? 33 : 74) * posicion)}${ancho_izquierda ? 'em':'vw'})` : `translateX(${(-(ancho_izquierda ? 28 : 74)) * posicion}${ancho_izquierda ? 'em':'vw'})` 
                }}>
                    {cargando && <SkeletonResponsive tipo='rect' className={`sk-imagen-carrusel ${izquierda?'imagen-publicacion' : 'imagen-servicio'}`}/> }
                    {cargando && <SkeletonResponsive tipo='rect' className={`sk-imagen-carrusel ${izquierda?'imagen-publicacion' : 'imagen-servicio'}`}/> }
                    {arreglo.length > 0 && arreglo.map((publicacion, i)=>{
                        return <img 
                            className={izquierda?'imagen-publicacion' : 'imagen-servicio'}
                            key={`imagen-publicacion-${i}`}
                            src={publicacion.imagen}
                            alt="imagen"
                            style={{
                                opacity: ancho_izquierda ? (posicion === i ? 1 : posicion < i ? 1 : 0) : 1
                            }}
                        />
                    })}
                </div>
                {
                    izquierda?
                    <div className="flechas flex jc-end ai-start">
                        <button className="btn-arketipos btn-administrador" onClick={()=>cambiarPosicion(posicion + 1 )}>
                            <img src={flecha} alt="boton-carrusel"/>
                        </button>
                        <button className="btn-arketipos btn-administrador" onClick={()=>cambiarPosicion(posicion - 1)}>
                            <img className="derecha" src={flecha} alt="boton-carrusel"/>
                        </button>
                    </div>:<div className="vacio"/>
                }
            </div>
            <div className={`textos ${!izquierda && 'textos-izquieda'} flex flex-column 'jc-end'`}>
                {/* <div className="flex ai-center jc-start contenedor-textos flex-reverse" style={{ */}
                <div className={`flex jc-start contenedor-textos ${!izquierda && 'flex-reverse'}`} style={{
                    // transform: `translateX(${(100 * posicion) + 2}%)`,
                    transform: izquierda?`translateX(${(-( ancho_izquierda ? 34 : 90 ) * posicion) - (ancho_izquierda ? 4 : 0)}vw)`:`translateX(${(( ancho_izquierda ? 34 : 90 ) * posicion) + (ancho_izquierda ? 4 : 0)}vw)`,
                }}>
                    {cargando && <div className={`contenedor-texto-publicacion ${!izquierda && 'contenedor-texto-publicacion-izq'} flex ai-start flex-column`}>
                            <SkeletonResponsive tipo='text' className="sk-letra-titulos"/>
                            <SkeletonResponsive tipo='text' className="sk-parrafo"/>
                    </div> }
                    {arreglo.map((publicacion, i)=>{
                        return <div className={`contenedor-texto-publicacion ${!izquierda && 'contenedor-texto-publicacion-izq'} flex ai-start flex-column`} key={`textos-${i}`} style={{
                            opacity: posicion === i ? 1 : 0,
                        }}>
                            <div className={`letra-titulos ${izquierda ? 'letra-azul' : 'letra-gris'} bold`}>
                                {publicacion.titulo}
                            </div>
                            <p className="letra-secciones bold-300">
                                {textoFinal(publicacion.texto)}
                            </p>
                        </div>
                    })}
                </div>
                {
                    !izquierda?
                    <div className="flechas flechas-pequenas flex jc-start ai-start">
                        <button className="btn-arketipos btn-paciente pequenos" onClick={()=>cambiarPosicion(posicion - 1)}>
                            <img src={flecha} alt="boton-carrusel"/>
                        </button>
                        <button className="btn-arketipos btn-paciente pequenos" onClick={()=>cambiarPosicion(posicion + 1)}>
                            <img className="derecha" src={flecha} alt="boton-carrusel"/>
                        </button>
                    </div>:<div className="vacio"/>
                }
            </div>
        </div>
    )
}

Carrusel.propTypes = {
    arreglo: PropTypes.array.isRequired,
    izquierda: PropTypes.bool.isRequired,
    actual: PropTypes.number,
    cargando: PropTypes.bool.isRequired,
}


export default Carrusel
