import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPsicologoActual } from '../../../../reducers/adminReducer';
import PerfilImg from './../../../../assets/ic_foto_perfil_azul.svg';
import SkeletonResponsive from '../../../general/SkeletonResponsive';
import { SIN_FOTO } from '../../../../utilidades/Constantes';
// import PropTypes from 'prop-types';

const PerfilTerapeutaAdmin = ({ psicologo, setUsuarioPorEliminar, history, match }) => {

    const origen = new URLSearchParams(history.location.search).get('origen') ?? 'Terapeutas'

    const checkOrigin = () => {
        switch (origen) {
            case "Terapeutas":
                history.push('/administrador/terapeutas')
                break
            case "Paciente":
                history.goBack()
                break
            default:
                history.push('/administrador/terapeutas')
                break
        }
    }

    return (
        <div className="info-usuario-admin">
            <div className="contenedor-perfil-usuario-admin">
                <div className='path-usuario-admin' onClick={checkOrigin}>
                    <div className='flecha-decoracion'>
                        <span>&larr;</span>
                    </div>
                    <b>{origen}</b>
                </div>
                <div className='perfil-usuario'>
                    {
                        psicologo ?
                            <div className='img-nombre-usuario-admin'>
                                <img 
                                    className='img-usuario-admin'
                                    src={
                                        // TODO: Quitar verificacion de null
                                        psicologo.urlFotoPerfil ?
                                            psicologo.urlFotoPerfil === SIN_FOTO ? PerfilImg : psicologo.urlFotoPerfil:
                                            PerfilImg
                                    }
                                    alt="Imagen de perfil"/>
                                <div className="titulo-perfil-usuario-admin">
                                    {psicologo.nombre}
                                    <hr/>
                                </div>
                            </div>:
                            <div className='img-nombre-usuario-admin'>
                                <SkeletonResponsive tipo='circle' className='sk-img-usuario-admin'/>
                                <SkeletonResponsive tipo='rect' className='sk-titulo-perfil-usuario-admin'/>
                            </div>
                    }
                    <div className="fila-dato">
                        <p className="campo">Correo:</p>
                        {
                            psicologo ? 
                                <p className="valor">{psicologo.correo}</p>:
                                <SkeletonResponsive tipo='rect' className='sk-valor'/>
                        }
                    </div>
                    {/* <div className="fila-dato">
                        <p className="campo">Tipo:</p>
                        {
                            psicologo ? 
                                <p className="valor">Terapeuta</p>:
                                <SkeletonResponsive tipo='rect' className='sk-valor'/>
                        }
                    </div> */}
                    {/* <div className="fila-dato">
                            <p className="campo">Pacientes:</p>
                            {
                                psicologo ? 
                                    !psicologo.pacientes || (psicologo.pacientes&&psicologo.pacientes.length === 0) ?
                                    <p className="valor">No tiene pacientes asignados</p>:
                                    <div className="dropdown-perfil-admin">
                                        <div className="custom-select-perfil-admin" onClick={dropdownHandleClick}>
                                            <div>
                                                {"Pacientes"}
                                            </div>
                                            <div className='flecha-decoracion-select'>
                                                <span>&darr;</span>
                                            </div>
                                        </div>
                                        {
                                            dropdownVisible &&
                                            <div className='lista-usuarios-perfil-admin'
                                                style={{ height: `${Math.min(120, psicologo.pacientes.length * 30)}px` }}>
                                                <ul className='ul-usuarios-perfil-admin'>
                                                    {
                                                        psicologo.pacientes.map(paciente => (
                                                            <li onClick={() => history.push(`/administrador/pacientes/${paciente.uidDoc}?origen=Terapeuta`)}>
                                                                {paciente.nombre}
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        }
                                    </div>:
                                    <SkeletonResponsive tipo='rect' className='sk-valor'/>
                            }
                    </div> */}
                    <div className="fila-dato">
                        <p className="campo">Celular:</p>
                        {
                            psicologo ? 
                                <p className="valor">{psicologo.telefono || '-'}</p>:
                                <SkeletonResponsive tipo='rect' className='sk-valor'/>
                        }
                    </div>
                    <div className="fila-dato">
                        <p className="campo">Descripción:</p>
                        {
                            psicologo ? 
                                <p className="valor">{psicologo.descripcion || '-'}</p>:
                                <SkeletonResponsive tipo='rect' className='sk-descripcion'/>
                        }
                    </div>
                    <div className='contenedor-botones-perfil'>
                        {
                            psicologo  ? 
                            psicologo.pacientes.length > 0 && <button 
                                    className="btn-azul btn-arketipos"
                                    onClick={() => history.push(`/administrador/terapeutas/${match.params.id}/pacientes`)}>Pacientes</button>:
                                <SkeletonResponsive tipo='rect' className='sk-btn-borde-negro sk-btn-azul'/>
                        }
                        {
                            psicologo ? 
                                psicologo.congelado && <button className="btn-borde-negro btn-arketipos" onClick={() => setUsuarioPorEliminar(psicologo, false)}>Eliminar Terapeuta</button>:
                                <SkeletonResponsive tipo='rect' className='sk-btn-borde-negro'/>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state, props) => ({
    psicologo: getPsicologoActual(state, props.match.params.id)
});

export default withRouter(connect(mapStateToProps, null)(PerfilTerapeutaAdmin))