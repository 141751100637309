import {
    GET_DB_PACIENTES,
    GET_DB_PACIENTES_SIN_APROBAR,
    GET_DB_PSICOLOGOS,
    GET_PACIENTE_ESPECIFICO_ADMIN,
    GET_PSICOLOGO_ESPECIFICO_ADMIN,
    GET_DB_TERAPIAS_PACIENTE,
    GET_DB_TAREAS_TERAPIAS_PACIENTE,
    GET_DB_ACTIVIDADES_PACIENTE_ADM
} from './../actions/adminActions';
import { createSelector } from 'reselect';
import { CLEAN_ADMIN } from '../utilidades/Constantes';

const INITIAL_STATE = {
    pacientes: null,
    psicologos: null,
    pacientesSinAprobar: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_DB_PACIENTES: {
            const pacientes = action.payload;
            return { ...state, pacientes };
        }
        case GET_DB_PSICOLOGOS: {
            const psicologos = action.payload;
            return { ...state, psicologos };
        }
        case GET_DB_PACIENTES_SIN_APROBAR: {
            const pacientesSinAprobar = action.payload;
            return { ...state, pacientesSinAprobar };
        }
        case GET_PACIENTE_ESPECIFICO_ADMIN: {
            const paciente_actual = action.payload;
            return { ...state, paciente_actual };
        }
        case GET_PSICOLOGO_ESPECIFICO_ADMIN: {
            const psicologo_actual = action.payload;
            return { ...state, psicologo_actual };
        }
        case GET_DB_TERAPIAS_PACIENTE: {
            const idPaciente = action.options.paciente;
            const idPsicologo = action.options.psicologo;
            const terapias = action.payload;
            const psicologosFinal = []
            if (state.psicologos) {
                state.psicologos.forEach(psicologo => {
                    if(psicologo.uidDoc === idPsicologo){
                        if(psicologo.pacientes) {
                            let pacientesFinal = []
                            psicologo.pacientes.forEach(paciente => {
                                if (idPaciente === paciente.uidDoc) {
                                    let pacienteBuscado = { ...paciente, terapias: terapias }
                                    pacientesFinal.push(pacienteBuscado);
                                }
                                else
                                    pacientesFinal.push(paciente);
                            })
                            let psicologoPacientes = {...psicologo, pacientes: pacientesFinal} 
                            psicologosFinal.push(psicologoPacientes)
                        }
                    } else 
                        psicologosFinal.push(psicologo)
                })
            }
            return { ...state, psicologos: psicologosFinal };
        }
        case GET_DB_TAREAS_TERAPIAS_PACIENTE: {
            const idPaciente = action.options.paciente;
            const idPsicologo = action.options.psicologo;
            const idTerapia = action.options.terapia;
            const tareas = action.payload;
            let psicologosFinal = []
            if (state.psicologos) {
                state.psicologos.forEach(psicologo => {
                    if(psicologo.uidDoc === idPsicologo){
                        if(psicologo.pacientes) {
                            let pacientesFinal = []
                            psicologo.pacientes.forEach(paciente => {
                                if (idPaciente === paciente.uidDoc) {
                                    if(paciente.terapias){
                                        let terapiasFinal = []
                                        paciente.terapias.forEach(terapia => {
                                            if(idTerapia === terapia.uidDoc){
                                                let terapiaBuscada = { ...terapia, tareas: tareas}
                                                terapiasFinal.push(terapiaBuscada)           
                                            } else {
                                                terapiasFinal.push(terapia)
                                            }
                                        })
                                        let pacienteTerapias = {...paciente, terapias: terapiasFinal} 
                                        pacientesFinal.push(pacienteTerapias)
                                    }
                                }
                                else
                                    pacientesFinal.push(paciente);
                            })
                            let psicologoPacientes = {...psicologo, pacientes: pacientesFinal} 
                            psicologosFinal.push(psicologoPacientes)
                        }
                    } else 
                        psicologosFinal.push(psicologo)
                })
            }
            return { ...state, psicologos: psicologosFinal };
        }
        case GET_DB_ACTIVIDADES_PACIENTE_ADM: {
            const idPaciente = action.options.paciente;
            const idPsicologo = action.options.psicologo;
            const idTerapia = action.options.terapia;
            const idTarea = action.options.tarea;
            const actividades = action.payload;
            console.log(idPaciente, idPsicologo)
            console.log(idTerapia, idTarea)
            console.log(actividades)
            let psicologosFinal = []
            if (state.psicologos) {
                console.log(0, state.psicologos)
                state.psicologos.forEach(psicologo => {
                    if(psicologo.uidDoc === idPsicologo){
                        if(psicologo.pacientes) {
                            console.log(1, psicologo.pacientes)
                            let pacientesFinal = []
                            psicologo.pacientes.forEach(paciente => {
                                if (idPaciente === paciente.uidDoc) {
                                    if(paciente.terapias){
                                        console.log(2, paciente)
                                        let terapiasFinal = []
                                        paciente.terapias.forEach(terapia => {
                                            if(idTerapia === terapia.uidDoc){
                                                if(terapia.tareas){
                                                    console.log(3, terapia)
                                                    let tareasFinal = []
                                                    terapia.tareas.forEach( tareaElemento =>{
                                                        if(idTarea === tareaElemento.uidDoc){
                                                            console.log(4, tareaElemento)
                                                            let tareaBuscada = { ...tareaElemento, actividades: actividades}
                                                            console.log(5, tareaBuscada)
                                                            tareasFinal.push(tareaBuscada)
                                                        } else {
                                                            tareasFinal.push(tareaElemento)
                                                        }
                                                        
                                                    })
                                                    let pacienteTareas = {...terapia, tareas: tareasFinal} 
                                                    terapiasFinal.push(pacienteTareas)
                                                }
                                            } else {
                                                terapiasFinal.push(terapia)
                                            }
                                        })
                                        let pacienteTerapias = {...paciente, terapias: terapiasFinal} 
                                        pacientesFinal.push(pacienteTerapias)
                                    }
                                }
                                else
                                    pacientesFinal.push(paciente);
                            })
                            let psicologoPacientes = {...psicologo, pacientes: pacientesFinal} 
                            psicologosFinal.push(psicologoPacientes)
                        }
                    } else 
                        psicologosFinal.push(psicologo)
                })
            }
            return { ...state, psicologos: psicologosFinal };
        }
        case CLEAN_ADMIN: {
            return {
                pacientes: null,
                psicologos: null,
                pacientesSinAprobar: null
            };
        }
        default:
            return state;
    }
}

export const getPacientes = createSelector(state => state.adminReducer, adminReducer => adminReducer.pacientes)

export const getPacientesSinAprobar = createSelector(state => state.adminReducer, adminReducer => adminReducer.pacientesSinAprobar)

export const getPsicologos = createSelector(state => state.adminReducer, adminReducer => adminReducer.psicologos)

export const getPacientesSinAsignar = createSelector(state => state.adminReducer, adminReducer => {

    if (!adminReducer.pacientes)
        return null
    else
        return adminReducer.pacientes.filter(paciente => paciente.psicologos.length === 0)
})

export const getPacienteActual = createSelector((state, id) => ({ adminReducer: state.adminReducer, id }), ({ adminReducer, id }) => {
    if (!adminReducer.pacientes || !adminReducer.psicologos)
        return null

    let pacienteActual = adminReducer.pacientes.filter(paciente => paciente.uidDoc === id)[0]

    return pacienteActual;
});

export const getPsicologoActual = createSelector((state, id) => ({ adminReducer: state.adminReducer, id }), ({ adminReducer, id }) => {
    if (!adminReducer.psicologos || !adminReducer.pacientes)
        return null

    let psicologoActual = adminReducer.psicologos.filter(psicologo => psicologo.uidDoc === id)[0];

    return psicologoActual;
});

export const getTerapiasPaciente = createSelector((state, idPaciente, idPsicologo) => ({ adminReducer: state.adminReducer, idPaciente, idPsicologo }), ({ adminReducer, idPaciente, idPsicologo }) => {
    if (adminReducer.psicologos) {
        let psicologo = adminReducer.psicologos.find(psicologo => psicologo.uidDoc === idPsicologo) 
        if(psicologo && psicologo.pacientes){
            let paciente = psicologo.pacientes.find(paciente => paciente.uidDoc === idPaciente)
            return paciente ? paciente.terapias : null;
        }
        else return null;
    }
    else return null;
})

export const getPacienteTerapias = createSelector((state, idPaciente) => ({ adminReducer: state.adminReducer, idPaciente }), ({ adminReducer, idPaciente }) => {
    if (adminReducer.pacientes) {
        const paciente = adminReducer.pacientes.find(paciente => paciente.uidDoc === idPaciente)
        return paciente ? paciente : null;
    }
    else return null;
})

export const getTerapiaPaciente = createSelector((state, idPaciente, idPsicologo, idTerapia) => ({ adminReducer: state.adminReducer, idPaciente, idPsicologo, idTerapia }), ({ adminReducer, idPaciente, idPsicologo, idTerapia }) => {
    if (adminReducer.psicologos) {
        let psicologo = adminReducer.psicologos.find(psicologo => psicologo.uidDoc === idPsicologo) 
        if(psicologo && psicologo.pacientes){
            let paciente = psicologo.pacientes.find(paciente => paciente.uidDoc === idPaciente)
            if(paciente && paciente.terapias){
                return paciente.terapias.find(terapia => terapia.uidDoc === idTerapia)
            }
            else return null;
        }
        else return null;
    }
    else return null;
})


export const getTareasTerapia = createSelector((state, idPaciente, idPsicologo, idTerapia) => ({ adminReducer: state.adminReducer, idPaciente, idPsicologo, idTerapia }), ({ adminReducer, idPaciente, idPsicologo, idTerapia }) => {
    if (adminReducer.psicologos) {
        let psicologo = adminReducer.psicologos.find(psicologo => psicologo.uidDoc === idPsicologo) 
        if(psicologo && psicologo.pacientes){
            let paciente = psicologo.pacientes.find(paciente => paciente.uidDoc === idPaciente)
            if(paciente && paciente.terapias){
                let terapia = paciente.terapias.find(terapia => terapia.uidDoc === idTerapia)
                return terapia ? terapia.tareas : null
            }
            else return null;
        }
        else return null;
    }
    else return null;
})

export const getTareaActual = createSelector((state, idPaciente, idTerapeuta, idTerapia, idTarea) => ({ adminReducer: state.adminReducer, idPaciente, idTerapeuta, idTerapia, idTarea }), ({ adminReducer, idPaciente, idTerapeuta, idTerapia, idTarea }) => {


    if (adminReducer.psicologos) {
        let psicologo = adminReducer.psicologos.find(psicologo => psicologo.uidDoc === idTerapeuta) 
        console.log('.....................')
        console.log(0, psicologo)
        if(psicologo && psicologo.pacientes){
            let paciente = psicologo.pacientes.find(paciente => paciente.uidDoc === idPaciente)
            console.log(1, paciente)
            if(paciente && paciente.terapias){
                let terapia = paciente.terapias.find(terapia => terapia.uidDoc === idTerapia)
                console.log(2, terapia)
                if(terapia && terapia.tareas){
                    let tarea = terapia.tareas.find(tarea => tarea.uidDoc === idTarea)
                    console.log(3, tarea)
                    return tarea ? tarea : null
                }
            }
            else return null;
        }
        else return null;
    }
    else return null;
})