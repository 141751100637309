import React from 'react'
import PropTypes from 'prop-types'
import ImagenCircular from '../../general/ImagenCircular'
import SkeletonResponsive from '../../../general/SkeletonResponsive'

const textoFinal = (textoAuste) => {
    let texto = textoAuste.split("\\n")

    return texto.map((tx, i) => {
        
        return (<span key={`texto-${i}`}>{tx}<br/></span>)
    })
}


const Integrante = ({integrante, servicios, cargando}) => {

    return (
        <div className="center flex-column integrante">
            <div className={`contenedor-imagen-equipo ${servicios && 'contenedor-imagen-servicios'}`}>
                {cargando ? <SkeletonResponsive tipo="circle" className="sk-imagencircular"/> :
                <ImagenCircular imagen={integrante.foto} completa={!servicios} className="fondo-blanco"/>}
            </div>
            {!cargando && <div className={`${servicios?'letra-gris':'letra-blanca'} bold letra-nombres text-align-center nombre-integrante`}>{textoFinal(integrante.nombre)}</div>}
            {cargando ? <SkeletonResponsive tipo='text' className="sk-parrafo"/> :
            <div className={`text-align-center bold-light ${servicios?'letra-gris-claro':'letra-blanca'} letra-menus`}>
                {integrante.cargo && `(${integrante.cargo})`}
                {integrante.cargo && <br/>}
                {integrante.email && `email: ${integrante.email}`}
                {integrante.descripcion && textoFinal(integrante.descripcion)}
            </div>}
            {/* <div className={`${servicios?'letra-gris':'letra-blanca'} bold letra-nombres text-align-center`}>{textoFinal(integrante.nombre)}</div> */}
            
        </div>
    )
}

Integrante.propTypes = {
    integrante: PropTypes.object.isRequired,
    servicios: PropTypes.bool,
    cargando: PropTypes.bool.isRequired,
}

export default Integrante
