import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { getPacienteActual } from '../../../../reducers/adminReducer';
import PerfilImg from './../../../../assets/ic_foto_perfil_azul.svg';
import SkeletonResponsive from '../../../general/SkeletonResponsive';
import { SIN_FOTO } from '../../../../utilidades/Constantes';

// import PropTypes from 'prop-types';

const PerfilPacienteAdmin = ({ paciente, setUsuarioPorEliminar }) => {

    let history = useHistory()

    let [dropdownVisible, setDropdownVisible] = useState(false)
    const [terapeutaSeleccionado, setTerapeutaSeleccionado] = useState(null)

    const origen = new URLSearchParams(history.location.search).get('origen') ?? 'Pacientes'

    const checkOrigin = () => {
        switch (origen) {
            case "Resumen":
                history.push('/administrador')
                break
            case "Pacientes":
                history.push('/administrador/pacientes')
                break
            case "Terapeuta":
                history.goBack()
                break
            default:
                history.push('/administrador/pacientes')
                break
        }
    }

    const dropdownHandleClick = () => setDropdownVisible(!dropdownVisible)

    return (
        <div className="info-usuario-admin">
            <div className="contenedor-perfil-usuario-admin">
                <div className='path-usuario-admin' onClick={checkOrigin}>
                    <div className='flecha-decoracion'>
                        <span>&larr;</span>
                    </div>
                    <b>{origen}</b>
                </div>
                <div className='perfil-usuario'>
                    {
                        paciente ?
                            <div className='img-nombre-usuario-admin'>
                                <img 
                                    className='img-usuario-admin'
                                    src={
                                        // TODO: Quitar verificacion de null
                                        paciente.urlFotoPerfil ?
                                            paciente.urlFotoPerfil === SIN_FOTO ? PerfilImg : paciente.urlFotoPerfil:
                                            PerfilImg
                                    }
                                    alt="Imagen de perfil"/>
                                <div className="titulo-perfil-usuario-admin">
                                    {paciente.nombre}
                                    <hr/>
                                </div>
                            </div>:
                            <div className='img-nombre-usuario-admin'>
                                <SkeletonResponsive tipo='circle' className='sk-img-usuario-admin'/>
                                <SkeletonResponsive tipo='rect' className='sk-titulo-perfil-usuario-admin'/>
                            </div>
                    }
                    <div className="fila-dato">
                        <p className="campo">Correo:</p>
                        {
                            paciente ? 
                                <p className="valor">{paciente.correo}</p>:
                                <SkeletonResponsive tipo='rect' className='sk-valor'/>
                        }
                    </div>
                    {/* <div className="fila-dato">
                        <p className="campo">Tipo:</p>
                        {
                            paciente ? 
                                <p className="valor">Paciente</p>:
                                <SkeletonResponsive tipo='rect' className='sk-valor'/>
                        }
                    </div> */}
                    <div className="fila-dato">
                        <p className="campo">Celular:</p>
                        {
                            paciente ? 
                                <p className="valor">{paciente.telefono}</p>:
                                <SkeletonResponsive tipo='rect' className='sk-valor'/>
                        }
                    </div>
                    <div className="fila-dato">
                        <p className="campo">Descripción:</p>
                        {
                            paciente ? 
                                <p className="valor">{paciente.descripcion}</p>:
                                <SkeletonResponsive tipo='rect' className='sk-descripcion'/>
                        }
                    </div>
                    <div className="fila-dato">
                        <p className="campo">Terapeuta:</p>
                        {
                            paciente ? 
                                !paciente.psicologos || (paciente.psicologos&&paciente.psicologos.length === 0) ?
                                <p className="valor">No tiene un terapeuta asignado</p>:
                                <div className="dropdown-perfil-admin">
                                    <div className="custom-select-perfil-admin" onClick={dropdownHandleClick}>
                                        <div className='nombre-terapeuta'>
                                            {terapeutaSeleccionado?.nombre || "Terapeutas"}
                                        </div>
                                        <div className='flecha-decoracion-select'>
                                            <span>&darr;</span>
                                        </div>
                                    </div>
                                    {
                                        dropdownVisible &&
                                        <div className='lista-usuarios-perfil-admin'
                                            style={{ height: `${Math.min(120, paciente.psicologos.length * 1.3)}em` }}>
                                            <ul className='ul-usuarios-perfil-admin'>
                                                {
                                                    paciente.psicologos.map(psicologo => (
                                                        <li 
                                                            key={psicologo.nombre}
                                                            onClick={() => {
                                                            setDropdownVisible(false)
                                                            setTerapeutaSeleccionado(psicologo)
                                                        }}>
                                                            {psicologo.nombre}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    }
                                </div>:
                                <SkeletonResponsive tipo='rect' className='sk-valor'/>
                        }
                    </div>
                    <div className='contenedor-botones-perfil'>
                        {
                            paciente ? 
                                terapeutaSeleccionado && <button onClick={() => history.push(`/administrador/pacientes/${paciente.uidDoc}/terapeutas/${terapeutaSeleccionado.uidDoc}`)} className="btn-azul btn-arketipos">Terapias</button>:
                                <SkeletonResponsive tipo='rect' className='sk-btn-borde-negro sk-btn-azul'/>
                        }
                        {
                            paciente ? 
                                paciente.congelado && <button className="btn-arketipos btn-borde-negro" onClick={() => setUsuarioPorEliminar(paciente, true)}>Eliminar Paciente</button>:
                                <SkeletonResponsive tipo='rect' className='sk-btn-borde-negro'/>
                        }
                    </div>
                </div>
            </div> 
        </div>
    );
}

const mapStateToProps = (state, props) => ({
    paciente: getPacienteActual(state, props.match.params.id),
});

export default withRouter(connect(mapStateToProps, null)(PerfilPacienteAdmin))