import React, {Component} from 'react'
import { Link, Redirect, withRouter } from 'react-router-dom'
import * as actions from '../../actions/sesionActions'
import {connect} from 'react-redux'
import Loading from '../general/loadingIndicator/LoadingIndicator'
import DialogoUnBoton from '../general/DialogoUnBoton'
import { ERROR, ERROR_PASSWORD_INCORRECTO, ERROR_USUARIO_DESCONOCIDO, CAMBIAR, OK, ERROR_CORREO_INVALIDO } from '../../utilidades/Constantes'
import DialogoRecuperar from './components/DialogoRecuperar'

class Login extends Component{

    state={
        user:'',
        password:'',
        cargando: false,
        recuperar: true,
        correo: ''
    }
    
    handleChange=(e)=>{
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    handleSubmit=(e)=>{
        e.preventDefault()
        this.setState({
            cargando:true
        })
        this.props.ingresar(this.state.user, this.state.password)
    }

    cerrarDialogo = ()=>{
        this.props.limpiarUsuario()
        this.setState({
            cargando:false,
        })
    }

    abrirDialogo = (tipo) =>{
        let titulo
        let descripcion
        
        if(tipo === 1){
            titulo="Contraseña incorrecta"
            descripcion="La contraseña que ingresaste es incorrecta, inténtalo nuevamente." 
        }
        
        if(tipo === 2){
            titulo="Correo incorrecto"
            descripcion="El correo ingresaste no se encuentra registrado, inténtalo nuevamente." 
        }

        if(tipo === 3){
            titulo="Algo está fallando"
            descripcion="Hubo un error al iniciar sesión, inténtalo más tarde." 
        }

        if(tipo === 4){
            titulo="Sin aprobación"
            descripcion="Parece que no te hemos aprobado, puedes intentarlo más tarde. Estaremos revisando tu solicitud."
        }

        return this.mostrarDialogo(titulo, descripcion , this.cerrarDialogo)
    }

    mostrarDialogo = (titulo, descripcion, funcion) =>{
        return <DialogoUnBoton
            titulo={titulo}
            descripcion={descripcion}
            boton1="Entendido"
            cerrar={funcion}
            click1={funcion}
        />
    }

    visualizacionDialogo = () =>{

        if(this.props.uidUser === ERROR_PASSWORD_INCORRECTO) return this.abrirDialogo(1)
        if(this.props.uidUser === ERROR_USUARIO_DESCONOCIDO) return this.abrirDialogo(2)
        if(this.props.uidUser === ERROR) return this.abrirDialogo(3)
        if(this.props.user !== null)
            if(!this.props.user.aprobado)return this.abrirDialogo(4)
        if(this.state.cargando) return <Loading color="rgb(65,142,238)"/>
        return ''
    }

    recuperarContrasena = async (v) =>{
        //El valor 0 representa dialogo cerrado
        //El valor 1 representa dialogo abierto
        let valor = v
        if(v === CAMBIAR){
            if(this.state.correo === "" || !this.state.correo.includes('@'))
                valor = 2 //El valor 2 representa error en el correo ingresado
            else{
                this.setState({
                    recuperar:3 //El valor 3 representa que está cargando la solicitud
                })
                let respuesta = await actions.recuperarPassword(this.state.correo)
                if(respuesta === OK){
                    valor=4
                } //El valor 4 representa exito en la solicitud
                if(respuesta === ERROR) valor=5 //El valor 5 representa error en la solicitud
                if(respuesta === ERROR_CORREO_INVALIDO) valor=2 //El valor 5 representa error en la solicitud
            }
        }
        this.setState({
            recuperar: valor,
            correo: valor===4 || valor===0 || valor===5?'':this.state.correo
        })
    }

    verCambiar = (v) =>{
        if(v === 2) return this.mostrarDialogo('Correo invalido',
            'El correo que ingresaste no es valido, inténtalo nuevamente.',
            ()=>this.recuperarContrasena(1)
        )
        if(v === 3) return <Loading color="rgb(65,142,238)"/>
        if(v === 4) return this.mostrarDialogo('¡Correo enviado!',
            'Te hemos enviado un correo para que puedas recuperar tu contraseña.',
            ()=>this.recuperarContrasena(0)
        )
        if(v === 5) return this.mostrarDialogo('Hubo un error',
            'Parece que tenemos problemas para enviarte el correo, inténtalo más tarde.',
            ()=>this.recuperarContrasena(0)
        )
        
        return ''
    }

    regresarDialogo = () =>{
        return (<DialogoRecuperar 
            click={()=>this.recuperarContrasena(CAMBIAR)} 
            cerrar={()=>this.recuperarContrasena(0)}
            valor={this.state.correo}
            cambiar={this.handleChange}
        />)
    }

    render(){

        if(this.props.user !== null)
            if(this.props.user.aprobado){
                if(this.props.user.rol === 0) return <Redirect to="administrador"/>
                if(this.props.user.rol === 1) return <Redirect to="paciente"/>
                if(this.props.user.rol === 2) return <Redirect to="terapeuta"/>
                if(this.props.user.rol === 3) return <Redirect to="administrador-landing"/>
            }

        if(this.state.recuperar === 1) return this.regresarDialogo()

        return(
            <div className="contenedor-dialogo-login">
                <div className="parte-atras-dialogo" onClick={()=>this.props.history.push('/')}/>
                <div className="contenedor-login">
                    <form onSubmit={this.handleSubmit}>
                        <div><span>Inicia sesión</span><hr/></div>
                        <input type="email" name="user" placeholder="Tu correo" onChange={this.handleChange} value={this.state.user} required/>
                        <input type="password" name="password" placeholder="Tu contraseña" onChange={this.handleChange} value={this.state.password} required/>
                        <button type="submit" className="boton-login btn-arketipos">Ingresa</button>
                        <button type="button" className="boton-recuperar-contrasena" onClick={()=>this.recuperarContrasena(1)}>¿Olvidaste tu contraseña?</button>
                    </form>
                    <div className="pregunta-registro">
                        <span>¿No tienes una cuenta?</span>
                        <Link to="/registro" className="boton-login btn-arketipos">Regístrate</Link>
                    </div>
                </div>
                {this.visualizacionDialogo()}
                {this.verCambiar(this.state.recuperar)}
            </div>
        )
    }
}

const mapStateToProps = (reducers) =>{
    return reducers.sesionReducer
}

export default connect(mapStateToProps, actions)(withRouter(Login))