import {iniciarSesion, cerrarSesion, recuperarContrasena, registrarUsuario} from '../proxy/FirebaseAuth'
import {registroTerapeuta, prueba, funcionEnviarCorreo} from '../proxy/FirebaseFunctions'
import {consultarDocumento, editarDocumento} from '../proxy/FirebaseDB'
import { SESION, UID_USER, CLEAN_USER, ERROR, ERROR_PASSWORD_INCORRECTO, 
    ERROR_USUARIO_DESCONOCIDO, SIN_FOTO, OK, CLEAN_ADMIN, CLEAN_TERAPEUTA, CLEAN_PACIENTE, PACIENTE_REGISTRADO, CARGANDO_USER
} from "../utilidades/Constantes"
import { eliminarArchivo, subirArchivo } from '../proxy/FirebaseStorage'

export const ingresar = (user, password) => async (dispatch) =>{

    let uid  = await iniciarSesion(user, password)
    // console.log(uid)
    dispatch({
        type: UID_USER,
        payload: uid
    })
    
    if(uid === ERROR || uid === ERROR_PASSWORD_INCORRECTO || uid === ERROR_USUARIO_DESCONOCIDO)
        return

        buscarUsuario(uid, dispatch)
}

export const indicarCargando = () => (dispatch) =>{
    dispatch({
        type: CARGANDO_USER,
        payload: true
    })
}

export const traerUsuario = (uid) => (dispatch) =>{
    buscarUsuario(uid, dispatch)
}

const buscarUsuario = (uid, dispatch) =>{
    consultarDocumento(`usuarios/${uid}`, dispatch, SESION)
}

export const limpiarUsuario = () => (dispatch) =>{
    cerrarSesion()
    dispatch({
        type: CLEAN_USER,
        payload: null
    })
    dispatch({
        type: CLEAN_ADMIN,
        payload: null
    })
    dispatch({
        type: CLEAN_TERAPEUTA,
        payload: null
    })
    dispatch({
        type: CLEAN_PACIENTE,
        payload: null
    })
}

export const recuperarPassword = async (correo) =>{
    let respuesta = await recuperarContrasena(correo)
    return respuesta
}

export const registrarPaciente = async (formulario) =>{
    let respuesta = await registrarUsuario(formulario, 1)
    console.log(respuesta)
    if(respuesta === OK) funcionEnviarCorreo({
        email: formulario.correo,
        nombrePaciente: formulario.nombre,
        tipo: PACIENTE_REGISTRADO
    })
    return respuesta
}

export const enviarCorreo = async (data) =>{
    funcionEnviarCorreo({...data, email: 'consultoria@psicoarketipos.com', tipo: 'CONTACTO_PAGINA'})
}

export const registrarTerapeuta = async (formulario) =>{
    let respuesta = await registroTerapeuta(formulario)
    return respuesta
}

export const pruebaFunctions = async () =>{
    let respuesta = await prueba({})
    return respuesta
}

export const cambiarImagenPerfil = async (imagen, id, usuario) => {
    
    let urlFotoPerfil = await subirArchivo(`usuarios/fotoPerfil/${id}`, imagen)
    
    if(urlFotoPerfil === ERROR)
        return urlFotoPerfil

    let resCambioFotoEnUsuarios = await editarDocumento(`usuarios/${id}`, {urlFotoPerfil})

    if(resCambioFotoEnUsuarios === ERROR) {
        await eliminarArchivo(`usuarios/fotoPerfil/${id}`, imagen)
        return resCambioFotoEnUsuarios
    }

    let resCambioColeccionPrincipal

    switch (usuario) {
        case 'terapeuta':
            resCambioColeccionPrincipal = await editarDocumento(`psicologos/${id}`, {urlFotoPerfil})
            break;

        case 'paciente':
            resCambioColeccionPrincipal = await editarDocumento(`pacientes/${id}`, {urlFotoPerfil})        
            break;
        default:
            break;
    }

    if(resCambioColeccionPrincipal === ERROR) {
        await eliminarArchivo(`usuarios/fotoPerfil/${id}`, imagen)
        await editarDocumento(`usuarios/${id}`, {urlFotoPerfil: SIN_FOTO})
        return resCambioColeccionPrincipal
    }

    return OK
}