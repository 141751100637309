import React from 'react';
import PropTypes from 'prop-types';

const DialogoRecuperar = ({click, cerrar, cambiar, valor}) => {

    const handleSubmit = (e) => {
        e.preventDefault()
        click()
    }

    return (
        <div className="contenedor-dialogo-login">
            <div className="parte-atras-dialogo" onClick={cerrar}/>
            <div className="contenedor-login">
                <form onSubmit={handleSubmit}>
                    <div><span>Recuperar Contraseña</span><hr/></div>
                    <p className="descripcion-dialogo">
                        Ingresa elcorreo con el que estás registrado en la plataforma.
                    </p>
                    <input type="email" name="correo" className="input-recuperar" placeholder="Correo Electrónico" value={valor} onChange={cambiar} required/>
                    <button type="submit" id="btn-primario" className="boton-login btn-arketipos btn-recuperar">
                        Enviar Correo
                    </button>
                </form>
            </div>
        </div>
    )
};

DialogoRecuperar.propTypes = {
    click: PropTypes.func.isRequired,
    cerrar: PropTypes.func.isRequired,
    cambiar: PropTypes.func.isRequired,
    valor: PropTypes.string.isRequired,
};

export default DialogoRecuperar;