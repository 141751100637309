import React, { useEffect, useState } from 'react'
import ImagenCircular from '../../../general/ImagenCircular'
import ciudador from '../../../../../assets/arketipos/ciudador.svg'
import amigo from '../../../../../assets/arketipos/amigo.svg'
import sonador from '../../../../../assets/arketipos/sonador.svg'
import divertido from '../../../../../assets/arketipos/divertido.svg'
import amante from '../../../../../assets/arketipos/amante.svg'
import heroe from '../../../../../assets/arketipos/heroe.svg'
import rebelde from '../../../../../assets/arketipos/rebelde.svg'
import sabio from '../../../../../assets/arketipos/sabio.svg'
import lider from '../../../../../assets/arketipos/lider.svg'
import mago from '../../../../../assets/arketipos/mago.svg'
import creador from '../../../../../assets/arketipos/creador.svg'
import aventurero from '../../../../../assets/arketipos/aventurero.svg'

const arketipos = {
    creador: creador,
    aventurero: aventurero,
    sabio: sabio,
    ciudador: ciudador,
    amigo: amigo,
    sonador: sonador,
    divertido: divertido,
    amante: amante,
    heroe: heroe,
    rebelde: rebelde,
    lider: lider,
    mago: mago,
}

const CarruselArketipos = () => {

    const [posicion, setPosicion] = useState(0)
    const [direccion, setDireccion] = useState(true)

    useEffect(() => {
        
        const timer = setTimeout(()=>{
            let numero = direccion ? posicion + 1 : posicion - 1
            if(numero > Object.values(arketipos).length - 1){
                numero = posicion - 1
                setDireccion(false)
            }
            if(numero < 0){
                numero = posicion + 1
                setDireccion(true)
            }
            setPosicion(numero)
        }, 8000)

        return () => {
            clearTimeout(timer)
        }
    })

    const ancho = window.innerWidth > 1050 ? 20 : 15

    return (
        <div className="imagen-inicio fondo-blanco">
            <div className="flex" style={{
                transform: `translateX(${- ancho *posicion}em)`
            }}>
                {Object.values(arketipos).map((arketipo, i) => {
                    return <ImagenCircular key={`arketipo-${i}`} className="imagen-arketipo" imagen={arketipo} completa={false}/>
                })}
            </div>
        </div>
    )
}

export default CarruselArketipos
