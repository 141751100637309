import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SIN_FOTO } from '../../../../utilidades/Constantes';
import PerfilImg from './../../../../assets/ic_foto_perfil_naranja.svg';

const Pacientes = ({ pacientes }) => {

    let history = useHistory()
    const [list, setList] = useState()

    useEffect(() => {
        if (pacientes && !list) {
            setList(pacientes)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pacientes])

    const filter = (e) => {
        const value = e.target.value
        if (value !== "") {
            const newList = pacientes.filter((paciente) => paciente.nombre.toLowerCase().includes(value.toLowerCase()))
            setList(newList)
        }
        else {
            setList(pacientes)
        }
    }

    return (
        <div className='contenedor-pacientes'>
            <div className='input-buscar'>
                <input type="text" placeholder='Buscar paciente' onChange={filter} />
            </div>
            {list &&
                list.length > 0 ?

                list.map((paciente, index) => (
                    <div
                        key={paciente.uidDoc}
                        className='item-paciente'
                        style={{
                            borderBottom: index === pacientes.length - 1 ? 'none' : '0.01px solid grey'
                        }}>
                        <div className='paciente-nombre-img'>
                            <img
                                className='profile-img'
                                src={
                                    // TODO: Quitar verificacion de null
                                    paciente.urlFotoPerfil ?
                                        paciente.urlFotoPerfil === SIN_FOTO ? PerfilImg : paciente.urlFotoPerfil :
                                        PerfilImg
                                }
                                alt="Imagen de perfil" />
                            {
                                paciente.revisar &&
                                <div className='circulo-revisar' />
                            }
                            <div className='nombre-usuario'>{paciente.nombre}</div>
                        </div>
                        <button
                            className='btn-arketipos'
                            onClick={() => history.push(`/terapeuta/pacientes/${paciente.uidDoc}`)}>
                            Ver perfil
                        </button>
                    </div>
                ))
                :
                <div className='texto-sin-pacientes'>
                    No tienes pacientes asignados
                </div>
            }
        </div>
        //     :
        // <div className='contenedor-pacientes'>
        //     {
        //         Array.from(Array(5).keys()).map((element, index) => (
        //             <div key={index} className="item-paciente" style={{
        //                 borderBottom: index === 4 ? 'none' : '0.01px solid grey'
        //             }}>
        //                 <div className='paciente-nombre-img'>
        //                     <SkeletonResponsive tipo='circle' className='sk-profile-img' />
        //                     <SkeletonResponsive tipo='rect' className='sk-nombre-usuario' />
        //                 </div>
        //                 <SkeletonResponsive tipo='rect' className='sk-btn-arketipos' />
        //             </div>
        //         ))
        //     }
        // </div>
    );
}

export default Pacientes;