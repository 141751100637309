import React from 'react'
import PropTypes from 'prop-types'
import SkeletonResponsive from '../../../../general/SkeletonResponsive'
import BotonAgenda from '../../../general/BotonAgenda'

const Aliados = ({ aliados, cargando }) => {
    return (
        <div className="center flex-column fondo-verde">
            <br />
            {aliados && aliados.length > 0 && <h3 className="letra-nombres letra-blanca">Nuestros aliados</h3>}

            {aliados && aliados.length > 0 && <div className="flex jc-space-around ai-end contenedor-aliados">
                {cargando && <div className="flex flex-column ai-center contenedor-aliado">
                    <SkeletonResponsive tipo='text' className="sk-parrafo" />
                </div>}
                {cargando && <div className="flex flex-column ai-center contenedor-aliado">
                    <SkeletonResponsive tipo='text' className="sk-parrafo" />
                </div>}
                {cargando && <div className="flex flex-column ai-center contenedor-aliado">
                    <SkeletonResponsive tipo='text' className="sk-parrafo" />
                </div>}
                {
                    aliados.map((aliado) => {
                        return <div className="flex flex-column ai-center contenedor-aliado">
                            <div className="text-align-center letra-blanca letra-aliados bold-600 center">{aliado.nombre}</div>
                            <a
                                className="center btn-arketipos fondo-blanco boton-aliado"
                                href={aliado.url}
                                target="noreferrer"
                            >
                                Visita la página
                            </a>
                        </div>
                    })
                }
            </div>}
            <div style={{ margin: '1rem 0 0' }} className='w-full center'>
                <BotonAgenda bgColor="white" textColor="green" />
            </div>
            <br /><br /><br />
        </div>
    )
}

Aliados.propTypes = {
    servicios: PropTypes.array.isRequired,
    cargando: PropTypes.bool.isRequired,
}

export default Aliados
