import React from 'react'
import PropTypes from 'prop-types'

const LogoColor = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 201.71 70.46">
            <g id="Capa_2" data-name="Capa 2">
                <g id="Capa_1-2" data-name="Capa 1">
                    <path d="M82.93,55.13c-1.3,1.45-3.57,2.51-5.6-.13-1-1.06-2.06-2.44-3.86-2.47V52.1h.23a12.4,12.4,0,0,0,6.37-1.41,4.45,4.45,0,0,0,2.34-4c0-3.5-2.34-5.46-7.08-5.46A9.6,9.6,0,0,0,69.48,43a9.09,9.09,0,0,0-3.32,4.68h-.34V42.19a39.92,39.92,0,0,1,.78-8.6H57.68a40,40,0,0,1,.74,8.6V54.24a40,40,0,0,1-.74,8.6H66.6a28.91,28.91,0,0,1-.74-6.6v-2.5a6.81,6.81,0,0,1,2.06-.24,5,5,0,0,1,2.32.52,6.66,6.66,0,0,1,2.7,3.16A20.54,20.54,0,0,0,75.17,61a5.59,5.59,0,0,0,4.46,1.82A12.25,12.25,0,0,0,86,60.55,11.43,11.43,0,0,1,82.93,55.13ZM71.24,50.85H65.9a4.38,4.38,0,0,1,1.94-3.06,6.19,6.19,0,0,1,3.84-1.34c1.9,0,3.11.8,3.11,2.14C74.79,50.09,73.41,50.85,71.24,50.85Z"/>
                    <path d="M89.94,59.84A9.57,9.57,0,0,1,86.27,52a10.18,10.18,0,0,1,3.31-7.66c2.14-2,5.5-3.16,9.5-3.16s7,.86,8.77,2.24a6.3,6.3,0,0,1,2.64,5.06c0,3.94-3,5.73-8.75,5.73A15,15,0,0,1,93.1,52l-.3.15C92.9,55.38,97,58,101.62,58a15.6,15.6,0,0,0,4.84-.74,7.71,7.71,0,0,0,3.41-1.92l.12,5.56a15.19,15.19,0,0,1-4.45,1.42,30.25,30.25,0,0,1-5.78.56C95.66,62.84,92.4,61.84,89.94,59.84ZM103.66,49a2.55,2.55,0,0,0-1.4-2.22,7,7,0,0,0-3.64-.84A6.36,6.36,0,0,0,95,47,4.6,4.6,0,0,0,93,50a10.13,10.13,0,0,0,6,1.6C101.3,51.63,103.66,50.77,103.66,49Z"/>
                    <path d="M0,62.84a71.42,71.42,0,0,0,3.48-8.77L8,42.36a34,34,0,0,0,1.57-8.77H22.85a33.83,33.83,0,0,0,1.52,8.77l4.57,11.71a70.54,70.54,0,0,0,3.48,8.77h-9a23.38,23.38,0,0,0-1.32-5.59l-.5-1.3H10.89l-.54,1.3a25,25,0,0,0-1.29,5.59ZM12.15,51.48h8.12l-2.58-6.26c-.3-.86-.74-2.47-1.28-4.81H16c-.64,2.57-1.08,4.18-1.32,4.85Z"/>
                    <path d="M35.6,62.84A31.65,31.65,0,0,0,36.3,56V49.41a36.1,36.1,0,0,0-.7-7.62h9.3a8.19,8.19,0,0,0-.84,2.3,12.63,12.63,0,0,0-.32,2.78v1.52h.46c.9-4.56,3.6-7.18,7.38-7.18a9.2,9.2,0,0,1,3.17.58v6.76a5.82,5.82,0,0,0-1.91-1.1A7.45,7.45,0,0,0,50.3,47a6.41,6.41,0,0,0-4.6,1.9,6.25,6.25,0,0,0-2,4.65V57.4a15.14,15.14,0,0,0,.78,5.44Z"/>
                    <path d="M145.87,70.46a40,40,0,0,0,.74-8.6V50.39a40,40,0,0,0-.74-8.6h8.91a13.4,13.4,0,0,0-.78,4.26v.86h.54a9.11,9.11,0,0,1,3.18-4.12,8.92,8.92,0,0,1,5.38-1.58,9.14,9.14,0,0,1,7.27,3.16A12,12,0,0,1,172.83,52a12,12,0,0,1-2.46,7.65,10,10,0,0,1-12.67,1.6,8.58,8.58,0,0,1-3.2-4.14H154v4.72a44.78,44.78,0,0,0,.78,8.6Zm18.37-14.28a6.06,6.06,0,0,0,0-8.29,6,6,0,0,0-4.3-1.6,5.54,5.54,0,0,0-4.18,1.64,5.88,5.88,0,0,0,0,8.13,5.48,5.48,0,0,0,4.16,1.74A5.85,5.85,0,0,0,164.24,56.18Z"/>
                    <path d="M188.93,62.84a16.48,16.48,0,0,1-5.52-.86A10.16,10.16,0,0,1,177,56.22a10.49,10.49,0,0,1-.84-4.19,10.6,10.6,0,0,1,.84-4.22,10.21,10.21,0,0,1,6.41-5.76,18.56,18.56,0,0,1,11,0,10.29,10.29,0,0,1,6.44,5.76,11.24,11.24,0,0,1,0,8.43A10.21,10.21,0,0,1,194.45,62,16.48,16.48,0,0,1,188.93,62.84Zm0-5A5.62,5.62,0,0,0,194.77,52a5.79,5.79,0,0,0-1.62-4.22,6,6,0,1,0-4.22,10.07Z"/>
                    <path className={`cls-1 cls-${color}`} d="M137.35,43.27a5.28,5.28,0,0,0,3.75-1.37,4.91,4.91,0,0,0,0-6.95,5.85,5.85,0,0,0-7.5,0,4.87,4.87,0,0,0,0,6.95A5.28,5.28,0,0,0,137.35,43.27Z"/>
                    <path className={`cls-1 cls-${color}`} d="M137.34,62.84a9.61,9.61,0,0,0,2.61-.36,4.21,4.21,0,0,0,2.49-1.38c.76-1.59.3-9.53.13-11.57a5.07,5.07,0,0,0-.35-1.33c-.76-1.55-3.08-3.71-4.88-3.71s-4.11,2.16-4.87,3.71a4.71,4.71,0,0,0-.36,1.33c-.17,2-.63,10,.13,11.57a4.21,4.21,0,0,0,2.49,1.38A9.61,9.61,0,0,0,137.34,62.84Z"/>
                    <path d="M128.08,56.18a6,6,0,0,1-2.82.68c-2.62,0-4.2-1.4-4.2-4.22V45h7.24V41.79h-7.24v-8.2l-6.93,1.86V55.22c0,5.78,4.26,7.78,9.55,7.62a10.26,10.26,0,0,0,4.82-1.12,16.79,16.79,0,0,1-.39-2.78C128.05,58.08,128.07,57.14,128.08,56.18Z"/>
                    <path d="M8.26,30.12A37.87,37.87,0,0,1,1.52,28.3l.35-8.67a17.35,17.35,0,0,0,6.07,3,25.77,25.77,0,0,0,7.06,1c3.45,0,6.07-.79,6.11-2.65,0-4.7-18.84-.24-18.84-11.28C2.19,4.28,8,.71,15.63.79a31.81,31.81,0,0,1,5.95.58,26.24,26.24,0,0,1,4.94,1.39v8.32a15.77,15.77,0,0,0-4.7-2,21.86,21.86,0,0,0-6-.83c-3.69,0-6,.85-6,2.56,0,2.89,5.85,2.77,11,3.88,4.18.92,7.91,2.64,7.91,7.44a6.92,6.92,0,0,1-1.77,4.8,10.45,10.45,0,0,1-4.81,3,22.41,22.41,0,0,1-7,1A38,38,0,0,1,8.26,30.12Z"/>
                    <path d="M42.87,30.87A16.74,16.74,0,0,1,37.3,30a10.25,10.25,0,0,1-6.48-5.81,11,11,0,0,1,0-8.49A10.28,10.28,0,0,1,37.3,9.89,17,17,0,0,1,42.87,9a17,17,0,0,1,5.56.85,10.36,10.36,0,0,1,6.5,5.81,11.32,11.32,0,0,1,0,8.51A10.31,10.31,0,0,1,48.43,30,16.72,16.72,0,0,1,42.87,30.87Zm0-5A5.67,5.67,0,0,0,48.76,20a5.84,5.84,0,0,0-1.64-4.25A5.91,5.91,0,0,0,37,20a5.68,5.68,0,0,0,5.91,5.91Z"/>
                    <path d="M57.7,30.28a36.71,36.71,0,0,0,.78-8.67V13.84a22.72,22.72,0,0,0-.28-4.22,7.77,7.77,0,0,0,4.14.93,6.82,6.82,0,0,0,4-.93A26.13,26.13,0,0,0,66,13.84v7.77a36.09,36.09,0,0,0,.79,8.67Zm1.55-24a3.47,3.47,0,0,1-1.13-2.62,3.53,3.53,0,0,1,1.13-2.66,4.77,4.77,0,0,1,6,0,3.47,3.47,0,0,1,1.11,2.66,3.36,3.36,0,0,1-1.11,2.62,4.77,4.77,0,0,1-6,0Z"/>
                    <path d="M72.92,28.41a9.28,9.28,0,0,1-2.44-6.84V17.71a38.66,38.66,0,0,0-.7-8.09h9A31.39,31.39,0,0,0,78,17v3.28c0,3.33,1.85,5.35,5.08,5.35a5.29,5.29,0,0,0,4-1.71,5.59,5.59,0,0,0,1.59-4V16.89c0-1.15-.06-2.48-.18-4A15,15,0,0,0,88,9.62h9a32.23,32.23,0,0,0-.77,6.94v6A35.9,35.9,0,0,0,97,30.28H87.83A9.41,9.41,0,0,0,88.7,26v-.83h-.46a8.35,8.35,0,0,1-8.44,5.75A9.29,9.29,0,0,1,72.92,28.41Z"/>
                    <path d="M100.32,30.28a30.7,30.7,0,0,0,.75-6.94v-6a34.36,34.36,0,0,0-.75-7.69h9.12a9.48,9.48,0,0,0-.9,4.38v.83H109A8.38,8.38,0,0,1,117.47,9a9.18,9.18,0,0,1,6.88,2.48,9.37,9.37,0,0,1,2.42,6.86v3.81a37.94,37.94,0,0,0,.73,8.09h-9a33,33,0,0,0,.79-7.36V19.67c0-3.33-1.88-5.39-5.09-5.39a5.34,5.34,0,0,0-4,1.7,5.64,5.64,0,0,0-1.61,4V23c0,1.19.06,2.52.2,4a14.09,14.09,0,0,0,.58,3.27Z"/>
                </g>
            </g>
        </svg>
    )
}

LogoColor.defaultValues = {
    color: 'azul'
}

LogoColor.propTypes = {
    color: PropTypes.string.isRequired,
}

export default LogoColor
