import firebase from './Firebase'
import {crearDocumento} from './FirebaseDB'
import { ERROR, OK, ERROR_PASSWORD_INCORRECTO, ERROR_USUARIO_DESCONOCIDO, ERROR_CORREO_INVALIDO} from '../utilidades/Constantes'

const auth = firebase.auth()

export const registrarUsuario = async (data, rol) =>{
    return auth.createUserWithEmailAndPassword(data.correo, data.password).then((u)=>{
        let user = u.user.uid
        return crearDocumento(`usuarios/${user}`, {
            aprobado: false,
            correo: data.correo,
            nombre: data.nombre,
            telefono: data.telefono,
            descripcion: data.descripcion,
            rol: rol
        })
    }).then((d)=>{
        return OK
    }).catch((e)=>{
        return ERROR
    })
    
}

export const iniciarSesion = async (user, password) =>{
    try {
        let usuario = await auth.signInWithEmailAndPassword(user, password)
        return usuario.user.uid
    } catch (error) {
        console.log(error)
        if(error.code === ERROR_PASSWORD_INCORRECTO) return ERROR_PASSWORD_INCORRECTO
        if(error.code === ERROR_USUARIO_DESCONOCIDO) return ERROR_USUARIO_DESCONOCIDO
        return ERROR
    }

}

export const cerrarSesion = () =>{
    auth.signOut()
}

export const recuperarContrasena = async (correo) =>{
    try{
        await auth.sendPasswordResetEmail(correo)
        return OK
    }
    catch(e){
        console.log(e)
        if(e.code === ERROR_CORREO_INVALIDO) return ERROR_CORREO_INVALIDO
        return ERROR
    }
}