import React from 'react'
import PropTypes from "prop-types";
import Skeleton from '@material-ui/lab/Skeleton'

const SkeletonResponsive = ({id, className, tipo, borderRadius = 'auto'}) => {
    return (
        <div id={id} className={`contenedor-skeleton ${className??''}`}>
            <Skeleton 
                variant={tipo}
                width={"100%"}
                height={"100%"}
                animation={'wave'}
                style={{
                    borderRadius
                }}/>
        </div>
    )
}

SkeletonResponsive.propTypes = {
    className: PropTypes.string,
    tipo: PropTypes.string.isRequired,
};

export default SkeletonResponsive
