import React, { useEffect, useState } from 'react'
import Carrusel from '../general/carrusel/Carrusel'
import Consultoria from './components/consultoria/Consultoria'
import Aliados from './components/aliados/Aliados'
import { getLoadingServicios, getServiciosState } from '../../../reducers/landingReducer'
import { bindActionCreators } from 'redux'
import { getServiciosLnading } from '../../../actions/landingActions'
import { connect } from 'react-redux'
import SkeletonResponsive from '../../general/SkeletonResponsive'
import BotonAgenda from '../general/BotonAgenda'

const Servicios = ({ servicios, cargando, traerServicios }) => {

    useEffect(() => {
        if (!servicios && !cargando) traerServicios()
        // eslint-disable-next-line
    }, [])

    const info = servicios ?? {}

    const [carruselSeleccionado, setCarruselSeleccionado] = useState(0)

    let arreglo = []

    if (carruselSeleccionado === 0) arreglo = info.psicologia ?? []
    if (carruselSeleccionado === 1) arreglo = info.formacion ?? []
    if (carruselSeleccionado === 2) arreglo = info.laborSocial ?? []

    return (
        <div className="contenedor-landing contenedor-servicios">
            <div className="primera-division fondo-verde letra-blanca letra-seccion-titulo bold-900">
                {cargando ? <SkeletonResponsive tipo='text' className="sk-letra-titulos" /> : <div className='w-full center column text-container'>
                    {info.tituloGrande ?? ''}
                    <BotonAgenda textColor="green" />

                </div>}
            </div>
            <div className="menu-servicios center">
                <button onClick={() => setCarruselSeleccionado(0)} className="center flex-column letra-menus">
                    <span className={carruselSeleccionado === 0 ? 'letra-seleccionada' : 'letra-no-seleccionada'}>Psicología</span>
                    <hr className={carruselSeleccionado === 0 && 'fondo-gris'} />
                </button>
                <button onClick={() => setCarruselSeleccionado(1)} className="center flex-column letra-menus">
                    <span className={carruselSeleccionado === 1 ? 'letra-seleccionada' : 'letra-no-seleccionada'}>Formación</span>
                    <hr className={carruselSeleccionado === 1 && 'fondo-gris'} />
                </button>
                <button onClick={() => setCarruselSeleccionado(2)} className="center flex-column letra-menus bold-500">
                    <span className={carruselSeleccionado === 2 ? 'letra-seleccionada' : 'letra-no-seleccionada'}>Labor Social</span>
                    <hr className={carruselSeleccionado === 2 && 'fondo-gris'} />
                </button>
            </div>
            <hr className="borde-abajo" />
            <Carrusel arreglo={arreglo} izquierda={false} actual={carruselSeleccionado} cargando={cargando} />
            <Consultoria servicios={info.consultoria ?? []} cargando={cargando} />
            <Aliados aliados={info.aliados ?? []} cargando={cargando} />
        </div>
    )
}

const mapStateToProps = (state) => ({
    servicios: getServiciosState(state),
    cargando: getLoadingServicios(state)
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    traerServicios: getServiciosLnading
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Servicios)
