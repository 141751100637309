import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ic_eliminar from '../../../../assets/ic_eliminar.svg'
import DialogoDosBotones from '../../../general/DialogoDosBotones'
import ReactPlayer from 'react-player'
import TextoInterlineado from '../../../general/TextoInterlineado'
import { updateOrderActivity } from '../../../../actions/terapeutaActions'

const Actividad = ({ idTerapia, idTarea, actividad, editar, index, eliminar }) => {

    const [dialogo, setDialogo] = useState(false)

    const pintarTipoActividad = (recurso, i) => {
        if (recurso.tipo === 0)
            return <a href={recurso.url} target="_blank" rel="noreferrer noopener" key={`recurso-${i}`}>{recurso.nombre ?? 'Documento Adjunto'}</a>

        if (recurso.tipo === 1) {
            if (recurso.url.includes('youtube.com') || recurso.url.includes('youtu.be')) {
                // let arUrl = recurso.url.split("/")
                // let url = arUrl[arUrl.length - 1]
                return <div className="contenedor-recurso">
                    <ReactPlayer url={recurso.url} width="100%" height="10em" />
                </div>
                // return <iframe
                //     src={`https://www.youtube.com/embed/${url}`}
                //     title={"Video"}
                //     key={`recurso-${i}`} frameBorder="0"
                //     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                //     allowFullScreen
                // />
            }
            else
                return <ReactPlayer url={recurso.url} width="100%" height="10em" key={`recurso-${i}`} />
            // return <video className="video" key={`recurso-${i}`} alt="video actividad" controls>
            //     <source src={recurso.url} type="video/mp4" />
            // </video>
        }

        if (recurso.tipo === 2)
            return <img src={recurso.url} width="150" height="100" key={`recurso-${i}`} alt="imagen actividad" />

        return ''
    }

    const eliminarActividad = () => {
        setDialogo(false)
        eliminar()
    }

    return (
        <div className="con-tareas contenedor-actividades">
            {dialogo &&
                <DialogoDosBotones
                    titulo="Eliminar actividad"
                    descripcion="¿Está seguro de querer eliminar esta actividad?"
                    boton1="Si" boton2="No"
                    click1={eliminarActividad}
                    click2={() => setDialogo(false)}
                    color="naranja"
                    cerrar={() => setDialogo(false)}
                />
            }
            <div className="contenido-actividad">
                <TextoInterlineado numero={index + 1} className="texto" texto={actividad.descripcion} />
                <div className='flex justify-end items-center'>

                    <input className='input-index' type="number" placeholder='0' defaultValue={actividad.index ?? 0}
                        onBlur={async (e) => {
                            const value = parseInt(e.target.value ?? 0);
                            await updateOrderActivity(
                                idTerapia,
                                idTarea,
                                (actividad.uidDocumento || actividad.uidDoc),
                                value
                            )
                        }}
                    />
                    <button className="boton-eliminar" type="button" onClick={() => setDialogo(true)}>
                        <img src={ic_eliminar} alt="eliminar" />
                    </button>
                </div>

            </div>
            <div className="contenedor-recursos">
                {actividad.recursos.map((r, i) => {
                    return pintarTipoActividad(r, i)
                })}
            </div>
            <button
                className="btn-arketipos fondo-borde-naranja btn-actividad"
                onClick={() => editar(actividad)}
            >
                Editar
            </button>
        </div>
    )
}

Actividad.propTypes = {
    actividad: PropTypes.object.isRequired,
    idTerapia: PropTypes.string,
    idTarea: PropTypes.string,
    editar: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    eliminar: PropTypes.func.isRequired
}

export default Actividad
