import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import DialogoDosBotones from '../../../general/DialogoDosBotones'

const textoFinal = (textoAuste) => {
    let texto = textoAuste.split("\\n")

    return texto.map((tx, i) => {
        
        return (<span key={`texto-${i}`}>{tx}<br/></span>)
    })
}


const FormualrioCarrusel = ({onSubmit, anuncio, nuevo, eliminar}) => {

    const [documento, setdocumento] = useState(nuevo ? anuncio : null)
    const [preguntar, setPregunta] = useState(false)

    useEffect(() => {
        if(nuevo) setdocumento(anuncio)
        // eslint-disable-next-line
    }, [])

    const handleChangeHome = (e) => {
        setdocumento({
            ...documento,
            [e.target.name]: e.target.value.replaceAll('\n', '\\n')
        })
    }

    const handleChangeFileHome = (e) => {
        if(e.target.files[0])
            setdocumento({
                ...documento,
                imagenArchivo: e.target.files[0],
                urlAnterior: anuncio.imagen,
                imagen: URL.createObjectURL(e.target.files[0])
            })
    }

    const handleSubmit = (e) => {
        delete documento.uidDoc
        onSubmit(e, anuncio.uidDoc, documento)
        setdocumento(null)
    }

    if(documento || nuevo){
        return (
            <form className="flex flex-column" onSubmit={handleSubmit}>
                <label className="flex flex-column campo-landing">
                    <span>Título</span>
                    <input type="text" name="titulo" onChange={handleChangeHome} value={documento.titulo??''} required/>
                </label>
                <label className="flex flex-column campo-landing">
                    <span>Descripción</span>
                    <textarea name="texto" onChange={handleChangeHome} value={(documento.texto??'').replaceAll("\\n", "\n")} required/>
                </label>
                <label className="flex flex-column campo-landing">
                <div className="flex ai-center jc-start">
                        <span className="btn-arketipos fondo-terapeuta center">Seleccionar Imagen</span>
                        <span>{ documento.imagenArchivo ? documento.imagenArchivo.name : 'Sin imagen seleccionada'}</span>
                </div>
                    <input type="file" accept="image/*" name="imagen" onChange={handleChangeFileHome} required={nuevo}/>
                    <br/>
                    <a href={anuncio.imagen??''} target="noreferrer">Ver Imagen</a>
                </label>
                <div>
                    <button className="btn-arketipos fondo-azul">Guardar Cambios</button>
                    {!nuevo && <button type="button" className="btn-arketipos boton-eliminar" onClick={()=>setdocumento(null)}>Cancelar</button>}
                </div>
                <br/>
                <hr className="separador-landing separador-landing-gris"/>
                <br/>
            </form>
        )
    }
    
    return (
        <div className="flex flex-column">
            {preguntar && <DialogoDosBotones 
                titulo="¿Seguro?"
                descripcion="¿Está seguro de querer eliminar este anuncio?"
                boton1="Si"
                boton2="No" 
                click2={()=>setPregunta(false)}
                click1={()=>{
                    eliminar(anuncio.uidDoc, anuncio)
                    setPregunta(false)
                }}
                cerrar={()=>setPregunta(false)}
            />}
            <div className="flex flex-column campo-landing">
                <span><b>Título</b></span>
                <div>{anuncio.titulo??''}</div>
            </div>
            <div className="flex flex-column campo-landing">
                <span><b>Descripción</b></span>
                <div>{textoFinal(anuncio.texto??'')}</div>
            </div>
            <div className="flex flex-column campo-landing">
                <span><b>Imagen actual</b></span>
                <a href={anuncio.imagen??''} target="noreferrer">Ver Imagen</a>
            </div>
            <div>
                <button onClick={()=>setdocumento(anuncio)} className="btn-arketipos fondo-azul">Editar</button>
                {!nuevo && <button type="button" className="btn-arketipos boton-eliminar" onClick={()=>setPregunta(true)}>Eliminar</button>}
            </div>
            <br/>
            <hr className="separador-landing separador-landing-gris"/>
            <br/>
        </div>
    )
}

FormualrioCarrusel.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    anuncio: PropTypes.object.isRequired,
    nuevo: PropTypes.bool.isRequired,
    eliminar: PropTypes.func.isRequired,
}

export default FormualrioCarrusel
