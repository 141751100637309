export function formatDate(date) {
    debugger
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export const mapToArray = (map) => {

    let arreglo = []

    for (let v in map) {
        arreglo.push({ ...map[v], id: v })
    }
    return arreglo
}

export const rand_code = (lon) => {
    const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ012346789"
    let code = "";
    for (let x = 0; x < lon; x++) {
        let rand = Math.floor(Math.random() * chars.length);
        code += chars.substr(rand, 1);
    }
    return code;
};