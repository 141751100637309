import React, {Component} from 'react'
import { withRouter } from 'react-router-dom'
import DialogoUnBoton from '../general/DialogoUnBoton'
import Loading from '../general/Loading'
import {enviarCorreo} from '../../actions/sesionActions'


class Contacto extends Component{

    state={
        nombre:'',
        correo:'',
        descripcion:'',
        cargando: false,
        dialogo: 0,
        registroFinalizado: false
    }

    handleChange=(e)=>{
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    handleSubmit= async(e)=>{
        e.preventDefault()

        this.setState({
            cargando: true
        })
        await enviarCorreo({
            nombre: this.state.nombre,
            correo: this.state.correo,
            mensaje: this.state.descripcion
        })
        this.setState({
            cargando: false,
            dialogo: 1
        })
    }

    cerrarDialogo = ()=>{
        this.setState({dialogo:0}, ()=>this.props.history.goBack())
    }

    abrirDialogo = (tipo) =>{
        let titulo
        let descripcion
        let funcion
        
        if(tipo === 1){
            titulo="¡Solicitud recibida!"
            descripcion="Ya hemos recibido tu solicitud, muy pronto nos pondremos en contacto contigo."
            funcion = this.finalizarRegistro
        }

        if(tipo === 2){
            titulo="Algo falló"
            descripcion="Parece que algo está fallando con tu registro, inténtalo más tarde."
            funcion = this.cerrarDialogo
        }

        if(tipo === 3){
            titulo="Las contraseñas no coinciden"
            descripcion="Las contraseñas que ingresaste no son iguales, inténtalo nuevamente."
            funcion = this.cerrarDialogo
        }
        return this.mostrarDialogo(titulo, descripcion, funcion)
    }

    mostrarDialogo = (titulo, descripcion, funcion) =>{
        return <DialogoUnBoton
            titulo={titulo}
            descripcion={descripcion}
            boton1="Entendido"
            cerrar={this.cerrarDialogo}
            click1={funcion}
        />
    }

    finalizarRegistro = () =>{
        this.setState({
            registroFinalizado: true
        })
    }

    render(){
        
        if(this.state.dialogo === 1) return this.abrirDialogo(this.state.dialogo)

        return(
            <div className="contenedor-dialogo-login">
                <div className="parte-atras-dialogo" onClick={()=>this.props.history.goBack()}/>
                <div className="contenedor-login">
                    <form onSubmit={this.handleSubmit}>
                        <div><span>Contactanos</span><hr/></div>
                        <input type="text" name="nombre" placeholder="Tu nombre" onChange={this.handleChange} value={this.state.nombre} required/>
                        <input type="email" name="correo" placeholder="Tu correo" onChange={this.handleChange} value={this.state.correo} required/>
                        <textarea name="descripcion" placeholder="Envianos un mensaje..." onChange={this.handleChange} value={this.state.descripcion} required/>
                        <div className="botones-registro">
                            <button type="submit" className="btn-arketipos boton-login btn-registro-usuario">Enviar</button>
                            <button type="button" onClick={()=>this.props.history.goBack()} className="btn-arketipos boton-login btn-registro-blanco">Cancelar</button>
                        </div>
                    </form>
                </div>
                {this.state.dialogo===0?'':this.abrirDialogo(this.state.dialogo)}
                {this.state.cargando?<Loading />:''}
            </div>
        )
    }
}

export default withRouter(Contacto)