import { ERROR } from '../utilidades/Constantes';
import firebase from './Firebase'
const storage = firebase.storage()
const storageRef = storage.ref()

export const subirArchivo = async (ruta, file) => {
    try {
        let ref = storageRef.child(ruta);
        await ref.put(file)
        return await ref.getDownloadURL()    
    } catch (error) {
        return ERROR;
    }
}

export const eliminarArchivoUrl = async (url) => {
    try {
        var ref = storage.refFromURL(url)
        return await ref.delete()
    } catch (error) {
        return ERROR
    }
}

export const eliminarArchivo = async (ruta) => {
    try {
        var ref = storageRef.child(ruta);
        return await ref.delete()    
    } catch (error) {
        return ERROR
    }
}