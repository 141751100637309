import React, {useEffect, useState} from 'react'
import { Link, Route, Switch, withRouter } from 'react-router-dom'
// import logo_azul from '../../assets/logo_azul.svg'
// import logo_amarillo from '../../assets/logo_amarillo.svg'
// import logo_verde from '../../assets/logo_verde.svg'
import Contacto from '../contacto/Contacto'
import Login from '../login/Login'
import Registro from '../registro/Registro'
// import botonMenu from '../../assets/ic_i_azul.svg'
// import icCerrar from '../../assets/ic_cerrar.svg'
import SkeletonResponsive from './SkeletonResponsive'
// import sinFotoAzul from '../../assets/ic_foto_perfil_azul.svg'
// import sinFotoVerde from '../../assets/ic_foto_perfil_verde.svg'
// import sinFotoNaranja from '../../assets/ic_foto_perfil_naranja.svg'
import { SIN_FOTO } from '../../utilidades/Constantes'
import { connect } from 'react-redux'
import * as actions from '../../actions/sesionActions'
import firebase from '../../proxy/Firebase'
import { Menu, Close } from '@material-ui/icons'
import FotoPerfilColor from './FotoPerfilColor'
import LogoColor from './LogoColor'

const Header = ({location, user, cargnadoUser, limpiarUsuario, indicarCargando, traerUsuario}) => {

    const [auth, setAuth] = useState(true)

    useEffect(() => {
        
        if(!user){
            indicarCargando()
            firebase.auth().onAuthStateChanged((u)=>{
                if(u === null){
                    setAuth(false)
                    limpiarUsuario()
                }
                else{
                    traerUsuario(u.uid)
                }
            })
        }
        // eslint-disable-next-line
    }, [])

    const ruta = location.pathname
    // let logo = logo_azul

    let color = 'fondo-azul'
    let ubicacion = -1
    let colorPerfil = 'azul'

    if(ruta === "/") {
        // logo = logo_azul
        color = 'fondo-azul'
        ubicacion = 0
        colorPerfil = 'azul'
    }
    if(ruta === "/servicios") {
        color = 'fondo-verde'
        colorPerfil = 'verde'
        ubicacion = 1
        // logo = logo_verde
    }
    if(ruta === "/nosotros") {
        color = 'fondo-amarillo'
        colorPerfil = 'amarillo'
        ubicacion = 2
        // logo = logo_amarillo
    }
    if(ruta === "/contacto") ubicacion = 3

    const [mostrar, setMostrar] = useState(false)
    let ancho = window.innerWidth

    let usuario = user??{}
    let rol = usuario.rol??3
    let cargando = cargnadoUser??false
    let rutaPerfil = '#'
    // let sinFoto = null

    if(rol === 0) {
        rutaPerfil = '/administrador/perfil'
        // sinFoto = sinFotoAzul
    }
    if(rol === 1){
        rutaPerfil = '/paciente/perfil'
        // sinFoto = sinFotoVerde
    }
    if(rol === 2){
        rutaPerfil = '/terapeuta/perfil'
        // sinFoto = sinFotoNaranja
    }
    if(rol === 3){
        rutaPerfil = '/administrador-landing'
        // sinFoto = sinFotoNaranja
    }

    return (
        <header className={mostrar ? 'menu-grande-responsive' : ''}>
            <button className="menu-responsive" onClick={()=>setMostrar(!mostrar)} style={{
                left: mostrar?'79vw':'2vw',
                transition: '1s all ease'
            }}>
                {!mostrar? 
                    <Menu className={color}/>:
                    <Close className={color}/>
                }
                {/* <img src={mostrar?icCerrar:botonMenu} alt="boton-menu"/> */}
            </button>
            {(mostrar || ancho > 800) &&
                <Link to="/" className="imagen-header">
                    <LogoColor color={colorPerfil}/>
                    {/* <img src={logo} alt="logo"/> */}
                </Link>
            }
            {(mostrar || ancho > 800) &&
            <div className="opciones-header">
                <Link onClick={()=>setMostrar(false)} to="/" className="opcion-header">Home <hr className={`${ubicacion === 0 &&'seleccionado'} ${color}`}/></Link>
                <Link onClick={()=>setMostrar(false)} to="/servicios" className="opcion-header">Servicios <hr className={`${ubicacion === 1 &&'seleccionado'} ${color}`}/></Link>
                <Link onClick={()=>setMostrar(false)} to="/nosotros" className="opcion-header">Nosotros <hr className={`${ubicacion === 2 &&'seleccionado'} ${color}`}/></Link>
                <Link onClick={()=>setMostrar(false)} to="/contacto" className="opcion-header">Contacto <hr className={`${ubicacion === 3 &&'seleccionado'} ${color}`}/></Link>
            </div>
            }
            {!mostrar && (!auth || !user) && <div className="opciones-login-header">
                <Link to="/login" className="boton borde-blanco">Ingresa</Link>
                <Link to="/registro" className={`boton btn-arketipos btn-registro transicion ${color}`}>Crear Cuenta</Link>
            </div>}


            {auth && user && <div className="opciones-login-header">
                {
                    cargando?
                    <div to={rutaPerfil} className="btn-perfil">
                        <SkeletonResponsive tipo='circle' className="sk-foto"/>
                        <SkeletonResponsive tipo='rect' className="sk-nombre-header"/>
                    </div>
                    :
                    <Link to={rutaPerfil} className="btn-perfil">
                        {
                        usuario.urlFotoPerfil ?
                            usuario.urlFotoPerfil === SIN_FOTO ? 
                            <FotoPerfilColor color={colorPerfil}/>
                            : <img src={usuario.urlFotoPerfil} alt="Imagen de perfil"/>:
                            <FotoPerfilColor color={colorPerfil}/>
                        }
                        
                        <div to={rutaPerfil} className={`nombre-header text-decoration-${colorPerfil}`}>
                            {usuario.nombre??'Cargando...'}
                            <hr/>
                        </div>
                    </Link>
                }
                {
                    cargando?
                    <SkeletonResponsive tipo='rect' className="sk-boton-header"/>
                    :
                    <button className={`btn-arketipos fondo-borde-${colorPerfil}`} onClick={limpiarUsuario}>Cerrar sesión</button>
                }
            </div>}

            <Switch>
                <Route path="/login">
                    <Login />
                </Route>
                <Route path="/registro">
                    <Registro />
                </Route>
                <Route path="/contacto">
                    <Contacto />
                </Route>
            </Switch>
        </header>
    )
}

const mapStateToProps = (reducers) =>{
    return reducers.sesionReducer
}

export default withRouter(connect(mapStateToProps, actions)(Header))