import { SESION, UID_USER, CLEAN_USER, CARGANDO_USER} from "../utilidades/Constantes";
import {createSelector} from 'reselect';

const INITIAL_STATE = {
    uidUser: null,
    user: null,
    cargnadoUser: false
}
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) =>{
    switch (action.type){
        case UID_USER:
            return {...state, uidUser: action.payload}
        case SESION:
            return {...state, user: action.payload, cargnadoUser: false}
        case CLEAN_USER:
            return {...state, user: null, uidUser: action.payload, cargnadoUser: false}
        case CARGANDO_USER:
            return {...state, cargnadoUser: action.payload}
        default: return {...state}
    }
}

export const getUserInfo = createSelector(state => state.sesionReducer, sesionReducer => {
    return sesionReducer.user;
})
export const getUid = createSelector(state => state.sesionReducer, sesionReducer => {
    return sesionReducer.uidUser;
})
export const getCargandoUser = createSelector(state => state.sesionReducer, sesionReducer => {
    return sesionReducer.cargnadoUser;
})