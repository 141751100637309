import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SkeletonResponsive from '../../../../general/SkeletonResponsive'

const textoFinal = (textoAuste) => {
    let texto = textoAuste.split("\\n")

    return texto.map((tx, i) => {
        
        return (<span key={`texto-${i}`}>{tx}<br/></span>)
    })
}

const QUIENES_SOMOS = 'quienesSomos'
const QUE_HACEMOS = 'queHacemos'
const QUE_QUEREMOS = 'queQueremos'

const Textos = ({textos, cargando}) => {

    const [seleccionado, setSeleccionado] = useState(QUIENES_SOMOS)

    return (
        <div className="center flex-column contenedor-textos-nosotros">
            <div className="flex jc-space-between ai-center">
                <button 
                    className={`${ seleccionado === QUIENES_SOMOS && 'boton-seleccionado' } base-boton letra-seccion-titulo bold center flex-column boton-nosotros letra-gris`}
                    onClick={()=>setSeleccionado(QUIENES_SOMOS)}
                >
                    Quiénes somos
                    <hr className={ seleccionado === QUIENES_SOMOS && 'hr-seleccionado' }/>
                </button>
                <button  
                    className={`${ seleccionado === QUE_HACEMOS && 'boton-seleccionado' } base-boton letra-seccion-titulo bold center flex-column boton-nosotros letra-gris`}
                    onClick={()=>setSeleccionado(QUE_HACEMOS)}
                >
                    Qué hacemos
                    <hr className={ seleccionado === QUE_HACEMOS && 'hr-seleccionado' }/>
                </button>
                <button  
                    className={`${ seleccionado === QUE_QUEREMOS && 'boton-seleccionado' } base-boton letra-seccion-titulo bold center flex-column boton-nosotros letra-gris`}
                    onClick={()=>setSeleccionado(QUE_QUEREMOS)}
                >
                    Qué queremos
                    <hr className={ seleccionado === QUE_QUEREMOS && 'hr-seleccionado' }/>
                </button>
            </div>
            <p className="letra-secciones bold-light letra-gris-claro">
                {cargando && <SkeletonResponsive tipo='text' className="sk-parrafo"/>}
                {textoFinal(textos[seleccionado]??'')}
            </p>
        </div>
    )
}

Textos.propTypes = {
    textos: PropTypes.object.isRequired,
    cargando: PropTypes.bool.isRequired,
}

export default Textos
