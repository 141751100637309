import React from 'react'
import PropTypes from 'prop-types'

const ImagenCircular = ({imagen, completa, className}) => {
    return (
        <div className={`center imagen-circular ${className && className}`}>
            <img src={imagen} className={completa && 'completa'} alt="imagen sección"/>
        </div>
    )
}

ImagenCircular.propTypes = {
    imagen: PropTypes.string.isRequired,
    completa: PropTypes.bool.isRequired,
    className: PropTypes.string,
}

export default ImagenCircular
