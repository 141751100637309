import React from 'react'
import { RiseLoader } from 'react-spinners';

const LoadingIndicator = ({color}) => {
    return (
        <div className='loading-indicator'>
            <RiseLoader
                size={15}
                color={color}/>
        </div>
    )
}

export default LoadingIndicator
