import firebase from './Firebase'
import {OK, ERROR} from '../utilidades/Constantes'

// const functions = firebase.functions()
const functions = firebase.functions()
// Utilizar emulador local
//  functions.useFunctionsEmulator("http://localhost:5001")


export const registroTerapeuta = async(datos) => {
    try {
        let registrar = functions.httpsCallable('registrarPsicologo')
        let respuesta  = await registrar(datos)
        return respuesta.data === 'FALLA' ? ERROR : OK;
    } catch (error) {
        console.log(error)
        return ERROR
    }
}

export const eliminarUsuario = async (data) => {
    try {
        let request = functions.httpsCallable('eliminarUsuario')
        let res = await request(data)
        return res.data
    } catch (error) {
        return {
            estado: ERROR,
            mensaje: "Hubo un inconveniente, inténtalo de nuevo más tarde"
        }
    }
}

export const funcionEliminarTerapia = async (data) => {
    try {
        let request = functions.httpsCallable('eliminarTerapia')
        let res = await request(data)
        return res.data
    } catch (error) {
        return {
            estado: ERROR,
            mensaje: "Hubo un inconveniente eliminando la terapia, inténtalo de nuevo más tarde"
        }
    }
}

export const funcionRechazarPaciente = async (data) => {
    try {
        let request = functions.httpsCallable('rechazarPaciente')
        let res = await request(data)
        return res.data
    } catch (error) {
        return {
            estado: ERROR,
            mensaje: "Hubo un inconveniente, inténtalo de nuevo más tarde"
        }
    }
}

export const funcionEnviarCorreo = async (data) => {
    try {
        let request = functions.httpsCallable('enviarCorreo')
        // {
        //     tipo: TAREA_ASIGNADA,
        //     email: 'nicolas.12.aguilar@gmail.com',
        //     // nombreUsuario: 'Nicolás Aguilar',
        //     // esPaciente: false,
        //     nombrePaciente: 'Nicolás Aguilar',
        //     aprobado: false,
        //     nombrePsicologo: 'Edgardo',
        //     nombreTerapia: 'Te ayudamos ya!',
        //     nombreTarea: 'Es la tarea propia',
        //     fechaHora: '05 de Diciembre de 2020 - 14:00 a 16:00',
        //     enlace: 'www.youtube.com'
        // }
        let res = await request(data)
        return res.data
    } catch (error) {
        return {
            estado: ERROR,
            mensaje: "Hubo un inconveniente, inténtalo de nuevo más tarde"
        }
    }
}

export const prueba = async() => {
    try {
        let registrar = functions.httpsCallable('helloWorld')
        let respuesta  = await registrar()
        console.log(respuesta.data)
        return OK
    } catch (error) {
        console.log(error)
        return ERROR
    }
}