import React, { useContext, useEffect, useState } from 'react';
import SkeletonResponsive from "../../../../../../general/SkeletonResponsive";
import { getPacienteActual, getTareaActual } from "../../../../../../../reducers/terapeutaReducer";
import { bindActionCreators } from "redux";
import {
    consultarPerfilPaciente, consultarTareaActividades, revisarTareaPaciente, updateOrderActiveActivity,
} from "../../../../../../../actions/terapeutaActions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { CargandoContext } from '../../../../../../general/CargandoContext/CargandoContext';
import { getUserInfo } from '../../../../../../../reducers/sesionReducer';
import DialogoDosBotones from './../../../../../../general/DialogoDosBotones';
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import TextoInterlineado from '../../../../../../general/TextoInterlineado';

function RevisarTarea({ paciente, tarea, consultarPerfilPaciente, consultarTareaActividades, match, history, user, administrador }) {
    let ready = paciente && tarea && tarea.actividades

    const [buscandoTarea, setBuscandoTarea] = useState(false)
    const [verificarRevision, setVerificarRevision] = useState(false)

    let { idPaciente, idTerapiaActiva, idTarea } = match.params

    let { correrIndicadorCarga, quitarIndicadorCarga } = useContext(CargandoContext)


    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if ((!paciente || (paciente && !paciente.correo))) {
            consultarPerfilPaciente({
                idPaciente
            })
        }
        if (paciente && paciente.terapiasActivas && !buscandoTarea && (!tarea || (tarea && !tarea.actividades))) {
            setBuscandoTarea(true)
            consultarTareaActividades({
                idPaciente,
                idTerapiaActiva,
                idTarea
            })
        }
    })

    const pintarActividades = (actividades) => (
        actividades.sort((a, b) => {
            if ((a.index ?? 0) > (b.index ?? 0)) return -1
            return 1
        }).map((actividad, i) => {
            switch (actividad.tipo) {
                case 1:
                    return <div className="con-actividad" key={actividad.uidDoc}>
                        <p className="entregado">
                            <input className='input-index' type="number" placeholder='0' defaultValue={actividad.index ?? 0}
                                onBlur={async (e) => {
                                    const value = parseInt(e.target.value ?? 0);
                                    await updateOrderActiveActivity(
                                        idTerapiaActiva,
                                        idTarea,
                                        (actividad.uidDocumento || actividad.uidDoc),
                                        value
                                    )
                                }}
                            /><br />
                            {actividad.entregado ? "Entregado" : "Pendiente"}</p>
                        <div className="content">
                            <TextoInterlineado
                                className="descripcion"
                                texto={actividad.descripcion}
                                numero={i + 1}
                            />
                            {pintarRecursos(actividad.recursos ?? [])}
                        </div>
                    </div>
                case 2:
                    return <div className="con-actividad" key={actividad.uidDoc}>
                        <p className="entregado">{actividad.entregado ? "Entregado" : "Pendiente"}</p>
                        <div className="content">
                            <TextoInterlineado
                                className="descripcion"
                                texto={actividad.descripcion}
                                numero={i + 1}
                            />
                            {pintarRecursos(actividad.recursos ?? [])}
                            {actividad.respuesta && <div className="contenedor-respuesta">
                                <p className="respuesta">{actividad.respuesta}</p>
                            </div>}
                        </div>
                    </div>
                case 3:
                    return <div className="con-actividad" key={actividad.uidDoc}>
                        <p className="entregado">{actividad.entregado ? "Entregado" : "Pendiente"}</p>
                        <div className="content">
                            <TextoInterlineado
                                className="descripcion"
                                texto={actividad.descripcion}
                                numero={i + 1}
                            />
                            {pintarRecursos(actividad.recursos ?? [])}
                            {actividad.archivoEntrega && <div className="fila-archivo">
                                <h3 className="nombre-archivo">{actividad.archivoEntrega.nombre}</h3>
                                <a className="descargar" href={actividad.archivoEntrega.url} target="_blank" rel="noopener noreferrer">Descargar</a>
                            </div>}
                        </div>
                    </div>
                default:
                    return <div />
            }
        }
        )
    )

    const handleRevisarTarea = async (confirmado) => {

        let actividadesEntregadas = true

        tarea.actividades.forEach(actividad => actividadesEntregadas &= actividad.entregado)

        if (confirmado || actividadesEntregadas) {
            setVerificarRevision(false)
            correrIndicadorCarga()
            await revisarTareaPaciente({
                idTerapiaActiva,
                idTarea,
                idPaciente,
                idPsicologo: user.uidDocumento
            })
            quitarIndicadorCarga()
            history.push(`/terapeuta/pacientes/${idPaciente}/terapia/${idTerapiaActiva}`);
        } else {
            setVerificarRevision(true)
        }

    }

    const pintarRecursos = (recursos) => (
        recursos.map((recurso, j) => {
            switch (recurso.tipo) {
                case 0: {
                    return <div className="recursos">
                        <div className="info-video">
                            <div className="archivos">
                                <h3 className="nombre-archivo">{recurso.nombre}</h3>
                                <p>{recurso.descripcion}</p>
                            </div>
                            <a className="descargar-archivo" href={recurso.url} rel="noopener noreferrer" target="_blank">Descargar</a>
                        </div>
                    </div>
                }
                case 1: {
                    // let linkCortado = recurso.url.split('/')
                    // let videoId = linkCortado[linkCortado.length - 1]
                    return <div className="recursos" key={`recurso-${j}`}>
                        <a className="link" rel="noopener noreferrer" href={recurso.url} target="_blank">{recurso.url && recurso.url.includes("youtu") ? "Ver desde YouTube" : "Descargar video"}</a>
                        <div className="info-video">
                            <ReactPlayer url={recurso.url} width="100%" height="22em" />
                            {/* <iframe
                                title="Video"
                                className="video"
                                src={`https://www.youtube.com/embed/${videoId}`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            /> */}
                            <div className="datos-video">
                                <h3 className="titulo-video">{recurso.nombre}</h3>
                                <p>{recurso.descripcion}</p>
                            </div>
                        </div>
                    </div>
                }
                case 2: {
                    return <div className="recursos" key={`recurso-${j}`}>
                        <a className="link" href={recurso.url} rel="noopener noreferrer" target="_blank">Ver imagen</a>
                        <div className="info-video">
                            <img className="video" src={recurso.url}
                                alt={recurso.nombre}
                            />
                            <div className="datos-video">
                                <h3 className="titulo-video">{recurso.nombre}</h3>
                                <p>{recurso.descripcion}</p>
                            </div>
                        </div>
                    </div>
                }
                default:
                    return <div />
            }
        })
    )

    return (
        <div className="contenedor-revision-actividades">
            <div className="fila-atras" onClick={() => {
                history.push(`/terapeuta/pacientes/${idPaciente}/terapia/${idTerapiaActiva}`);
            }}>
                {ready ? <button className="btn-arketipos btn-terapeuta btn-atras">←</button>
                    : <SkeletonResponsive tipo={'rect'} className='sk-btn-atras' />}
                {ready ? <h5 className="texto-atras">{`${tarea?.nombre} - ${paciente.nombre}`}</h5>
                    : <SkeletonResponsive tipo={'rect'} className='sk-texto-atras' />}
            </div>
            <div className="titulo-terapia">
                {ready ? <h1 className="nombre-terapia">{"La terapia"}</h1>
                    : <SkeletonResponsive tipo={'rect'} className='sk-titulo-terapias' />}
            </div>
            <div className="contenedor-actividades">
                {ready ?
                    tarea.actividades.length > 0 ? pintarActividades(tarea.actividades)
                        :
                        <div className="sin-actividades">
                            <p className="texto">No tienes actividades</p>
                        </div>
                    : <div>
                        <SkeletonResponsive tipo={'rect'} className='sk-actividad' />
                        <SkeletonResponsive tipo={'rect'} className='sk-actividad' />
                        <SkeletonResponsive tipo={'rect'} className='sk-actividad' />
                    </div>
                }
            </div>
            {
                !tarea?.finalizada &&
                <button
                    className="btn-terapeuta btn-arketipos btn-revisada"
                    onClick={() => handleRevisarTarea(false)}>
                    Marcar como revisada
                </button>

            }
            {
                verificarRevision &&
                <DialogoDosBotones
                    titulo='¿Está seguro de marcar la tarea como revisada?'
                    descripcion='El paciente todavía no ha entregado todas las actividades'
                    boton1='Si'
                    boton2='Cancelar'
                    cerrar={() => setVerificarRevision(false)}
                    click1={() => handleRevisarTarea(true)}
                    click2={() => setVerificarRevision(false)}
                    color='naranja' />
            }
        </div>
    );
}


RevisarTarea.defaultValues = {
    administrador: false
};

RevisarTarea.propTypes = {
    administrador: PropTypes.bool,
};

const mapStateToProps = (state, props) => ({
    paciente: getPacienteActual(state, props.match.params.idPaciente),
    tarea: getTareaActual(state, props.match.params.idPaciente, props.match.params.idTerapiaActiva, props.match.params.idTarea),
    user: getUserInfo(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    consultarPerfilPaciente: consultarPerfilPaciente,
    consultarTareaActividades: consultarTareaActividades,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RevisarTarea));