import React, {useState, useContext, useEffect} from 'react'
import PropTypes from 'prop-types'
import DialogoAdjuntar from './DialogoAdjuntar';
import DialogoUnBoton from '../../../general/DialogoUnBoton'
import { CARGANDO, ERROR, OK, CANCELAR} from '../../../../utilidades/Constantes';
import { CargandoContext } from '../../../general/CargandoContext/CargandoContext';

const NuevaActividad = ({index, actividadRecibida, estadoCreacion, editar, guardar, finalizarCreacion}) => {

    const [actividad, setActividad] = useState(actividadRecibida)
    const [adjuntar, setAdjuntar] = useState(false)
    let { correrIndicadorCarga, quitarIndicadorCarga } = useContext(CargandoContext)

    const agregarAdjuntos = (archivo, tipo, adjunto) =>{
        let recursos = actividad.recursos??[]
        recursos.push({archivo: archivo, tipo: tipo, adjunto: adjunto})
        let act = {...actividad, recursos: recursos}
        setActividad(act)
        setAdjuntar(false)
    }

    useEffect(() => {
        if(estadoCreacion === CARGANDO){
            correrIndicadorCarga()
        }
        if(estadoCreacion === OK){
            quitarIndicadorCarga()
            finalizar()
        }
        if(estadoCreacion === ERROR) quitarIndicadorCarga()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estadoCreacion])

    const eliminarAdjunto = (index) =>{
        let recursos = actividad.recursos??[]
        recursos.splice(index, 1)
        setActividad({...actividad, recursos: recursos})
    }

    const finalizar = () => {
        finalizarCreacion()
        guardar({preventDefault:()=>{}}, CANCELAR)
    }

    const mostrarDialogo = (tipo) =>{
        
        if(tipo === ERROR){
            return <DialogoUnBoton
                titulo='Error'
                descripcion={
                    editar?'Parece que ha ocurrido un error al actualizar la actividad.':
                    'Parece que ha ocurrido un error al crear la actividad.'
                }
                boton1='Listo' 
                click1={finalizar}
                cerrar={finalizar}
                color='naranja'
            />
        }
        return ''
    }

    return (
        <form className="con-tareas contenedor-actividades" onSubmit={(e)=>guardar(e, actividad)}>
            {
                adjuntar?
                <DialogoAdjuntar
                    cerrar={()=>setAdjuntar(false)}
                    guardarRecurso={agregarAdjuntos}
                />:''
            }
            {mostrarDialogo()}
            <div className="escribir-descripcion">
                <span>{index+1}.</span>
                <textarea placeholder="Descripción de la actividad"
                    autoFocus
                    onChange={(e)=>setActividad({...actividad, descripcion: e.target.value.replaceAll('\n', '\\n')})}
                    value={(actividad.descripcion??'').replaceAll('\\n', '\n')} required
                >
                </textarea>
            </div>
            <div className="botones-crear-actividad">
                <div className="contenedor-adjuntar">
                    <button
                        type="button"
                        className="btn-arketipos fondo-borde-naranja btn-adjuntar" 
                        onClick={()=>setAdjuntar(true)}
                    >
                        Adjuntar
                    </button>
                    <div className="adjuntos">
                        {(actividad.recursos??[]).map((r, i)=>{

                            if(editar)
                                return <div key={`rec-${i}`}>
                                    <span>{r.nombre??'adjunto'}</span>
                                    <button className="circulo-x" onClick={()=>eliminarAdjunto(i)}>x</button>
                                </div>

                            if(r.adjunto)
                                return <div key={`rec-${i}`}>
                                    <span>{r.archivo.name}</span>
                                    <button className="circulo-x" onClick={()=>eliminarAdjunto(i)}>x</button>
                                </div>
                            else
                                return <div key={`rec-${i}`}>
                                    <span>{r.archivo}</span>
                                    <button className="circulo-x" onClick={()=>eliminarAdjunto(i)}>x</button>
                                </div>
                        })}
                    </div>
                </div>
                <button 
                    className="btn-arketipos fondo-borde-naranja btn-actividad" 
                    type="submit"
                >
                    Guardar
                </button>
                <button 
                    className="btn-arketipos btn-actividad" 
                    type="button"
                    onClick={(e)=>guardar(e, CANCELAR)}
                >
                    Cancelar
                </button>
            </div>
        </form>
    )
}

NuevaActividad.propTypes = {
    index: PropTypes.number.isRequired,
    actividadRecibida: PropTypes.object.isRequired,
    editar: PropTypes.bool.isRequired, 
    guardar: PropTypes.func.isRequired,
    estadoCreacion: PropTypes.any,
    finalizarCreacion: PropTypes.func.isRequired,
}

export default NuevaActividad
