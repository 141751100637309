import firebase from 'firebase'

const config = {
  apiKey: "AIzaSyD_ugziGi5VNKTEhHQ6N3tbo-k_7abhvoo",
  authDomain: "laberint-1e315.firebaseapp.com",
  databaseURL: "https://laberint-1e315.firebaseio.com",
  projectId: "laberint-1e315",
  storageBucket: "laberint-1e315.appspot.com",
  messagingSenderId: "251378117091",
  appId: "1:251378117091:web:434cde9addc44cd23b58d6",
  measurementId: "G-LXW15V9D3V"
};
firebase.initializeApp(config);
// firebase.firestore().settings(settings);
export default firebase;