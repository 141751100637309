import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DropdownSelector from '../../../general/dropdownSelector/DropdownSelector';
import { CargandoContext } from '../../../general/CargandoContext/CargandoContext';
import * as actions from '../../../../actions/adminActions';
import { ERROR } from '../../../../utilidades/Constantes';

class DialogoAsignar extends Component {
    
    state = {
        dropdown_visible: false,
        seleccion_actual: "",
        error: null
    }

    dialogoRef = React.createRef();


    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.dialogoRef && !this.dialogoRef.current.contains(event.target)) {
            this.props.cerrarDialogoHandleClick()
        }
    }

    dropdownHandleClick = () => {
        this.setState({
            dropdown_visible: !this.state.dropdown_visible
        })
    }

    selectionHandleClick = (selection) => {
        this.setState({ seleccion_actual: selection, dropdown_visible: false })
    }

    asignarPsicologoPaciente = async ({ correrIndicadorCarga, quitarIndicadorCarga }) => {
        const psicologoSeleccionado = this.props.psicologos.find(psicologo => psicologo.nombre === this.state.seleccion_actual);

        this.setState({error: null})
        correrIndicadorCarga()
        let res = await this.props.asignarTerapeuta({
            paciente: this.props.paciente, 
            psicologo: psicologoSeleccionado
        })
        quitarIndicadorCarga()

        if(res === ERROR)
            this.setState({
                error: "Ocurrio un error, por favor intentalo más tarde"
            })
        else
            this.props.cerrarDialogoHandleClick()

    }

    render() {
        let psicologosActuales = this.props.paciente.psicologos.map(psicologo => psicologo.uidDoc)
        let psicologosDisponibles = this.props.psicologos.filter(psicologo => !psicologosActuales.includes(psicologo.uidDoc))

        return (
            <CargandoContext.Consumer>
                { value => <div className='fondo-dialogo' style={{ display: this.props.visible ? "grid" : "none" }}>
                    <div className='dialogo-asignar-paciente' ref={this.dialogoRef}>
                        <div className='dialogo-titulo'>
                            Asignar terapeuta a paciente
                            <hr className='linea-dialogo' />
                        </div>
                        {
                            psicologosDisponibles.length !== 0 ?
                                <div className='contenido-dialogo-asignar-paciente'>
                                    <div className='datos-dialogo-asignar'>
                                        <div className='dialogo-field'>
                                            <b>Paciente:</b> {this.props.paciente.nombre}
                                        </div>
                                        <div className='dialogo-field elegir-terapeuta'>
                                            <b>Elegir terapeuta:</b>
                                        </div>
                                        <DropdownSelector
                                            estilo='administrador'
                                            value={this.state.seleccion_actual}
                                            selectionHandleClick={this.selectionHandleClick}
                                            placeholder={'Seleccionar terapeuta'}
                                            opciones={psicologosDisponibles.map(psicologo => psicologo.nombre)}/>
                                    </div>
                                    {
                                        this.state.error &&
                                        <div className='error'>
                                            {this.state.error}
                                        </div>
                                    }
                                    <div className='btns-dialogo-asignar'>
                                        <button className='btn-arketipos btn-borde-negro' onClick={this.props.cerrarDialogoHandleClick}>Cancelar</button>
                                        {
                                            this.state.seleccion_actual&&
                                            <button className='btn-arketipos btn-azul' onClick={() => this.asignarPsicologoPaciente(value)}>Asignar</button>
                                        }
                                    </div>
                                </div> :
                                <div>
                                    <div className="no-hay-psicologos">
                                        No hay más psicólogos por asignar
                                    </div>
                                    <div className='btns-dialogo-asignar-no-hay'>
                                        <button id='btn-de-acuerd-asignar' className='btn-arketipos btn-azul' onClick={this.props.cerrarDialogoHandleClick}>De acuerdo</button>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                }
            </CargandoContext.Consumer>
        );
    }
}
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(null, mapDispatchToProps)(DialogoAsignar);