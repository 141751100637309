import React, { useState, useRef, useEffect, useCallback, useContext } from 'react';
import DropdownSelector from '../../../general/dropdownSelector/DropdownSelector';
import { formatDate } from '../../../../utilidades/fechas';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../../actions/terapeutaActions';
import { CargandoContext } from './../../../general/CargandoContext/CargandoContext';
import { ERROR } from '../../../../utilidades/Constantes';

const DialogoCita = ({ pacientes, crearCita, editarCita, cerrarDialogoHandleClick, citaEdicion }) => {

    let [pacienteSeleccionado, setPacienteSeleccionado] = useState('')
    let [url, setUrl] = useState('')
    let [fecha, setFecha] = useState(formatDate(new Date()))
    let [horaInicio, setHoraInicio] = useState('14:00')
    let [horaFin, setHoraFin] = useState('16:00')
    let [error, setError] = useState(null)

    let { correrIndicadorCarga, quitarIndicadorCarga } = useContext(CargandoContext)

    let dialogoRef = useRef()

    let valoresEditados

    if (citaEdicion)
        valoresEditados = url !== citaEdicion.link ||
            pacienteSeleccionado !== pacientes.find(paciente => paciente.uidDoc === citaEdicion.idPaciente).nombre ||
            fecha !== citaEdicion.fecha || horaInicio !== citaEdicion.inicio || horaFin !== citaEdicion.fin

    const handleClickOutside = useCallback(event => {
        if (dialogoRef && !dialogoRef.current.contains(event.target))
            cerrarDialogoHandleClick()
    }, [cerrarDialogoHandleClick]
    )

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        if (citaEdicion) {
            setPacienteSeleccionado(pacientes.find(paciente => paciente.uidDoc === citaEdicion.idPaciente).nombre)
            setUrl(citaEdicion.link)
            setFecha(citaEdicion.fecha)
            setHoraInicio(citaEdicion.inicio)
            setHoraFin(citaEdicion.fin)
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }

    }, [handleClickOutside, citaEdicion, pacientes])

    const handlePacienteSeleccionado = (pacienteSeleccionado) => setPacienteSeleccionado(pacienteSeleccionado)

    const handleClickCrearCita = async () => {
        debugger
        let paciente = pacientes.find(paciente => paciente.nombre === pacienteSeleccionado)
        setError(null)
        correrIndicadorCarga()
        let res = await crearCita({
            paciente,
            url,
            fecha,
            horaInicio,
            horaFin
        })
        quitarIndicadorCarga()
        if (res === ERROR)
            setError('Ocurrio un error, intentalo de nuevo más tarde')
        else
            cerrarDialogoHandleClick()
    }

    const handleClickEditarCita = async () => {
        let paciente = pacientes.find(paciente => paciente.nombre === pacienteSeleccionado)
        setError(null)
        correrIndicadorCarga()
        let res = await editarCita({
            id: citaEdicion.uidDoc,
            paciente: paciente.uidDoc,
            url,
            fecha,
            horaInicio,
            horaFin
        });
        quitarIndicadorCarga()
        if (res === ERROR)
            setError('Ocurrio un error, intentalo de nuevo más tarde')
        else
            cerrarDialogoHandleClick()
    }

    const establecerHoraInicio = (horaInicio) => {
        if (horaInicio > horaFin)
            setHoraFin(horaInicio)

        setHoraInicio(horaInicio)
    }

    const establecerHoraFin = (horaFin) => {
        if (horaFin < horaInicio)
            setHoraInicio(horaFin)

        setHoraFin(horaFin)
    }

    const handleCerrarDialogo = () => {
        cerrarDialogoHandleClick()
    }

    return (
        <div className='fondo-dialogo'>
            <div className='dialogo-crear-cita' ref={dialogoRef}>
                <div className='contenido-dialogo-crear-cita'>
                    <div className='titulo-dialogo-crear-cita'>
                        {
                            citaEdicion ?
                                "Edición de cita" :
                                "Agenda una cita"
                        }
                        <hr />
                    </div>
                    <div className='dialogo-crear-cita-field'>
                        <div className='titulo-dialogo-crear-cita-field'>Eligir un paciente</div>
                        <DropdownSelector
                            estilo='terapeuta'
                            value={pacienteSeleccionado}
                            selectionHandleClick={handlePacienteSeleccionado}
                            placeholder={'Seleccionar paciente'}
                            opciones={pacientes.map(paciente => paciente.nombre)} />
                    </div>
                    <div className='dialogo-crear-cita-field'>
                        <div className='titulo-dialogo-crear-cita-field'>Link de la videollamada</div>
                        <input className='dialogo-crear-cita-input' type='text' value={url}
                            onChange={(e) => setUrl(e.target.value)}></input>
                    </div>
                    <div className='dialogo-crear-cita-field'>
                        <div className='titulo-dialogo-crear-cita-field'>Fecha</div>
                        <input
                            className='dialogo-crear-cita-input'
                            min={formatDate(new Date())}
                            type='date' value={fecha}
                            onChange={(e) => setFecha(e.target.value)}></input>
                    </div>
                    <div className='dialogo-crear-cita-horas'>
                        <div className='componente-hora-dialogo-crear-cita'>
                            <div className='titulo-dialogo-crear-cita-field'>Hora de inicio</div>
                            <input type="time" className='hora-input-dialogo-crear-cita'
                                value={horaInicio}
                                max={horaFin}
                                onChange={(e) => establecerHoraInicio(e.target.value)}></input>
                        </div>
                        <div className='componente-hora-dialogo-crear-cita'>
                            <div className='titulo-dialogo-crear-cita-field'>Hora de fin</div>
                            <input type="time" className='hora-input-dialogo-crear-cita'
                                value={horaFin}
                                max={horaInicio}
                                onChange={(e) => establecerHoraFin(e.target.value)}></input>
                        </div>
                    </div>
                    <div className='botones-dialogo-crear-cita'>
                        <button className='btn-arketipos btn-cancelar-dialogo-crear-cita' onClick={() => handleCerrarDialogo()}>Cancelar</button>
                        {
                            citaEdicion ?
                                valoresEditados &&
                                <button
                                    className='btn-arketipos btn-crear-dialogo-crear-cita'
                                    onClick={handleClickEditarCita}>Editar</button> :
                                url !== '' && pacienteSeleccionado !== '' &&
                                <button
                                    className='btn-arketipos btn-crear-dialogo-crear-cita'
                                    onClick={handleClickCrearCita}>Crear</button>
                        }
                    </div>
                    {
                        error &&
                        <div className='error'>
                            {error}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(null, mapDispatchToProps)(DialogoCita);