import React, { useContext } from 'react'
import PerfilImg from './../../../../assets/user.svg'
import FechaImg from './../../../../assets/nombramiento.svg'
import UbicacionImg from './../../../../assets/ubicacion-del-mapa.svg'
import CopyImg from './../../../../assets/copy.svg'
import BasuraImg from './../../../../assets/ic_basura.svg'
import CopyToClipboard from 'react-copy-to-clipboard'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../../../actions/terapeutaActions';
import { CargandoContext } from '../../../general/CargandoContext/CargandoContext'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
  "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];

const ItemCita = ({ cita, eliminarCita, mostrarDialogoEditarCita, usuario }) => {

  let { nombrePaciente, nombrePsicologo, fecha, link, inicio, fin, uidDoc } = cita

  let { correrIndicadorCarga, quitarIndicadorCarga } = useContext(CargandoContext)

  const handleEliminarCita = async () => {
    correrIndicadorCarga()
    await eliminarCita({ uidDoc })
    quitarIndicadorCarga()
  }

  return (
    <div className={`contenedor-cita contenedor-cita-${usuario}`}>
      <img className='img img-fecha' src={FechaImg} alt="Imagen de la fecha de la cita" />
      <div className='fecha-cita'>
        <b>{`${fecha.split("-")[2]} de ${meses[((parseInt(fecha.split("-")[1])) - 1)]} de ${fecha.split("-")[0]} - `}</b>{`${inicio} a ${fin}`}
      </div>
      {
        usuario === 'terapeuta' &&
        <>
          <div className='editar-cita' onClick={() => mostrarDialogoEditarCita(cita)}>Editar</div>
          <img className='img img-link' src={UbicacionImg} alt="Imagen del enlace" />
        </>
      }
      <div className={`link-cita link-cita-${usuario}`}>
        {
          usuario === 'paciente' &&
          <img className={`img`} src={UbicacionImg} alt="Imagen del enlace" />
        }
        <span><b>Link: </b>{link}</span>
        <CopyToClipboard text={link}>
          <img className='img-copiar' src={CopyImg} alt="Imagen para copiar en el portapapeles" onClick={() => {
            toast.dark(`Copiado ${link}`, {
              position: "bottom-center",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }} />
        </CopyToClipboard>
      </div>
      <img className='img img-perfil-cita' src={PerfilImg} alt="Imagen de perfil" />
      {
        usuario === 'terapeuta' &&
        <div className='eliminar' onClick={handleEliminarCita}>
          <img className='img-eliminar' src={BasuraImg} alt='Icono eliminar' />
          Eliminar
        </div>
      }
      <b className='nombre-paciente-cita'>
        {
          usuario === 'terapeuta' ?
            nombrePaciente :
            nombrePsicologo
        }
      </b>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(null, mapDispatchToProps)(ItemCita);