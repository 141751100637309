import React, { useState } from 'react';

const DropdownSelector = ({ opciones, value, placeholder, estilo, selectionHandleClick }) => {

    let [ dropdownVisible, setDropdownVisible ] = useState(false)

    const dropdownHandleClick = () => setDropdownVisible(!dropdownVisible)

    return (
        <div style={{position: 'relative'}}>
            <div className="custom-select" onClick={dropdownHandleClick}>
                <div className='seleccion-actual'>
                    {value !== "" ? value : placeholder}
                </div>
                <div className={`flecha-decoracion ${estilo}`}>
                    &darr;
                </div>
            </div>
            {
                dropdownVisible &&
                <div className='lista-opciones'
                    style={{ height: `${Math.min(5, opciones.length * 2)}em` }}>
                    <ul className='ul-opciones'>
                        {
                            opciones.map(opcion => (
                                <li
                                    key={opcion}
                                    className={estilo}
                                    claskey={opcion}
                                    onClick={() => {
                                    selectionHandleClick(opcion)
                                    setDropdownVisible(false)
                                }}>{opcion}</li>
                            ))
                        }
                    </ul>
                </div>
            }
        </div>
    );
}

export default DropdownSelector;