import React, {Component} from 'react'
import Header from '../general/HeaderApp'
import './registro.sass'
import DialogoUnBoton from '../general/DialogoUnBoton'
import Loading from '../general/Loading'
import { ERROR, OK } from '../../utilidades/Constantes'
import {registrarTerapeuta, pruebaFunctions} from '../../actions/sesionActions'
import { Redirect } from 'react-router-dom'


class RegistroTerapeuta extends Component{

    state={
        nombre:'',
        correo:'',
        telefono:'',
        password:'',
        passwordConfirm:'',
        descripcion:'',
        cargando: false,
        dialogo: 0,
        registroFinalizado: false
    }

    handleChange=(e)=>{
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    handleSubmit= async(e)=>{
        e.preventDefault()

        if(this.state.password !== this.state.passwordConfirm){
            this.setState({
                dialogo: 3
            })
            return
        }

        this.setState({
            cargando: true
        })
        let r = await registrarTerapeuta(this.state)
        this.setState({
            cargando: false,
        })
        if(r === OK){
            this.setState({
                dialogo: 1,
                nombre:'',
                correo:'',
                telefono:'',
                password:'',
                passwordConfirm:'',
                descripcion:'',
                cargando: false,
            })
        }
        if(r === ERROR){
            this.setState({
                dialogo: 2
            })
        }
    }

    cerrarDialogo = ()=>{
        this.setState({dialogo:0})
    }

    abrirDialogo = (tipo) =>{
        let titulo
        let descripcion
        let funcion
        
        if(tipo === 1){
            titulo="¡El registro fue exitoso!"
            descripcion="Ya está registrado el psicólogo, ahora se le enviará una notificación al a su correo."
            funcion = this.finalizarRegistro
        }

        if(tipo === 2){
            titulo="Algo falló"
            descripcion="Parece que algo está fallando con el registro, inténtalo más tarde."
            funcion = this.cerrarDialogo
        }

        if(tipo === 3){
            titulo="Las contraseñas no coinciden"
            descripcion="Las contraseñas que ingresaste no son iguales, inténtalo nuevamente."
            funcion = this.cerrarDialogo
        }
        return this.mostrarDialogo(titulo, descripcion, funcion)
    }

    mostrarDialogo = (titulo, descripcion, funcion) =>{
        return <DialogoUnBoton
            titulo={titulo}
            descripcion={descripcion}
            boton1="Entendido"
            cerrar={funcion}
            click1={funcion}
        />
    }

    finalizarRegistro = () =>{
        this.setState({
            registroFinalizado: true
        })
    }

    hacerPrueba = async() =>{
        let r = await pruebaFunctions()
        console.log(r)
    }

    render(){
        if(this.state.registroFinalizado)
            return <Redirect to="/administrador"/>

        return(
            <div>
                <Header />
                <div className="contenedor-registro">
                    <form onSubmit={this.handleSubmit}>
                        <h1 onClick={this.hacerPrueba}>Registrar psicologo</h1>
                        <input type="text" name="nombre" placeholder="Nombre" onChange={this.handleChange} value={this.state.nombre} required/>
                        <input type="email" name="correo" placeholder="Correo" onChange={this.handleChange} value={this.state.correo} required/>
                        <input type="tel" name="telefono" placeholder="Celular" onChange={this.handleChange} value={this.state.telefono} required/>
                        <input type="password" name="password" placeholder="Crea una contraseña" onChange={this.handleChange} value={this.state.password} required/>
                        <input type="password" name="passwordConfirm" placeholder="Confirma la contraseña" onChange={this.handleChange} value={this.state.passwordConfirm} required/>
                        <textarea name="descripcion" placeholder="Agrega la descripción..." onChange={this.handleChange} value={this.state.descripcion} required/>
                        <button type="submit" className="boton boton-login fondo-azul">Crear cuenta</button>
                    </form>
                </div>
                {this.state.dialogo===0?'':this.abrirDialogo(this.state.dialogo)}
                {this.state.cargando?<Loading />:''}
            </div>
        )
    }
}

export default RegistroTerapeuta