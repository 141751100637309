import React from 'react'
import PropTypes from 'prop-types'

const FotoPerfilColor = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
            <circle class={`cls-1 cls-${color}`} cx="250" cy="250" r="243.96"/>
            <path class="cls-2" d="M250.11,184.1c17,0,30.79-5.13,41.64-15.22q16-15.12,16-38.51c0-15.59-5.33-28.61-16-38.7-10.85-10.09-24.67-15-41.64-15s-30.81,5-41.66,15-16.2,23.11-16.2,38.7,5.33,28.43,16.2,38.51S233.12,184.1,250.11,184.1Z"/>
            <path class="cls-2" d="M250,423.37a110,110,0,0,0,29.56-4c13.91-3.87,25.21-9.67,28.13-15.72,8.64-17.91,3.42-126.5,1.5-149.52a56.46,56.46,0,0,0-4-15.11c-8.58-17.52-34.82-42-55.17-42s-46.59,24.51-55.17,42a56.46,56.46,0,0,0-4,15.11c-1.92,23-7.14,131.61,1.5,149.52,2.92,6.05,14.22,11.85,28.13,15.72A110,110,0,0,0,250,423.37Z"/>
        </svg>
    )
}

FotoPerfilColor.defaultValues = {
    color: 'azul'
}

FotoPerfilColor.propTypes = {
    color: PropTypes.string.isRequired,
}

export default FotoPerfilColor
