import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getPacienteActual } from '../../../../../reducers/terapeutaReducer';
import SkeletonResponsive from '../../../../general/SkeletonResponsive';
import PerfilImg from '../../../../../assets/ic_foto_perfil_naranja.svg';
import * as actions from '../../../../../actions/terapeutaActions';
import { SIN_FOTO } from '../../../../../utilidades/Constantes';

const PerfilPacienteTerapeuta = ({ paciente, consultarPerfilPaciente, setPacientePorAsignarTerapia, match }) => {

    let history = useHistory()

    useEffect(() => {
        if((!paciente || (paciente && !paciente.correo)))
            consultarPerfilPaciente({
                idPaciente: match.params.id
            })
    })

    let datosListos = paciente && paciente.correo && paciente.terapiasActivas

    const handleSetPacientePorAsignarTerapia = () => {
        setPacientePorAsignarTerapia(paciente)
    }

    let terapiasFiltradas = paciente?.terapiasActivas?.filter( terapia => !terapia.finalizada)
    
    return (
        <div className='contenedor-perfil-paciente'>
            <div className='encabezado-perfil-paciente'>
                <div
                    className='path-perfil-paciente'
                    onClick={() => history.push('/terapeuta/pacientes')}>
                    <div className='flecha-decoracion'>
                        <span>&larr;</span>
                    </div>
                    <b>Pacientes</b>
                </div>
                <button 
                    onClick={handleSetPacientePorAsignarTerapia}
                    className='btn-arketipos btn-asignar-terapia'>Asignar Terapia</button>
            </div>
            <div className='perfil-paciente'>
                {
                    datosListos ?
                        <div className='img-nombre-paciente'>
                            <img
                                className='img-paciente'
                                src={
                                    // TODO: Quitar verificacion de null
                                    paciente.urlFotoPerfil ?
                                        paciente.urlFotoPerfil === SIN_FOTO ? PerfilImg : paciente.urlFotoPerfil:
                                        PerfilImg
                                }
                                alt="Imagen de perfil"/>
                            <div className="titulo-perfil-paciente">
                                {paciente.nombre}
                                <hr/>
                            </div>
                        </div>:
                        <div className='img-nombre-paciente'>
                            <SkeletonResponsive tipo='circle' className='sk-img-paciente'/>
                            <SkeletonResponsive tipo='rect' className='sk-titulo-perfil-paciente'/>
                        </div>
                }
                <div className="fila-dato">
                    <p className="campo">Correo:</p>
                    {
                        datosListos ? 
                            <p className="valor">{paciente.correo}</p>:
                            <SkeletonResponsive tipo='rect' className='sk-valor'/>
                    }
                </div>
                <div className="fila-dato">
                    <p className="campo">Celular:</p>
                    {
                        datosListos ? 
                            <p className="valor">{paciente.telefono}</p>:
                            <SkeletonResponsive tipo='rect' className='sk-valor'/>
                    }
                </div>
                <div className="fila-dato">
                    <p className="campo">Descripción:</p>
                    {
                        datosListos ? 
                            <p className="valor">{paciente.descripcion}</p>:
                            <SkeletonResponsive tipo='rect' className='sk-descripcion'/>
                    }
                </div>
                <div className="fila-dato">
                    <p className="titulo-terapias-asignadas">Terapias asignadas:</p>
                    {
                        datosListos ? 
                        terapiasFiltradas.length !== 0 ?
                                <div className='lista-terapias'>
                                    {
                                        terapiasFiltradas
                                        .map( (terapia, index) => (
                                                <div className='terapia' style={{borderBottom: index !==paciente.terapiasActivas.length-1 ? "1px solid #B2B2B2": "none"}}>
                                                    <div className='nombre'>
                                                        {terapia.nombre}
                                                    </div>
                                                    <button
                                                        className='btn-arketipos btn-revisar'
                                                        onClick={() => history.push(`/terapeuta/pacientes/${match.params.id}/terapia/${terapia.uidDoc}`)}>
                                                        Revisar
                                                        {
                                                            terapia.revisar &&
                                                            <div className='circulo-revisar'/>
                                                        }
                                                    </button>
                                                </div>
                                        ))
                                    }
                                </div>:
                                <div>No hay terapias asignadas</div>
                            :
                            <SkeletonResponsive tipo='rect' className='sk-descripcion'/>
                    }
                </div>
                <button 
                    className='btn-arketipos btn-chat'
                    onClick={() => history.push('/terapeuta/chat')}>
                        Chat
                </button>
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => ({
    paciente: getPacienteActual(state, props.match.params.id)
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PerfilPacienteTerapeuta));
