import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {SIN_FOTO} from "../../../../utilidades/Constantes";
import fotoDefault from "../../../../assets/ic_foto_perfil_verde.svg";
import SkeletonResponsive from "../../../general/SkeletonResponsive";

function Resumen({nombre, terapeutas, terapias, history}) {

    const llamarTerapia = (history, terapia) => {
        history.push("/paciente/terapias/" + terapia);
    }

    const pintarTerapeutas = (listaTerapeutas) => {
        return listaTerapeutas.map((terapeuta) =>
            <div className="fila-info" key={terapeuta.uidDoc}>
                <div className={"conte-foto"}>
                    <img className='foto-persona' src={
                        terapeuta.urlFotoPerfil ?
                            terapeuta.urlFotoPerfil === SIN_FOTO ? fotoDefault : terapeuta.urlFotoPerfil :
                            fotoDefault} alt="Imagen Terapeuta"/>
                </div>
                <p className='nombre-terapeuta'>{terapeuta.nombre}</p>
                <button className="btn-arketipos btn-paciente btn-verde" onClick={() => {
                    history.push(`paciente/terapeuta/${terapeuta.uidDoc}`)
                }}>Ver perfil
                </button>
            </div>
        )
    }

    const pintarTerapias = (listaTerapias) => (
        listaTerapias.map((terapia) =>
            <div className="fila-info" key={terapia.uidDoc}>
                <div className="columna-terapias">
                    <p className='nombre-terapia'>{terapia.nombre}</p>
                    <p className="tareas-completadas">Completaste <span
                        className="negrilla">{terapia.tareasCompletadas}</span> de <span
                        className="negrilla">{terapia.numeroTareas}</span> Tareas</p>
                </div>
                <button className="btn-arketipos btn-paciente btn-verde"
                        onClick={() => llamarTerapia(history, terapia.uidDoc)}>Mira tu terapia
                </button>
            </div>
        )
    )

    return (
        <div className="info-paciente">
            {nombre ? <h1 className="titulo-bienvenida">{"¡Hola " + nombre + "!"}</h1>
                : <SkeletonResponsive tipo={'rect'} className='sk-titulo-bienvenida'/>}
            <div className="contenedor-resumen">
                <div className="contenedor-blanco">
                    <div className="espacio-texto">
                        {terapeutas ? <h4 className="subtitulo">Tus terapeutas</h4>
                            : <SkeletonResponsive tipo={'rect'} className='sk-subtitulo'/>}
                        {terapeutas ?
                            terapeutas.length > 0 ? pintarTerapeutas(terapeutas)
                                : <button className="btn-arketipos btn-paciente-sin-datos">
                                    No tienes un terapeuta asignado todavía</button>
                            : <div className='fila-info'>
                                <SkeletonResponsive tipo={'circle'} className='sk-foto-persona'/>
                                <SkeletonResponsive tipo={'rect'} className='sk-nombre-terapeuta'/>
                                <SkeletonResponsive tipo={'rect'} className='sk-btn-verde'/>
                            </div>}
                    </div>
                </div>
                <div className="contenedor-blanco">
                    <div className="espacio-texto">
                        {terapias ? <h4 className="subtitulo">Tus terapias</h4>
                            : <SkeletonResponsive tipo={'rect'} className='sk-subtitulo'/>}
                        {terapias ?
                            terapias.length > 0 ? pintarTerapias(terapias) :
                                <button className="btn-arketipos btn-paciente btn-paciente-sin-datos">
                                    No tienes terapias asignadas todavía</button>
                            : <div className='fila-info'>
                                <div className="columna-terapias">
                                    <SkeletonResponsive tipo={'rect'} className='sk-nombre-terapia'/>
                                    <SkeletonResponsive tipo={'rect'} className='sk-tareas-terapia'/>
                                </div>
                                <SkeletonResponsive tipo={'rect'} className='sk-btn-verde'/>
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}


Resumen.propTypes = {
    nombre: PropTypes.string.isRequired,
    terapeutas: PropTypes.array.isRequired,
    terapias: PropTypes.array.isRequired,
};

export default withRouter(Resumen);