import { editarDocumento, eliminarDocumento, consultarDocumento, consultarColeccionConSubcolecciones, consultarColeccionCampoEspecificoRestricciones, consultarColeccionCampoEspecifico, crearDocumento, consultarColeccion } from '../proxy/FirebaseDB';
import { eliminarUsuario, funcionEnviarCorreo, funcionRechazarPaciente, registroTerapeuta } from '../proxy/FirebaseFunctions';
import { ERROR, OK, PACIENTE_APROBADO_RECHAZADO, PACIENTE_NUEVO, SIN_FOTO, TERAPEUTA_ASIGNADO, TERAPEUTA_REGISTRADO, USUARIO_CONGELADO } from './../utilidades/Constantes';

export const GET_DB_PACIENTES = 'GET_DB_PACIENTES';
export const GET_DB_PSICOLOGOS = 'GET_DB_PSICOLOGOS';
export const GET_DB_PACIENTES_SIN_APROBAR = 'GET_DB_PACIENTES_SIN_APROBAR';

export const GET_PACIENTE_ESPECIFICO_ADMIN = 'GET_PACIENTE_ESPECIFICO_ADMIN';
export const GET_PSICOLOGO_ESPECIFICO_ADMIN = 'GET_PSICOLOGO_ESPECIFICO_ADMIN';
export const GET_DB_TERAPIAS_PACIENTE = 'GET_DB_TERAPIAS_PACIENTE';
export const GET_DB_TAREAS_TERAPIAS_PACIENTE = 'GET_DB_TAREAS_TERAPIAS_PACIENTE';
export const GET_DB_ACTIVIDADES_PACIENTE_ADM = 'GET_DB_ACTIVIDADES_PACIENTE_ADM';


export const getDBUsers = payload => {
    return async dispatch => {
        await Promise.all([
            consultarColeccionConSubcolecciones('pacientes', ['psicologos'], dispatch, GET_DB_PACIENTES),
            consultarColeccionConSubcolecciones('psicologos', ['pacientes'], dispatch, GET_DB_PSICOLOGOS),
            consultarColeccionCampoEspecifico('usuarios', 'aprobado', false, dispatch, GET_DB_PACIENTES_SIN_APROBAR)
        ])
    }
}

export const getPsicologoEspecificoAdmin = payload => {
    return dispatch => {
        consultarDocumento(`psicologos/${payload}`, dispatch, GET_PSICOLOGO_ESPECIFICO_ADMIN);
    }
}

export const updateAdminProfileInfo = payload => {
    return async dispatch => {
        let uidAdmin = payload.uidDocumento;
        delete payload.uidDocumento;

        let res = await editarDocumento(`usuarios/${uidAdmin}`, payload);
        if (res === ERROR)
            return res

        return OK
    }
}

export const deleteUser = async data => {
    return await eliminarUsuario(data)
}

export const rechazarPaciente = async (data, paciente) => {
    let res = await funcionRechazarPaciente(data)
    funcionEnviarCorreo({
        tipo: PACIENTE_APROBADO_RECHAZADO,
        email: paciente.correo,
        nombrePaciente: paciente.nombre,
        aprobado: false
    })
    return res
}

export const traerTerapiasPaciente = payload => {
    return async dispatch => {
        let { paciente, psicologo } = payload;
        consultarColeccionCampoEspecificoRestricciones('terapiasActivas',
            [
                { campo: "idPaciente", valor: paciente },
                { campo: "idPsicologo", valor: psicologo }],
            dispatch, GET_DB_TERAPIAS_PACIENTE, {paciente, psicologo})
    }
}

export const traerTareasTerapiasPaciente = payload => {
    return async dispatch => {
        let { paciente, psicologo, terapia } = payload;
        consultarColeccion(`terapiasActivas/${terapia}/tareas`,
            dispatch, GET_DB_TAREAS_TERAPIAS_PACIENTE, {paciente, psicologo, terapia})
    }
}

export const traerActividadesTareaPaciente = payload => {
    return async dispatch => {
        let { paciente, psicologo, terapia, tarea } = payload;
        consultarColeccion(`terapiasActivas/${terapia}/tareas/${tarea}/actividades`,
            dispatch, GET_DB_ACTIVIDADES_PACIENTE_ADM, {paciente, psicologo, terapia, tarea})
    }
}



export const asignarTerapeuta = payload => {
    return async dispatch => {
        let { paciente, psicologo } = payload;

        // Crear documento en subcoleccion de psicologos
        let res1 = await crearDocumento(`pacientes/${paciente.uidDoc}/psicologos/${psicologo.uidDoc}`, {
            nombre: psicologo.nombre,
            urlFotoPerfil: psicologo.urlFotoPerfil ?? SIN_FOTO
        })

        if (res1 === ERROR)
            return res1

        // Crear documento en subcoleccion de psicologos
        let res2 = await crearDocumento(`psicologos/${psicologo.uidDoc}/pacientes/${paciente.uidDoc}`, {
            nombre: paciente.nombre,
            urlFotoPerfil: paciente.urlFotoPerfil ?? SIN_FOTO,
            estado: PACIENTE_NUEVO,
        })

        if (res2 === ERROR) {
            await eliminarDocumento(`pacientes/${paciente.uidDoc}/psicologos/${psicologo.uidDoc}`)
            return res2
        }

        funcionEnviarCorreo({
            tipo: TERAPEUTA_ASIGNADO,
            email: paciente.correo,
            nombrePaciente: paciente.nombre,
            nombrePsicologo: psicologo.nombre
        })

        await getDBUsers()(dispatch)

        return OK
    }
}

export const activarUsuario = payload => {
    return async dispatch => {
        if (payload.paciente)
            return editarDocumento(`pacientes/${payload.uid}`, { congelado: false });
        else
            return editarDocumento(`psicologos/${payload.uid}`, { congelado: false });
    }
}

export const desactivarUsuario = payload => {
    return async dispatch => {

        let res;
        if (payload.paciente)
            res = await editarDocumento(`pacientes/${payload.uid}`, { congelado: true });
        else
            res = await editarDocumento(`psicologos/${payload.uid}`, { congelado: true });

        if (res === OK)
            funcionEnviarCorreo({
                tipo: USUARIO_CONGELADO,
                email: payload.usuario.correo,
                nombreUsuario: payload.usuario.nombre,
                esPaciente: payload.paciente
            })

        return res
    }
}

export const aprobarPaciente = async paciente => {
    let res = await crearDocumento(`pacientes/${paciente.uidDoc}`, {
        congelado: false,
        nombre: paciente.nombre,
        telefono: paciente.telefono,
        correo: paciente.correo,
        descripcion: paciente.descripcion,
        urlFotoPerfil: SIN_FOTO
    })

    if (res === ERROR)
        return res

    let res2 = editarDocumento(`usuarios/${paciente.uidDoc}`, {
        aprobado: true
    })

    if (res2 === ERROR) {
        await eliminarDocumento(`pacientes/${paciente.uidDoc}`)
        return res2
    }

    funcionEnviarCorreo({
        tipo: PACIENTE_APROBADO_RECHAZADO,
        email: paciente.correo,
        nombrePaciente: paciente.nombre,
        aprobado: true
    })

    return OK
}

export const registrarTreapeuta = async (formulario) => {

    let res = await registroTerapeuta({
        nombre: formulario.nombre,
        correo: formulario.correo,
        telefono: '',
        password: formulario.password,
        descripcion: '',
    })

    funcionEnviarCorreo({
        tipo: TERAPEUTA_REGISTRADO,
        email: formulario.correo,
        nombrePsicologo: formulario.nombre
    })

    return res
}