import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DialogoDosBotones from '../../../general/DialogoDosBotones'

const FormularioIntegrante = ({onSubmit, integrante, nuevo, eliminar}) => {

    const [documento, setDocumento] = useState(nuevo ? integrante :  null)
    const [preguntar, setPregunta] = useState(false)

    const handleChangeHome = (e) => {
        setDocumento({
            ...documento,
            [e.target.name]: e.target.value.replaceAll('\n', '\\n')
        })
    }

    const handleChangeFileHome = (e) => {
        if(e.target.files[0])
            setDocumento({
                ...documento,
                imagenArchivo: e.target.files[0],
                urlAnterior: integrante.foto,
                foto: URL.createObjectURL(e.target.files[0])
            })
    }

    const handleSubmit = (e) => {
        delete documento.uidDoc
        onSubmit(e, integrante.uidDoc, documento)
        setDocumento(null)
    }

    if(documento)
        return (
            <form className="flex flex-column" onSubmit={handleSubmit}>
                <label className="flex flex-column campo-landing">
                    <span>Nombre</span>
                    <input type="text" name="nombre" onChange={handleChangeHome} value={documento.nombre??''} required/>
                </label>
                <label className="flex flex-column campo-landing">
                    <span>Cargo</span>
                    <input type="text" name="cargo" onChange={handleChangeHome} value={documento.cargo??''} required/>
                </label>
                <label className="flex flex-column campo-landing">
                    <span>Correo</span>
                    <input type="email" name="email" onChange={handleChangeHome} value={documento.email??''} required/>
                </label>
                <label className="flex flex-column campo-landing">
                <div className="flex ai-center jc-start">
                        <span className="btn-arketipos fondo-terapeuta center">Seleccionar Imagen</span>
                        <span>{ documento.imagenArchivo ? documento.imagenArchivo.name : 'Sin imagen seleccionada'}</span>
                </div>
                    <input type="file" accept="image/*" name="imagen" onChange={handleChangeFileHome} required={nuevo}/>
                    <br/>
                    <a href={integrante.foto??''} target="noreferrer">Ver Imagen</a>
                </label>
                <div>
                    <button className="btn-arketipos fondo-azul">Guardar Cambios</button>
                    {!nuevo && <button type="button" className="btn-arketipos boton-eliminar" onClick={()=>setDocumento(null)}>Cancelar</button>}
                </div>
                <br/>
                <hr className="separador-landing separador-landing-gris"/>
                <br/>
            </form>
        )
    
    return (
        <div className="flex flex-column">
            {preguntar && <DialogoDosBotones 
                titulo="¿Seguro?"
                descripcion="¿Está seguro de querer eliminar este integrante?"
                boton1="Si"
                boton2="No" 
                click2={()=>setPregunta(false)}
                click1={()=>{
                    eliminar(integrante.uidDoc, integrante)
                    setPregunta(false)
                }}
                cerrar={()=>setPregunta(false)}
            />}
            <div className="flex flex-column campo-landing">
                <span><b>Nombre</b></span>
                <div>{integrante.nombre??''}</div>
            </div>
            <div className="flex flex-column campo-landing">
                <span><b>Cargo</b></span>
                <div>{integrante.cargo??''}</div>
            </div>
            <div className="flex flex-column campo-landing">
                <span><b>Correo</b></span>
                <div>{integrante.email??''}</div>
            </div>
            <div className="flex flex-column campo-landing">
                <span><b>Foto</b></span>
                <br/>
                <a href={integrante.foto??''} target="noreferrer">Ver Imagen</a>
            </div>
            <div>
                <button className="btn-arketipos fondo-azul" onClick={()=>setDocumento(integrante)}>Editar</button>
                {!nuevo && <button type="button" className="btn-arketipos boton-eliminar" onClick={()=>setPregunta(true)}>Eliminar</button>}
            </div>
            <br/>
            <hr className="separador-landing separador-landing-gris"/>
            <br/>
        </div>
    )
}

FormularioIntegrante.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    integrante: PropTypes.object.isRequired,
    nuevo: PropTypes.bool.isRequired,
    eliminar: PropTypes.func.isRequired,
}

export default FormularioIntegrante
