import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { withRouter, useHistory, useParams, useLocation, Redirect } from 'react-router-dom';
import SkeletonResponsive from "../../../general/SkeletonResponsive";
import { traerTerapiasPaciente } from "../../../../actions/adminActions"
import { getTerapiasPaciente, getPacienteTerapias } from "../../../../reducers/adminReducer"

const TerapiasPaciente = ({ terapias, paciente, traerTerapias, esPsicologo }) => {

    const { idTerapeuta, idPaciente } = useParams()
    const history = useHistory();
    let location = useLocation();

    useEffect(() => {
        if (!terapias) {
            traerTerapias({ paciente: idPaciente, psicologo: idTerapeuta })
        }
        // eslint-disable-next-line
    }, terapias)

    const componenteListaTerapias = listaTerapias => (
        listaTerapias.map((terapia, i) => (
            <div onClick={() => {
                history.push(`${location.pathname}/terapias/${terapia.uidDoc}`)
            }} key={`terapia-${i}`}
                className="contenedor-blanco">
                <div className="espacio-texto">
                    <div className="fila-titulo-terapia">
                        {terapia.uidTarea && terapia.tareaActual && <div className="tarea">
                            <p className="estado-terapia">Tarea Pendiente</p>
                            <hr className="subrayado" />
                        </div>}
                        <h4 className="titulo-terapia">{terapia.nombre}</h4>
                    </div>
                    <p className="descripcion-terapia">{terapia.descripcion}</p>
                </div>
            </div>
        ))
    )

    if (!paciente)
        return <Redirect to={esPsicologo ? `/administrador/terapeutas/${idTerapeuta}/pacientes` : `/administrador/pacientes/${idPaciente}`} />

    return (

        <div className="contenedor-terapias">
            <div className="fila-atras" onClick={() => {
                history.goBack();
            }}>
                {terapias && paciente ? <button className="btn-arketipos btn-administrador btn-atras">←</button>
                    : <SkeletonResponsive tipo={'rect'} className='sk-btn-atras' />}
                {terapias && paciente ? <h5 className="texto-atras">{esPsicologo ? `Lista pacientes` : `Perfil ${paciente.nombre}`}</h5>
                    : <SkeletonResponsive tipo={'rect'} className='sk-texto-atras' />}
            </div>
            {esPsicologo && <div className="titulo-perfil-usuario-admin">
                <div className="columna">
                    {paciente.nombre}
                    <hr />
                </div>
                <button className="btn-arketipos btn-administrador btn-perfil-terapias" onClick={() => history.push(`/administrador/pacientes/${paciente.uidDoc}`)}>
                    Perfil
                </button>
            </div>}
            {terapias ?
                terapias.length === 0 ?
                    <div className="sin-terapias">
                        <p className="texto">No tienes ninguna terapia asignada</p>
                    </div> :
                    <div className="lista-terapias">
                        {componenteListaTerapias(terapias)}
                    </div>
                :
                <div className="lista-terapias">
                    <SkeletonResponsive tipo={'rect'} className='sk-contenedor-blanco' />
                    <SkeletonResponsive tipo={'rect'} className='sk-contenedor-blanco' />
                    <SkeletonResponsive tipo={'rect'} className='sk-contenedor-blanco' />
                    <SkeletonResponsive tipo={'rect'} className='sk-contenedor-blanco' />
                    <SkeletonResponsive tipo={'rect'} className='sk-contenedor-blanco' />
                    <SkeletonResponsive tipo={'rect'} className='sk-contenedor-blanco' />
                </div>
            }
        </div>
    )
}

TerapiasPaciente.propTypes = {
    terapias: PropTypes.array,
    traerTerapias: PropTypes.func,
    esPsicologo: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => ({
    terapias: getTerapiasPaciente(state, props.match.params.idPaciente, props.match.params.idTerapeuta),
    paciente: getPacienteTerapias(state, props.match.params.idPaciente)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    traerTerapias: traerTerapiasPaciente
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TerapiasPaciente))