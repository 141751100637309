import React, {Component} from 'react'
import { Link, Redirect, withRouter } from 'react-router-dom'
import sinFotoAzul from '../../assets/ic_foto_perfil_azul.svg'
import sinFotoVerde from '../../assets/ic_foto_perfil_verde.svg'
import sinFotoNaranja from '../../assets/ic_foto_perfil_naranja.svg'
import {connect} from 'react-redux'
import * as actions from '../../actions/sesionActions'
import logoAzul from '../../assets/logo_azul.svg'
import logoNaranja from '../../assets/logo_naranja.svg'
import logoVerde from '../../assets/logo_verde.svg'
import firebase from '../../proxy/Firebase'
import SkeletonResponsive from '../general/SkeletonResponsive';
// import {getUserInfo} from '../../reducers/sesionReducer'
import { ROL_ADMIN, ROL_PACIENTE, ROL_TERAPEUTA, SIN_FOTO } from '../../utilidades/Constantes'

class HeaderApp extends Component {

    state={
        auth: 0,
        verOpciones: false
    }

    componentDidMount(){
        this.props.indicarCargando()
        firebase.auth().onAuthStateChanged((u)=>{
            if(u === null){
                this.setState({
                    auth:-1
                })
                this.props.limpiarUsuario()
            }
            else{
                this.setState({
                    auth: 1
                })
                this.props.traerUsuario(u.uid)
            }
        })
    }

    cerrarSesion = () =>{
        this.props.limpiarUsuario()
    }

    render(){

        let user = this.props.user??{}
        let rol = user.rol??3

        let cargando = this.props.cargnadoUser??false

        let color = ''
        let rutaPerfil = '#'
        let logo = null
        let sinFoto = null
        
        if(rol === 0) {
            color = 'azul'
            rutaPerfil = '/administrador/perfil'
            logo = logoAzul
            sinFoto = sinFotoAzul
        }
        if(rol === 1){
            color = 'verde'
            rutaPerfil = '/paciente/perfil'
            logo = logoVerde
            sinFoto = sinFotoVerde
        }
        if(rol === 2){
            color = 'naranja'
            rutaPerfil = '/terapeuta/perfil'
            logo = logoNaranja
            sinFoto = sinFotoNaranja
        }


        if(this.state.auth === -1){
            return <Redirect to="/login"/>
        }

        if(rol === ROL_ADMIN && !this.props.location.pathname.includes('administrador'))
            return <Redirect to="/administrador"/>
        else if(rol === ROL_PACIENTE && !this.props.location.pathname.includes('paciente'))
            return <Redirect to="/paciente"/>
        else if(rol === ROL_TERAPEUTA && !this.props.location.pathname.includes('terapeuta'))
            return <Redirect to="/terapeuta"/>
            
        return (
            <div className="header-sesion">
                {
                    cargando?
                    <SkeletonResponsive tipo='rect' className="sk-imagen-logo"/>
                    :
                    <Link to="/" className="imagen-header">
                        <img className="logo" src={logo} alt="logo"/>
                    </Link>
                }
                <div className="opciones-header-perfil">
                    {
                        cargando?
                        <div to={rutaPerfil} className="btn-perfil" onClick={()=>this.setState({verOpciones:true})}>
                            <SkeletonResponsive tipo='circle' className="sk-foto"/>
                            <SkeletonResponsive tipo='rect' className="sk-nombre-header"/>
                        </div>
                        :
                        <Link to={rutaPerfil} className="btn-perfil" onClick={()=>this.setState({verOpciones:true})}>
                            <img src={
                                user.urlFotoPerfil ?
                                    user.urlFotoPerfil === SIN_FOTO ? sinFoto : user.urlFotoPerfil:
                                    sinFoto
                                } alt="Imagen de perfil"/>
                            <div to={rutaPerfil} className={`nombre-header text-decoration-${color}`}>
                                {user.nombre??'Cargando...'}
                                <hr/>
                            </div>
                        </Link>
                    }
                    {
                        cargando?
                        <SkeletonResponsive tipo='rect' className="sk-boton-header"/>
                        :
                        <button className={`btn-arketipos fondo-borde-${color}`} onClick={this.cerrarSesion}>Cerrar sesión</button>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (reducers) =>{
    return reducers.sesionReducer
}

export default withRouter(connect(mapStateToProps, actions)(HeaderApp))