import {
    GET_DB_PACIENTE, GET_DB_DETALLE_TERAPIA, GET_DB_TERAPEUTA,
    GET_DB_CITAS_PACIENTE, GET_DB_TERAPIAS, GET_DB_PERFIL_TERAPEUTA,
    CARGANDO_TERAPIAS, CARGANDO_PACIENTE, CARGANDO_CITAS,
    GET_DB_ACTIVIDADES_PACIENTE, CARGANDO_ACTIVIDADES
} from '../actions/pacienteActions';
import {createSelector} from 'reselect';
import { CLEAN_PACIENTE } from '../utilidades/Constantes';
import {DELETE_TERAPIA_ACTUAL} from "../actions/terapeutaActions";

const INITIAL_STATE = {
    paciente: null,
    terapeuta: {},
    terapeutas: {},
    terapias: null,
    terapia: null,
    citas: null,
    cargandoPaciente: false,
    cargandoTerapias: false,
    cargandoCitas: false,
    cargandoActividades: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_DB_PACIENTE: {
            const paciente = action.payload;
            return {...state, paciente, cargandoPaciente: false};
        }
        case GET_DB_TERAPIAS: {
            const terapias = action.payload;
            return {...state, terapias, cargandoTerapias: false};
        }
        case CARGANDO_TERAPIAS: {
            return {...state, cargandoTerapias: action.payload};
        }
        case CARGANDO_PACIENTE: {
            return {...state, cargandoPaciente: action.payload};
        }
        case CARGANDO_CITAS: {
            return {...state, cargandoCitas: action.payload};
        }
        case CARGANDO_ACTIVIDADES: {
            return {...state, cargandoActividades: action.payload};
        }
        case GET_DB_DETALLE_TERAPIA: {
            const terapia = action.payload;
            return {...state, terapia};
        }
        case DELETE_TERAPIA_ACTUAL: {
            const terapia = action.payload;
            return {...state, terapia};
        }
        case GET_DB_ACTIVIDADES_PACIENTE: {
            const actividades = action.payload;
            return {...state,
                terapia: {...state.terapia, actividades},
                cargandoActividades: false
            };
        }
        case GET_DB_CITAS_PACIENTE: {
            const citas = action.payload;
            return {...state, citas, cargandoCitas: false};
        }
        case GET_DB_TERAPEUTA: {
            const terapeuta = action.payload;
            return {...state, terapeuta};
        }
        case GET_DB_PERFIL_TERAPEUTA: {
            const perfilTerapeuta = action.payload;
            perfilTerapeuta.uidDoc = perfilTerapeuta.uidDocumento
            delete perfilTerapeuta.uidDocumento

            return  {...state, terapeutas: {...state.terapeutas, [perfilTerapeuta.uidDoc]: perfilTerapeuta}, cargandoPerfilTerapeuta: false};
        }
        case CLEAN_PACIENTE:{
            return  {
                paciente: null,
                terapeuta: {},
                terapeutas: {},
                terapias: null,
                terapia: null,
                citas: null,
                cargandoPaciente: false,
                cargandoTerapias: false,
                cargandoCitas: false,
                cargandoActividades: false,
            };
        }
        default:
            return state;
    }
}

export const getPaciente = createSelector(state => state.pacienteReducer, pacienteReducer => pacienteReducer.paciente);

export const getCargandoPaciente = createSelector(state => state.pacienteReducer, pacienteReducer => pacienteReducer.cargandoPaciente);
export const getCargandoActividades = createSelector(state => state.pacienteReducer, pacienteReducer => pacienteReducer.cargandoActividades);
export const getCargandoTerapias = createSelector(state => state.pacienteReducer, pacienteReducer => pacienteReducer.cargandoTerapias);
export const getCargandoCitas = createSelector(state => state.pacienteReducer, pacienteReducer => pacienteReducer.cargandoCitas);

export const getTerapias = createSelector(state => state.pacienteReducer, pacienteReducer => pacienteReducer.terapias);

export const getTerapia = createSelector(state => state.pacienteReducer, pacienteReducer => pacienteReducer.terapia);

export const getTerapeutaActual = createSelector((state, id) => ({pacienteReducer: state.pacienteReducer, id}), ({pacienteReducer, id}) => {
    return pacienteReducer.terapeutas[id]
});

export const getCitas = createSelector(state => state.pacienteReducer, pacienteReducer => {
    if(!pacienteReducer.citas || !pacienteReducer.paciente)
        return null

    let today = new Date()

    let citasVigentes = pacienteReducer.citas.filter( cita => new Date(cita.fecha) >= new Date(today.toJSON().slice(0,10)) )
    
    return citasVigentes.map( cita => {
        let psicologo = pacienteReducer.paciente.psicologos.filter( psicologo => psicologo.uidDoc === cita.idPsicologo )[0] ?? {}
        return { ...cita, nombrePsicologo: psicologo.nombre ?? "El psicologo no existe"  }
    }).sort((a, b) => {
        let dateA = new Date(`${a.fecha}T${a.inicio}`), 
        dateB = new Date(`${b.fecha}T${b.inicio}`);
        return dateA - dateB;
    })
});