import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import DialogoCrearTarea from "./dialogoCrearTarea/DialogoCrearTarea";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getDetalleTerapia,
  deleteTerapiaActual,
  updateOrderSession,
} from "../../../../../actions/terapeutaActions";
import { getTerapia } from "../../../../../reducers/terapeutaReducer";
import SkeletonResponsive from "../../../../general/SkeletonResponsive";

const DetalleTerapia = (props) => {
  const [dialogoCrearTarea, setDialogoCrearTarea] = useState(false);
  const { history, idTerapia, traerTerapia, quitarTerapia } = props;
  let detalleTerapia, tareas;
  detalleTerapia = props.detalleTerapia;
  tareas = detalleTerapia && detalleTerapia.tareas;

  const pintarTareas = (listaTareas) =>
    listaTareas
      .sort((a, b) => {
        if ((a.index ?? 0) > (b.index ?? 0)) {
          return -1;
        }
        return 1;
        // try {
        //   const numA = parseInt(a.nombre.match(/\d+/)[0]);
        //   const numB = parseInt(b.nombre.match(/\d+/)[0]);
        //   // Comparar los números extraídos
        //   if (numA < numB) {
        //     return 1;
        //   }
        //   if (numA > numB) {
        //     return -1;
        //   }
        //   return 0;
        // } catch (error) {
        //   if (a.nombre > b.nombre) return -1;
        //   return 1;
        // }
      })
      .map((tareas, i) => {
        return (
          <div className="con-tareas" key={`tarea-${i}`}>
            <p className="texto">{tareas.nombre}</p>
            <div className="order-container">
              <div>
                <input
                  type="number"
                  placeholder="0"
                  defaultValue={tareas.index ?? 0}
                  name={`index-${i}`}
                  onBlur={async (e) => {
                    const value = parseInt(e.target.value ?? 0);
                    await updateOrderSession(
                      idTerapia,
                      tareas.uidDocumento || tareas.uidDoc,
                      value
                    );
                  }}
                />
              </div>
              <button
                className="editar"
                onClick={() =>
                  history.push(
                    `/terapeuta/terapias/${idTerapia}/tareas/${
                      tareas.uidDocumento || tareas.uidDoc
                    }`
                  )
                }
              >
                Editar
              </button>
            </div>
          </div>
        );
      });
  const cerrarDialogo = () => {
    setDialogoCrearTarea(false);
  };
  useEffect(() => {
    traerTerapia(idTerapia);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="contenedor-detalle-terapia">
      {dialogoCrearTarea ? (
        <DialogoCrearTarea
          cerrar={cerrarDialogo}
          uid={detalleTerapia.uidDoc}
          index={tareas ? tareas.length ?? 0 : 0}
        />
      ) : (
        ""
      )}
      <div
        className="fila-atras"
        onClick={() => {
          history.goBack();
          quitarTerapia();
        }}
      >
        {detalleTerapia ? (
          <button className="btn-arketipos btn-terapeuta btn-atras">←</button>
        ) : (
          <SkeletonResponsive tipo={"rect"} className="sk-btn-atras" />
        )}
        {detalleTerapia ? (
          <h5 className="texto-atras">Tus terapias</h5>
        ) : (
          <SkeletonResponsive tipo={"rect"} className="sk-texto-atras" />
        )}
      </div>
      <div className="titulo-tarea">
        <div className="espacio-vacio" />
        {detalleTerapia ? (
          <h1 className="titulo-terapias">{detalleTerapia.nombre}</h1>
        ) : (
          <SkeletonResponsive tipo={"rect"} className="sk-titulo-terapias" />
        )}
        {detalleTerapia ? (
          <button
            className="btn-arketipos fondo-borde-naranja btn-crear-tarea"
            onClick={() => setDialogoCrearTarea(true)}
          >
            Crear Tarea{" "}
          </button>
        ) : (
          <SkeletonResponsive tipo={"rect"} className="sk-btn-crear-tarea" />
        )}
      </div>
      {detalleTerapia ? (
        <p className="descripcion-terapia">{detalleTerapia.descripcion}</p>
      ) : (
        <div className="sk-fila-texto">
          <SkeletonResponsive
            tipo={"rect"}
            className="sk-descripcion-terapia-1"
          />
          <SkeletonResponsive
            tipo={"rect"}
            className="sk-descripcion-terapia-2"
          />
        </div>
      )}
      <div className="contenedor-tareas">
        {tareas ? (
          tareas.length > 0 ? (
            pintarTareas(tareas)
          ) : (
            <div className="sin-tareas">
              <p className="texto">No hay tareas creadas</p>
            </div>
          )
        ) : (
          <div>
            <SkeletonResponsive tipo={"rect"} className="sk-tarea" />
            <SkeletonResponsive tipo={"rect"} className="sk-tarea" />
            <SkeletonResponsive tipo={"rect"} className="sk-tarea" />
            <SkeletonResponsive tipo={"rect"} className="sk-tarea" />
            <SkeletonResponsive tipo={"rect"} className="sk-tarea" />
          </div>
        )}
      </div>
    </div>
  );
};

DetalleTerapia.propTypes = {
  idTerapia: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  detalleTerapia: getTerapia(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      traerTerapia: getDetalleTerapia,
      quitarTerapia: deleteTerapiaActual,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DetalleTerapia));
