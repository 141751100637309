import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { getGuardandoCambio, getLoadingHome, getLoadingNosotros, getLoadingServicios } from '../../reducers/landingReducer'
import { getUserInfo } from '../../reducers/sesionReducer'
import Footer from '../general/Footer'
import Header from '../general/Header'
import LoadingIndicator from '../general/loadingIndicator/LoadingIndicator'
import FormualrioHome from './formualrioHome/FormualrioHome'
import FormualrioNosotros from './formualrioNosotros/FormualrioNosotros'
import FormualrioServicios from './formualrioServicios/FormualrioServicios'
import firebase from '../../proxy/Firebase'

const AdministradorLanding = ({usuario, cargandoHome, cargandoServicios, cargandoNosotros, guardando, location}) => {

    const [mostrarCargando, setMostrarCargando] = useState(false)
    const [auth, setAuth] = useState(true)
    

    const ruta = location.pathname

    useEffect(() => {
        
        const cargando = cargandoHome || cargandoServicios || cargandoNosotros || guardando

        setMostrarCargando(cargando) 

        if(auth === true)
            firebase.auth().onAuthStateChanged((u)=>{
                if(u === null){
                    setAuth(false)
                    // limpiarUsuario()
                }
            })
        // eslint-disable-next-line
    }, [cargandoHome, cargandoServicios, cargandoNosotros, guardando])


    if(usuario)
        if(usuario.rol !== 3) return <Redirect to="/login"/>
    
    if(!auth) return <Redirect to="/login"/>

    return (
        <div className="fondo-blanco">
            {mostrarCargando && <LoadingIndicator color="rgb(65,142,238)"/>}
            <Header/>
            <div className="center contenedor-opciones-admin-landing">
                <Link to="/administrador-landing" className={`opciones-admin-landing ${ruta === "/administrador-landing" && 'seleccionada'}`}>Home</Link>
                <Link to="/administrador-landing/servicios" className={`opciones-admin-landing ${ruta === "/administrador-landing/servicios" && 'seleccionada'}`}>Servicios</Link>
                <Link to="/administrador-landing/nosotros" className={`opciones-admin-landing ${ruta === "/administrador-landing/nosotros" && 'seleccionada'}`}>Nosotros</Link>
            </div>
            <Switch>
                <Route path="/administrador-landing/servicios" component={FormualrioServicios}/>
                <Route path="/administrador-landing/nosotros" component={FormualrioNosotros}/>
                <FormualrioHome />
            </Switch>
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({
    usuario: getUserInfo(state),
    cargandoHome: getLoadingHome(state),
    cargandoServicios: getLoadingServicios(state),
    cargandoNosotros: getLoadingNosotros(state),
    guardando: getGuardandoCambio(state),
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
    // traerUsuario = 
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdministradorLanding))
