import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import SkeletonResponsive from '../../../general/SkeletonResponsive';
import * as actions from '../../../../actions/pacienteActions';
import PerfilImg from './../../../../assets/ic_foto_perfil_naranja.svg';
import { getTerapeutaActual } from '../../../../reducers/pacienteReducer';
import { SIN_FOTO } from '../../../../utilidades/Constantes';

const PerfilTerapeuta = ({ terapeuta, match, consultarPerfilTerapeuta }) => {

    let history = useHistory()

    useEffect(() => {
        if(!terapeuta || (terapeuta && !terapeuta.correo))
            consultarPerfilTerapeuta({
                idPsicologo: match.params.id,
            })
    })

    return (
        <div className='contenedor-perfil-terapeuta'>
            <div className='encabezado-perfil-paciente'>
                <div
                    className='path-perfil-paciente'
                    onClick={() => history.push('/paciente')}>
                    <div className='flecha-decoracion'>
                        <span>&larr;</span>
                    </div>
                    <b>Resumen</b>
                </div>
            </div>
            <div className="perfil-terapeuta">
                {
                    terapeuta ? 
                        <div className='seccion-foto-cambiar'>
                            <img className='img-perfil'
                                src={
                                    // TODO: Quitar verificacion de null
                                    terapeuta.urlFotoPerfil ?
                                        terapeuta.urlFotoPerfil === SIN_FOTO ? PerfilImg : terapeuta.urlFotoPerfil:
                                        PerfilImg
                                }
                                alt="Imagen de perfil" />
                            <div className='nombre'>{terapeuta.nombre}</div>
                            <button
                                className={`btn-arketipos btn-paciente`}
                                onClick={() => history.push('/paciente/chat')}>Contactar</button>
                        </div>:
                        <SkeletonResponsive tipo='rect' className='sk-seccion-foto-cambiar'/>
                }
                <div className="titulo-perfil-terapeuta">
                    Tu terapeuta
                    <hr className={`background-paciente`}/>
                </div>
                <div className="contenedor-perfil">
                    <div className="fila-dato">
                        <p className="campo">Correo:</p>
                        {
                            terapeuta?
                                <p className="valor">{terapeuta.correo}</p>:
                                <SkeletonResponsive tipo='rect' className='sk-valor'/>
                        }
                    </div>
                    <div className="fila-dato">
                        <p className="campo">Celular:</p>
                        {
                            terapeuta ?
                                <p className="valor">{terapeuta.telefono}</p>:
                                <SkeletonResponsive tipo='rect' className='sk-valor'/>
                        }
                    </div>
                    <div className="fila-dato">
                        <p className="campo">Descripción:</p>
                        {
                            terapeuta ?
                                <p className="valor">{terapeuta.descripcion}</p>:
                                <SkeletonResponsive tipo='rect' className='sk-descripcion'/>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state, props) => ({
    terapeuta: getTerapeutaActual(state, props.match.params.id)
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PerfilTerapeuta));