import {combineReducers} from 'redux'

import landingReducer from './landingReducer'
import sesionReducer from './sesionReducer'
import adminReducer from './adminReducer'
import pacienteReducer from "./pacienteReducer";
import terapeutaReducer from "./terapeutaReducer";
import chatReducer from "./chatReducer";

export default combineReducers({
    landingReducer,
    sesionReducer,
    adminReducer,
    pacienteReducer,
    terapeutaReducer,
    chatReducer
});
