import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {seleccionarTerapia} from '../../../../actions/pacienteActions'
import SkeletonResponsive from "../../../general/SkeletonResponsive";

function Terapias({history, terapias, seleccionar}) {
    const llamarTerapia = (history, terapia) => {

        // seleccionar(terapias[i])
        history.push("/paciente/terapias/" + terapia);
    }

    const componenteListaTerapias = (listaTerapias) => (
        listaTerapias.map((terapia, i) => (
            <div onClick={() => llamarTerapia(history, terapia.uidDoc)} key={`terapia-${i}`}
                 className="contenedor-blanco">
                <div className="espacio-texto">
                    <div className="fila-titulo-terapia">
                        {terapia.uidTarea && terapia.tareaActual && <div className="tarea">
                            <p className="estado-terapia">Tarea Pendiente</p>
                            <hr className="subrayado"/>
                        </div>}
                        <h4 className="titulo-terapia">{terapia.nombre}</h4>
                    </div>
                    <p className="descripcion-terapia">{terapia.descripcion}</p>
                </div>
            </div>
        ))
    )

    return (
        <div className="contenedor-terapias">
                {terapias ?
                    terapias.length === 0 ?
                        <div className="sin-terapias">
                            <p className="texto">No tienes ninguna terapia asignada</p>
                        </div>:
                    <div className="lista-terapias">
                        {componenteListaTerapias(terapias)}
                    </div>
                    :
                    <div className="lista-terapias">
                        <SkeletonResponsive tipo={'rect'} className='sk-contenedor-blanco'/>
                        <SkeletonResponsive tipo={'rect'} className='sk-contenedor-blanco'/>
                        <SkeletonResponsive tipo={'rect'} className='sk-contenedor-blanco'/>
                        <SkeletonResponsive tipo={'rect'} className='sk-contenedor-blanco'/>
                        <SkeletonResponsive tipo={'rect'} className='sk-contenedor-blanco'/>
                        <SkeletonResponsive tipo={'rect'} className='sk-contenedor-blanco'/>
                    </div>
                }
        </div>
    );
}

Terapias.propTypes = {
    idPaciente: PropTypes.string,
    terapias: PropTypes.array,
};

const mapDispatchToProps = dispatch => bindActionCreators({
    seleccionar: seleccionarTerapia
}, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(Terapias));