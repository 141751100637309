import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { registrarTreapeuta } from '../../../../actions/adminActions'
import { OK } from '../../../../utilidades/Constantes'
import { CargandoContext } from '../../../general/CargandoContext/CargandoContext'

const DialogoAgregarTerapeuta = ({cerrarDialogoHandleClick}) => {

    const [formulario, setFormulario] = useState({})
    const [error, setError] = useState(null)
    
    let dialogoRef = useRef()
    
    let { correrIndicadorCarga, quitarIndicadorCarga } = useContext(CargandoContext)

    const handleClickOutside = useCallback( event => {
        if (dialogoRef && !dialogoRef.current.contains(event.target))
            cerrarDialogoHandleClick()
    }, [cerrarDialogoHandleClick])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);            
        }
    }, [handleClickOutside])

    const handleChange = (e) => {
        setFormulario({
            ...formulario,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if(formulario.password !== formulario.verificacionPassword) {
            setError('La contraseña y su verificación no coinciden')
            return
        }

        setError(null)

        correrIndicadorCarga()
        let res = await registrarTreapeuta(formulario)
        quitarIndicadorCarga()

        if(res !== OK) {
            setError('Hubo un inconveniente, por favor intentalo más tarde. También revisa que el correo no se encuentre registrado.')
        } else {
            cerrarDialogoHandleClick()
        }
    }

    return (
        <div className='fondo-dialogo'>
            <div className='dialogo-agregar-terapeuta' ref={dialogoRef}>
                <form className='contenido-dialogo' onSubmit={handleSubmit}>
                    <div className='titulo-dialogo'>
                        Añadir a un terapeuta
                        <hr />
                    </div>
                    <input name='nombre' onChange={handleChange} value={formulario.nombre} placeholder='Nombre' type="text" required/>
                    <input name='correo' onChange={handleChange} value={formulario.correo} placeholder='Correo electrónico' type="email" required/>
                    <input minLength='6' name='password' onChange={handleChange} value={formulario.password} placeholder='Contraseña' type="password" required/>
                    <input minLength='6' name='verificacionPassword' onChange={handleChange} value={formulario.verificacionPassword} placeholder='Confirmar contraseña' type="password" required/>
                    {
                        error && 
                        <div className='error'>{error}</div>
                    }
                    <div className="botones">
                        <button type='button' className="btn-arketipos" onClick={cerrarDialogoHandleClick}>Cancelar</button>
                        <button type='submit' className="btn-arketipos btn-agregar">Agregar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default DialogoAgregarTerapeuta
