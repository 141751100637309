import React, { useContext, useEffect, useState } from 'react';
import SkeletonResponsive from "./../../../general/SkeletonResponsive";
import { bindActionCreators } from "redux";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { CargandoContext } from './../../../general/CargandoContext/CargandoContext';
// import { getUserInfo } from '../../../../reducers/sesionReducer';
import { getPacienteActual, getTareaActual, getTerapiaPaciente } from '../../../../reducers/adminReducer';
import { traerActividadesTareaPaciente } from '../../../../actions/adminActions';
import PropTypes from 'prop-types'
import TextoInterlineado from '../../../general/TextoInterlineado';
import ReactPlayer from 'react-player'

const ActividadesPaciente = ({ paciente, tarea, esPsicologo, consultarTareaActividades, match, history, detalleTerapia }) => {
    let ready = paciente && tarea && tarea.actividades

    const [buscandoTarea, setBuscandoTarea] = useState(false)

    let { idPaciente, idTerapiaActiva, idTarea, idTerapeuta } = match.params

    let { correrIndicadorCarga, quitarIndicadorCarga } = useContext(CargandoContext)


    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        // if ((!paciente || (paciente && !paciente.correo))) {
        //     consultarPerfilPaciente({
        //         idPaciente
        //     })
        // }

        if(paciente && detalleTerapia){
            if (!tarea && !buscandoTarea) {
                setBuscandoTarea(true)
                correrIndicadorCarga()
                consultarTareaActividades({
                    paciente: idPaciente,
                    terapia: idTerapiaActiva,
                    tarea: idTarea,
                    psicologo: idTerapeuta
                })
            }
            else if(tarea && !buscandoTarea){
                if (!tarea.actividades && !buscandoTarea) {
                    setBuscandoTarea(true)
                    correrIndicadorCarga()
                    consultarTareaActividades({
                        paciente: idPaciente,
                        terapia: idTerapiaActiva,
                        tarea: idTarea,
                        psicologo: idTerapeuta
                    })
                }
            }
    
            if(tarea)
                if(tarea.actividades) quitarIndicadorCarga()
        }

        // eslint-disable-next-line
    }, [tarea])

    // console.log('***************')
    // console.log(tarea)

    const pintarActividades = (actividades) => (
        actividades.map((actividad, i) => {
            switch (actividad.tipo) {
                case 1:
                    return <div className="con-actividad" key={actividad.uidDoc}>
                        <p className="entregado entregado-admin">{actividad.entregado?"Entregado":"Pendiente"}</p>
                        <div className="content">
                            <TextoInterlineado className="descripcion" numero={i + 1} texto={actividad.descripcion}/>
                            {pintarRecursos(actividad.recursos ?? [])}
                        </div>
                    </div>
                case 2:
                    return <div className="con-actividad" key={actividad.uidDoc}>
                        <p className="entregado entregado-admin">{actividad.entregado?"Entregado":"Pendiente"}</p>
                        <div className="content">
                            <TextoInterlineado className="descripcion" numero={i + 1} texto={actividad.descripcion}/>
                            {pintarRecursos(actividad.recursos ?? [])}
                            {actividad.respuesta && <div className="contenedor-respuesta">
                                <p className="respuesta">{actividad.respuesta}</p>
                            </div>}
                        </div>
                    </div>
                case 3:
                    return <div className="con-actividad" key={actividad.uidDoc}>
                        <p className="entregado entregado-admin">{actividad.entregado?"Entregado":"Pendiente"}</p>
                        <div className="content">
                            <TextoInterlineado className="descripcion" numero={i + 1} texto={actividad.descripcion}/>
                            {pintarRecursos(actividad.recursos ?? [])}
                            {actividad.archivoEntrega && <div className="fila-archivo">
                                <h3 className="nombre-archivo">{actividad.archivoEntrega.nombre}</h3>
                                <a className="descargar descargar-admin" href={actividad.archivoEntrega.url} target="_blank" rel="noopener noreferrer">Descargar</a>
                            </div>}
                        </div>
                    </div>
                default:
                    return <div />
            }
        }
        )
    )
    const pintarRecursos = (recursos) => (
        recursos.map((recurso, j) => {
            switch (recurso.tipo) {
                case 0: {
                    return <div className="recursos">
                        <div className="info-video">
                            <div className="archivos">
                                <h3 className="nombre-archivo">{recurso.nombre}</h3>
                                <p>{recurso.descripcion}</p>
                            </div>
                            <a className="descargar-archivo descargar-admin" href={recurso.url} rel="noopener noreferrer" target="_blank">Descargar</a>
                        </div>
                    </div>
                }
                case 1: {
                    // let linkCortado = recurso.url.split('/')
                    // let videoId = linkCortado[linkCortado.length - 1]
                    return <div className="recursos" key={`recurso-${j}`}>
                        <a className="link" rel="noopener noreferrer" href={recurso.url} target="_blank">{recurso.url && recurso.url.includes("youtu") ? "Ver desde YouTube" : "Descargar video"}</a>
                        <div className="info-video">
                            <ReactPlayer url={recurso.url} width="50%" height="10em"/>
                            {/* <iframe
                                title="Video"
                                className="video"
                                src={`https://www.youtube.com/embed/${videoId}`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            /> */}
                            <div className="datos-video">
                                <h3 className="titulo-video">{recurso.nombre}</h3>
                                <p>{recurso.descripcion}</p>
                            </div>
                        </div>
                    </div>
                }
                case 2: {
                    return <div className="recursos" key={`recurso-${j}`}>
                        <a className="link" href={recurso.url} rel="noopener noreferrer" target="_blank">Ver imagen</a>
                        <div className="info-video">
                            <img className="video" src={recurso.url}
                                alt={recurso.nombre}
                            />
                            <div className="datos-video">
                                <h3 className="titulo-video">{recurso.nombre}</h3>
                                <p>{recurso.descripcion}</p>
                            </div>
                        </div>
                    </div>
                }
                default:
                    return <div />
            }
        })
    )

    if (!paciente || !detalleTerapia)
        return <Redirect to={esPsicologo ? `/administrador/terapeutas/${idTerapeuta}/pacientes/${idPaciente}`
            : `/administrador/pacientes/${idPaciente}/terapeutas/${idTerapeuta}`} />

    return (
        <div className="contenido-terapeuta">
            <div className="contenedor-revision-actividades">
                <div className="fila-atras" onClick={() => {
                    history.goBack();
                    // history.push(`/administrador/terapeutas/${idTerapeuta}/pacientes/${idPaciente}/terapias/${idTerapiaActiva}/`);
                }}>
                    {ready ? <button className="btn-arketipos btn-administrador btn-atras">←</button>
                        : <SkeletonResponsive tipo={'rect'} className='sk-btn-atras' />}
                    {ready ? <h5 className="texto-atras">{`Tareas - ${paciente.nombre}`}</h5>
                        : <SkeletonResponsive tipo={'rect'} className='sk-texto-atras' />}
                </div>
                <div className="titulo-terapia">
                    {ready ? <h1 className="nombre-terapia">{tarea?.nombre}</h1>
                        : <SkeletonResponsive tipo={'rect'} className='sk-titulo-terapias' />}
                </div>
                <div className="contenedor-actividades">
                    {ready ?
                        tarea.actividades.length > 0 ? pintarActividades(tarea.actividades)
                            :
                            <div className="sin-actividades">
                                <p className="texto">Sin actividades</p>
                            </div>
                        : <div>
                            <SkeletonResponsive tipo={'rect'} className='sk-actividad' />
                            <SkeletonResponsive tipo={'rect'} className='sk-actividad' />
                            <SkeletonResponsive tipo={'rect'} className='sk-actividad' />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

ActividadesPaciente.propTypes = {
    esPsicologo: PropTypes.bool.isRequired,
}

const mapStateToProps = (state, props) => ({
    paciente: getPacienteActual(state, props.match.params.idPaciente),
    tarea: getTareaActual(state, props.match.params.idPaciente, props.match.params.idTerapeuta, props.match.params.idTerapiaActiva, props.match.params.idTarea),
    detalleTerapia: getTerapiaPaciente(state, props.match.params.idPaciente, props.match.params.idTerapeuta, props.match.params.idTerapiaActiva),
});

const mapDispatchToProps = dispatch => bindActionCreators({
    // consultarPerfilPaciente: consultarPerfilPaciente,
    consultarTareaActividades: traerActividadesTareaPaciente,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActividadesPaciente));