import React, {Component} from 'react';
import HeaderApp from '../general/HeaderApp';
import {Route, Switch, withRouter} from 'react-router-dom';
import Resumen from "./components/resumen/Resumen";
import Perfil from "./../general/Perfil/Perfil";
import Terapias from "./components/terapias/Terapias";
import Pacientes from "./components/pacientes/Pacientes";
import Citas from "./../general/citas/Citas";
import DetalleTerapia from "./components/terapias/detalleTerapia/DetalleTerapia";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../actions/terapeutaActions';
import {
    getTerapiasPsicologo,
    getPerfilPsicologo,
    getPacientesPsicologo,
    getCitasPsicologo,
    getCargandoTerapias
} from '../../reducers/terapeutaReducer';
import {getUserInfo} from '../../reducers/sesionReducer';
import LoadingIndicator from '../general/loadingIndicator/LoadingIndicator';
import {CargandoContext} from '../general/CargandoContext/CargandoContext';
import Menu from '../general/Menu/Menu';
import PerfilPacienteTerapeuta from './components/pacientes/perfilPacienteTerapeuta/PerfilPacienteTerapeuta';
import DialogoAsignarTerapia from './components/dialogoAsignarTerapia/DialogoAsignarTerapia';
import DetalleTarea from './components/tareas/DetalleTarea.jsx';
import Chat from '../general/Chat';
import RevisionTerapia from "./components/pacientes/perfilPacienteTerapeuta/revisionTerapia/RevisionTerapia";
import RevisarTarea
    from "./components/pacientes/perfilPacienteTerapeuta/revisionTerapia/revisarTarea/RevisarTarea";


class Terapeuta extends Component {

    state = {
        cargando: false,
        pacientePorAsignarTerapia: null
    }

    traerInformacion = () => {
        const {getDBTerapiasPsicologo, getDBGetPerfilPsicologo, getDBCitasPsicologo, sesion} = this.props;
        if (sesion) {
            if (this.props.terapias === null)
                getDBTerapiasPsicologo(sesion.uidDocumento);

            if (this.props.perfil === null)
                getDBGetPerfilPsicologo(sesion.uidDocumento);

            if (this.props.citas === null)
                getDBCitasPsicologo(sesion.uidDocumento);
        }

    }

    componentDidUpdate(prevProps, prevState) {
        const {getPacientesPsicologo, terapias, perfil, citas} = this.props;

        const prevPropsPacientesNuevos = prevProps.perfil ? prevProps.perfil.pacientesNuevos : [];
        
        if (!terapias || !perfil || !citas) {
            this.traerInformacion()
        }

        if (perfil && terapias && (perfil.pacientesNuevos !== prevPropsPacientesNuevos || terapias !== prevProps.terapias)) {
            getPacientesPsicologo({terapias, perfil})
        }
    }

    setPacientePorAsignarTerapia = (paciente) => {
        this.setState({
            pacientePorAsignarTerapia: paciente
        })
    }

    render() {
        let ruta = this.props.location.pathname.split("/")
        let idTerapia = ruta[ruta.length - 1]
        let idTarea = ruta[ruta.length - 1]
        let pacientes = this.props.pacientes
        let psicologo = this.props.perfil ?? {}
        let terapias = this.props.terapias ?? []
        let sesion = this.props.sesion ?? {}
        let uid = sesion.uidDocumento ?? ''
        if (ruta.length === 6) {
            idTerapia = ruta[ruta.length - 3]
        }

        return (
            <CargandoContext.Provider value={{
                correrIndicadorCarga: () => this.setState({cargando: true}),
                quitarIndicadorCarga: () => this.setState({cargando: false}),
            }}>
                <div className="pagina-terapeuta">
                    <HeaderApp/>
                    <Menu usuario='terapeuta'/>
                    <hr className='linea-menu'/>
                    <div className="contenido-terapeuta">
                        <Switch>
                            <Route path="/terapeuta/pacientes/:idPaciente/terapia/:idTerapiaActiva/tareas/:idTarea">
                                <RevisarTarea/>
                            </Route>
                            <Route path="/terapeuta/pacientes/:idPaciente/terapia/:idTerapiaActiva">
                                <RevisionTerapia/>
                            </Route>
                            <Route path="/terapeuta/perfil">
                                <Perfil
                                    usuario='terapeuta'
                                    profileInfo={this.props.perfil}
                                    updateInfo={actions.updateTerapeutaProfileInfo}/>
                            </Route>
                            <Route path="/terapeuta/terapias/:idTerapia/tareas/:idTarea">
                                <DetalleTarea idTarea={idTarea} idTerapia={idTerapia}/>
                            </Route>
                            <Route path="/terapeuta/terapias/:idTerapia">
                                <DetalleTerapia idTerapia={idTerapia}/>
                            </Route>
                            <Route path="/terapeuta/terapias">
                                <Terapias
                                    terapias={this.props.terapias}
                                    uid={uid}
                                />
                            </Route>
                            <Route path="/terapeuta/citas">
                                <Citas
                                    usuario='terapeuta'
                                    citas={this.props.citas}
                                    pacientes={this.props.pacientes}
                                />
                            </Route>
                            <Route path="/terapeuta/pacientes/:id">
                                <PerfilPacienteTerapeuta
                                    setPacientePorAsignarTerapia={this.setPacientePorAsignarTerapia}/>
                            </Route>
                            <Route path="/terapeuta/pacientes">
                                <Pacientes
                                    pacientes={pacientes}/>
                            </Route>
                            <Route path="/terapeuta/chat">
                                <Chat usuario="terapeuta"/>
                            </Route>
                            <Route path="/terapeuta/">
                                <Resumen
                                    pacientes={pacientes ?? []}
                                    nombre={psicologo.nombre ?? ""}
                                    setPacientePorAsignarTerapia={this.setPacientePorAsignarTerapia}/>
                            </Route>
                        </Switch>
                    </div>
                    {
                        this.state.pacientePorAsignarTerapia &&
                        <DialogoAsignarTerapia
                            cerrarDialogoHandleClick={() => this.setState({pacientePorAsignarTerapia: null})}
                            paciente={this.state.pacientePorAsignarTerapia}
                            terapias={terapias}/>
                    }
                    {
                        this.state.cargando &&
                        <LoadingIndicator
                            color='rgb(255, 125, 81)'/>
                    }
                </div>
            </CargandoContext.Provider>
        );
    }
}

const mapStateToProps = state => ({
    terapias: getTerapiasPsicologo(state),
    perfil: getPerfilPsicologo(state),
    pacientes: getPacientesPsicologo(state),
    sesion: getUserInfo(state),
    cargandoTerapias: getCargandoTerapias(state),
    citas: getCitasPsicologo(state),
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Terapeuta));