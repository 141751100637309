import React, {useState} from 'react';
import PropTypes from 'prop-types';

const DialogoAdjuntar = ({cerrar, guardarRecurso, seleccionarTipo}) => {

    const [tipo, setTipo] = useState(0)
    const [url, setUrl] = useState('')

    const adjuntarArchivo = (e, tipo) =>{
        guardarRecurso(e.target.files[0], tipo, true)
    }

    const guardarUrl = (url, tipo) =>{
        guardarRecurso(url, tipo, false)
    }

    const seleccionarVista = () =>{

        if(seleccionarTipo)
            return <div className="contenedor-botones-dialogo">
                <button type="button" onClick={()=>guardarRecurso(1)} className="btn-arketipos btn-terapeuta">Lectura</button>
                <button type="button" onClick={()=>guardarRecurso(2)} className="btn-arketipos btn-terapeuta">Escritura</button>
                <button type="button" onClick={()=>guardarRecurso(3)} className="btn-arketipos btn-terapeuta">Adjuntar</button>
            </div>

        if(tipo === 0)
            return <div className="contenedor-botones-dialogo">
                <label className="btn-arketipos btn-terapeuta btn-adjuntar-archivo">
                    <span>Archivo</span>
                    <input type="file" name="archivo" onChange={(e)=>adjuntarArchivo(e, 0)}/>
                </label>
                <button type="button" onClick={()=>setTipo(1)} className="btn-arketipos btn-terapeuta">Video</button>
                <button type="button" onClick={()=>setTipo(2)} className="btn-arketipos btn-terapeuta">Imagen</button>
            </div>

        if(tipo === 1) return <div className="contenedor-botones-dialogo">
            <label className="contenedor-input">
                <span>Ingresa la url del video</span><br/>
                <input className="input" type="text" placeholder="URL del video" name="archivo" onChange={(e)=>setUrl(e.target.value)}/>
            </label>
            <div className="contenedor-input">
                <span>O puedes subirlo</span>
                <label className="btn-arketipos btn-terapeuta btn-adjuntar-archivo">
                    <span>Subir Video</span>
                    <input type="file" accept="video/*" name="archivo" onChange={(e)=>adjuntarArchivo(e, 1)}/>
                </label>
            </div>
            <button type="button" onClick={()=>guardarUrl(url, 1)} className="btn-arketipos btn-terapeuta">Guardar</button>
        </div>

        if(tipo === 2) return <div className="contenedor-botones-dialogo">
            <label className="contenedor-input">
                <span>Ingresa la url de la imagen</span><br/>
                <input className="input" type="text" placeholder="URL del video" name="archivo" onChange={(e)=>setUrl(e.target.value)}/>
            </label>
            <div className="contenedor-input">
                <span>O puedes subirla</span>
                <label className="btn-arketipos btn-terapeuta btn-adjuntar-archivo">
                    <span>Subir Imagen</span>
                    <input type="file" accept="image/*" name="archivo" onChange={(e)=>adjuntarArchivo(e, 2)}/>
                </label>
            </div>
            <button type="button" onClick={()=>guardarUrl(url, 2)} className="btn-arketipos btn-terapeuta">Guardar</button>
        </div>
        
        return ''
    }

    if(seleccionarTipo)
        return (
            <div className="fondo-dialogo-crear-tarea">
                <div className="fondo-dialogo-rc" onClick={cerrar}/>
                <div className="dialogo-crear-tarea dialogo-adjuntar">
                    <h2 className="titulo-dialogo-crear-tarea">
                        Selecciona el tipo de actividad
                    </h2>
                    <div className="form">
                        {seleccionarVista()}
                    </div>
                </div>
            </div>
        )

    return (
        <div className="fondo-dialogo-crear-tarea">
            <div className="fondo-dialogo-rc" onClick={cerrar}/>
            <div className="dialogo-crear-tarea dialogo-adjuntar">
                <h2 className="titulo-dialogo-crear-tarea">
                    Agrega un recurso para la actividad
                </h2>
                <div className="form">
                    {seleccionarVista()}
                </div>
            </div>
        </div>
    )

}

DialogoAdjuntar.propTypes = {
    cerrar: PropTypes.func.isRequired,
    guardarRecurso: PropTypes.func.isRequired,
    seleccionarTipo: PropTypes.bool,
    recibirTipo: PropTypes.func
}

export default DialogoAdjuntar;