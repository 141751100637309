import firebase from './Firebase'
import {OK, ERROR, UPDATE, SET, DELETE} from '../utilidades/Constantes'

const database = firebase.firestore()

export const consultarColeccion = async (ruta, dispatch, etiqueta, options, offListener) => {
    let onCollectionUpdate = (querySnapshot) => {
        const prds = [];
        querySnapshot.forEach((doc) => {
            prds.push({...doc.data(), uidDoc: doc.id});
        });
        dispatch({
            type: etiqueta,
            payload: prds,
            options
        })
    }
    let listener = database.collection(ruta).onSnapshot(onCollectionUpdate)
    if(offListener){
        dispatch({
            type: offListener,
            isListener: true,
            payload: listener
        })
    }
}

export const obtenerColeccion = async (ruta) => {
    let documentos = []
    
    let res = await database.collection(ruta).get()
    
    res.forEach(doc => documentos.push({...doc.data(), uidDoc: doc.id}))

    return documentos
}

export const consultarColeccionConSubcolecciones = async (ruta, subcolecciones, dispatch, etiqueta) => {
    let onCollectionUpdate = (querySnapshot) => {
        let documentos = [];
        let promises = [];

        querySnapshot.forEach((doc) => {

            let promisesSubcolecciones = []
            let docCompleto = {...doc.data(), uidDoc: doc.id}

            subcolecciones.forEach(subcoleccion => {
                promisesSubcolecciones.push(database.collection(ruta).doc(doc.id).collection(subcoleccion).get().then(res => {
                    let docsSubcoleccion = []
                    res.forEach(docSub => docsSubcoleccion.push({...docSub.data(), uidDoc: docSub.id}))
                    docCompleto = {...docCompleto, [subcoleccion]: docsSubcoleccion}
                }))
            })

            promises.push(Promise.all(promisesSubcolecciones).then(() => {
                documentos.push(docCompleto)
            }))
        });

        Promise.all(promises).then(() => {
            dispatch({
                type: etiqueta,
                payload: documentos
            });
        });
    }
    database.collection(ruta).onSnapshot(onCollectionUpdate)
}

export const consultarDocumentoConSubcolecciones = async (ruta, subcolecciones, dispatch, etiqueta, options) => {
    let onCollectionUpdate = (querySnapshot) => {
        let promisesSubcolecciones = []
        let docCompleto = {...querySnapshot.data(), uidDoc: querySnapshot.id}

        subcolecciones.forEach(subcoleccion => {
            promisesSubcolecciones.push(database.doc(ruta).collection(subcoleccion).get().then(res => {
                let docsSubcoleccion = []
                res.forEach(docSub =>
                    docsSubcoleccion.push({...docSub.data(), uidDoc: docSub.id.trim()}))
                docCompleto = {...docCompleto, [subcoleccion]: docsSubcoleccion}
            }))
        })
        Promise.all(promisesSubcolecciones).then(() => {
            dispatch({
                type: etiqueta,
                payload: docCompleto,
                options
            });
        })
    };
    database.doc(ruta).onSnapshot(onCollectionUpdate)
}


export const consultarDocumentoConSubcoleccionesSinEscuchador = async (ruta, subcolecciones, dispatch, etiqueta) => {
    
    let documento = await database.doc(ruta).get()

    let promisesSubcolecciones = []
        let docCompleto = {...documento.data(), uidDoc: documento.id}

    subcolecciones.forEach(subcoleccion => {
        promisesSubcolecciones.push(database.doc(ruta).collection(subcoleccion).get().then(res => {
            let docsSubcoleccion = []
            res.forEach(docSub =>
                docsSubcoleccion.push({...docSub.data(), uidDoc: docSub.id.trim()}))
            docCompleto = {...docCompleto, [subcoleccion]: docsSubcoleccion}
        }))
    })
    Promise.all(promisesSubcolecciones).then(() => {
        dispatch({
            type: etiqueta,
            payload: docCompleto,
        });
    })
}


export const consultarColeccionCampoEspecifico = async (ruta, campo, valor, dispatch, etiqueta) => {
    let onCollectionUpdate = (querySnapshot) => {
        const prds = [];
        querySnapshot.forEach((doc) => {
            prds.push({...doc.data(), uidDoc: doc.id});
        });
        dispatch({
            type: etiqueta,
            payload: prds
        })
    }
    database.collection(ruta).where(campo, '==', valor).onSnapshot(onCollectionUpdate)
}

export const consultarColeccionCampoEspecificoRestricciones = async (ruta, restricciones, dispatch, etiqueta, options) => {
    let onCollectionUpdate = (querySnapshot) => {
        const prds = [];
        querySnapshot.forEach((doc) => {
            prds.push({...doc.data(), uidDoc: doc.id});
        });
        dispatch({
            type: etiqueta,
            payload: prds,
            options
        })
    }
    let res = database.collection(ruta)
    restricciones.forEach( restriccion => {
        res = res.where(restriccion.campo, '==', restriccion.valor)
    })
    res.onSnapshot(onCollectionUpdate)
}

export const obtenerColeccionCampoEspecifico = async (ruta, campo, valor) => {
    let documentos = []

    let res = await database.collection(ruta).where(campo, '==', valor).get()
    
    res.forEach(doc => documentos.push({...doc.data(), uidDoc: doc.id}))

    return documentos
}

export const obtenerColeccionCamposEspecificos = async (ruta, restricciones) => {
    let documentos = []

    let res = database.collection(ruta)

    restricciones.forEach( restriccion => {
        res = res.where(restriccion.campo, '==', restriccion.valor)
    })
    
    res = await res.get()
    
    res.forEach(doc => documentos.push({...doc.data(), uidDoc: doc.id}))

    return documentos
}

export const consultarDocumentosPorId = async (ruta, arregloIds, dispatch, etiqueta) => {
    let onCollectionUpdate = (querySnapshot) => {
        const prds = [];
        querySnapshot.forEach((doc) => {
            prds.push({...doc.data(), uidDoc: doc.id});
        });
        dispatch({
            type: etiqueta,
            payload: prds
        })
    }
    database.collection(ruta).where(firebase.firestore.FieldPath.documentId(), 'in', arregloIds).onSnapshot(onCollectionUpdate)
}

export const consultarDocumento = async (ruta, dispatch, etiqueta, options) => {
    let onCollectionUpdate = (querySnapshot) => {
        if (querySnapshot.exists === false) {
            return null
        }
        dispatch({
            type: etiqueta,
            payload: {...querySnapshot.data(), uidDocumento: querySnapshot.id},
            options: {...options}
        })
    }
    database.doc(ruta).onSnapshot(onCollectionUpdate);
}

export const obtenerDocumento = async (ruta) => {
    let res = await database.doc(ruta).get()
    return {...res.data(), uidDoc: res.id}
}

export const crearDocumento = async (ruta, doc) => {

    try {
        await database.doc(ruta).set({...doc})
        return OK
    } catch (e) {
        return ERROR
    }
}

export const eliminarDocumento = async (ruta) => {

    database.doc(ruta).delete().then(() => {
        return OK
    }).catch(error => {
        console.log(error)
        return ERROR
    })
}


export const crearDocumentoAutoID = async (ruta, doc, devolverId = false) => {
    try {
        const documento = database.collection(ruta).doc()
        await documento.set(doc)
        return devolverId ? { id: documento.id } : OK
    } catch (e) {
        return ERROR
    }
}

export const sumaRestaDocumento = async (ruta, incremento, campo) => {
    try {
        const documento = database.doc(ruta)
        await documento.update({
            [campo]: firebase.firestore.FieldValue.increment(incremento)
        })
        return OK
    } catch (e) {
        return ERROR
    }
}

export const consultarColeccionConOrden = async (ruta, campo, tipo) => {
    let coleccion = []

    let onCollectionUpdate = (querySnapshot) => {
        const prds = [];
        querySnapshot.forEach((doc) => {
            prds.push({...doc.data(), uidDoc: doc.id});
        });
        coleccion = prds
        return coleccion
    }

    database.collection(ruta).orderBy(campo, tipo).onSnapshot(onCollectionUpdate)

}

export const editarDocumento = async (ruta, doc) => {
    try {
        await database.doc(ruta).update(doc)
        return OK
    } catch (e) {
        console.log(e)
        return ERROR
    }
}

export const escrituraPorLotes = async escrituras => {
    let batch = database.batch()

    for (let i = 0; i < escrituras.length; i++) {
        let actualRef = database.doc(escrituras[i].ruta)
        
        switch (escrituras[i].tipo) {
            case UPDATE:
                batch.update(actualRef, escrituras[i].data);
                break;
            case SET:
                batch.set(actualRef, escrituras[i].data);
                break;
            case DELETE:
                batch.delete(actualRef);
                break;
            default:
                break;
        }
    }

    try {
        await batch.commit()
        return OK
    } catch (error) {
        return ERROR
    }
}

export const incrementarTareasCompletadas = async idTerapiaActiva => {
    let terapiaActivaDocRef = database.collection('terapiasActivas').doc(idTerapiaActiva)

    try {
        return await database.runTransaction(async transaction => {
            let doc = await transaction.get(terapiaActivaDocRef)

            transaction.update(terapiaActivaDocRef,
                {
                    tareasCompletadas: doc.data().tareasCompletadas + 1,
                    revisar: false
                }
            )
        })
    } catch (error) {
        return ERROR
    }
}

export const apagarListener = (listener, dispatch, etiqueta) => {
    listener()
    dispatch({
        payload: null,
        type: etiqueta
    })
}