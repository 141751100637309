import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { CargandoContext } from '../../../general/CargandoContext/CargandoContext'
import DialogoCrearTerapia from "./detalleTerapia/dialogoCrearTerapia/DialogoCrearTerapia";
import SkeletonResponsive from "../../../general/SkeletonResponsive";
import DialogoDosBotones from "../../../general/DialogoDosBotones"
import DialogoUnBoton from "../../../general/DialogoUnBoton"
import eliminar from "../../../../assets/ic_eliminar_terapia.svg"
import { eliminarTerapia } from "../../../../actions/terapeutaActions"

class Terapias extends React.Component {

    state = {
        crearTerapia: false,
        eliminarTerapia: {
            mostrar: false,
            titulo: "",
            descripcion: "",
            boton1: "",
            boton2: "",
            click1: () => { },
            click2: this.cerrarEliminar,
        },
        dialogo: {
            mostrar: false,
            titulo: "",
            descripcion: "",
            boton1: "",
            click1: () => { },
        },
        filter: ""
    }

    cerrarDialogo = () => {
        this.setState({
            crearTerapia: false
        })
    };

    cerrarEliminar = () => {
        this.setState({
            eliminarTerapia: {
                mostrar: false,
                titulo: "",
                descripcion: "",
                boton1: "",
                boton2: "",
                click1: () => { },
                click2: this.cerrarEliminar,
            }
        })
    };

    cerrarDialogoUnBoton = () => {
        this.setState({
            dialogo: {
                mostrar: false,
                titulo: "",
                descripcion: "",
                boton1: "",
                click1: () => { }
            }
        })
    };

    eliminarTerapia = async (cargando, idTerapia, cerrar, dialogo) => {
        cerrar()
        cargando.correrIndicadorCarga()
        let res = await eliminarTerapia(idTerapia)
        if (res.estado) {
            this.setState({
                dialogo: {
                    mostrar: true,
                    titulo: "Error",
                    descripcion: "Ocurrió un error eliminando tu terapia, inténtalo de nuevo más tarde",
                    boton1: "Aceptar",
                    click1: this.cerrarDialogoUnBoton
                }
            })
        } else {
            this.setState({
                dialogo: {
                    mostrar: true,
                    titulo: "Eliminado",
                    descripcion: "Tu terapia fue eliminada exitosamente",
                    boton1: "Listo",
                    click1: this.cerrarDialogoUnBoton
                }
            })
        }
        cargando.quitarIndicadorCarga()
    }

    handleChange = (e) => {
        const value = e.target.value
        this.setState({ filter: value })
    }

    render() {
        const { terapias, history, uid } = this.props;
        const value = this.state.filter

        const list = (terapias ?? []).filter((terapia) => terapia.nombre.toLowerCase().includes(value.toLowerCase()))

        return (
            <CargandoContext.Consumer>
                {value =>
                    <div className="lista-terapias">
                        {this.state.eliminarTerapia.mostrar && <DialogoDosBotones
                            titulo={this.state.eliminarTerapia.titulo}
                            descripcion={this.state.eliminarTerapia.descripcion}
                            boton1={this.state.eliminarTerapia.boton1}
                            boton2={this.state.eliminarTerapia.boton2}
                            click1={this.state.eliminarTerapia.click1}
                            click2={this.state.eliminarTerapia.click2}
                            cerrar={this.cerrarEliminar}
                            color={"naranja"} />}
                        {this.state.dialogo.mostrar && <DialogoUnBoton
                            titulo={this.state.dialogo.titulo}
                            descripcion={this.state.dialogo.descripcion}
                            boton1={this.state.dialogo.boton1}
                            click1={this.state.dialogo.click1}
                            cerrar={this.cerrarDialogoUnBoton}
                            color={"naranja"} />}
                        {this.state.crearTerapia && <DialogoCrearTerapia cerrar={this.cerrarDialogo} uid={uid} />}
                        {terapias ? <button onClick={() => this.setState({ crearTerapia: true })}
                            className="btn-arketipos btn-crear-terapia">
                            Crear terapia </button>
                            : <SkeletonResponsive tipo={'rect'} className='sk-btn-crear-terapia' />}
                        {
                            terapias ?
                                (terapias.length === 0 ?
                                    <div className="sin-terapias">
                                        <p className="texto">No has creado ninguna terapia</p>
                                    </div>
                                    :
                                    <div className="terapias-grilla">
                                        <div className='input-buscar'>
                                            <input type="text" placeholder='Buscar paciente'
                                                onChange={this.handleChange}
                                                value={this.state.filter}
                                            />
                                        </div>
                                        <div></div>
                                        {list && list.length && list.map((terapia, i) => {
                                            let numPacientes = terapia.numeroPacientes ?? 0
                                            let numTareas = terapia.numeroTareas ?? 0
                                            return <div className="contenedor-terapia" key={terapia.uidDoc}>
                                                <img src={eliminar} alt={"x"} className="eliminar-terapia" onClick={() => {
                                                    this.setState({
                                                        eliminarTerapia: {
                                                            mostrar: true,
                                                            titulo: "Eliminar terapia",
                                                            descripcion: `Seguro que deseas eliminar la terapia: ${terapia.nombre}, recuerda que todas las tareas y actividades que creaste se eliminaran para siempre`,
                                                            boton1: "Cancelar",
                                                            boton2: "Aceptar",
                                                            click1: this.cerrarEliminar,
                                                            click2: () => this.eliminarTerapia(value, terapia.uidDoc, this.cerrarEliminar, this.cerrarDialogoUnBoton),
                                                        }
                                                    })
                                                }} />
                                                <div className="espacio-texto" onClick={() => history.push(`/terapeuta/terapias/${terapia.uidDoc}`)}>
                                                    <h3 className="nombre-terapia">{terapia.nombre}</h3>
                                                    <div className="tareas-pacientes">
                                                        <p className="texto">{numPacientes + ' Pacientes'}</p>
                                                        <p className="texto">{numTareas + ' Tareas'}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>)
                                : <div className="terapias-grilla">
                                    <SkeletonResponsive tipo={'rect'} className='sk-contenedor-terapia' />
                                    <SkeletonResponsive tipo={'rect'} className='sk-contenedor-terapia' />
                                    <SkeletonResponsive tipo={'rect'} className='sk-contenedor-terapia' />
                                    <SkeletonResponsive tipo={'rect'} className='sk-contenedor-terapia' />
                                    <SkeletonResponsive tipo={'rect'} className='sk-contenedor-terapia' />
                                    <SkeletonResponsive tipo={'rect'} className='sk-contenedor-terapia' />
                                </div>
                        }
                    </div>}
            </CargandoContext.Consumer>
        );
    }
}

Terapias.propTypes = {
    terapias: PropTypes.array,
    uid: PropTypes.string.isRequired,
};


export default withRouter(Terapias);