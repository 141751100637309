import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import TextoInterlineado from '../../../general/TextoInterlineado'

const Actividad = ({ actividad, editar, index, verDialogoArchivo }) => {

    const [actividadActual, setActividadActual] = useState(actividad)
    const [editarActividad, setEditarActividad] = useState(false)

    useEffect(() => {
        setActividadActual(actividad)
    }, [actividad])


    const pintarTipoActividad = (recurso, i) => {
        if (recurso.tipo === 0)
            return <a href={recurso.url} target="_blank" rel="noreferrer noopener" key={`recurso-${i}`}>{recurso.nombre ?? 'Documento Adjunto'}</a>

        if (recurso.tipo === 1) {
            if (recurso.url.includes('youtube.com') || recurso.url.includes('youtu.be')) {
                // let arUrl = recurso.url.split("/")
                // let url = arUrl[arUrl.length-1]
                return <div className="contenedor-recurso">
                    <ReactPlayer url={recurso.url} width="100%" height="10em" />
                </div>
                // return <iframe
                //     title="Video"
                //     src={`https://www.youtube.com/embed/${url}`}
                //     key={`recurso-${i}`} frameBorder="0" 
                //     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                //     allowFullScreen
                // />
            }
            else
                return <video className="video" key={`recurso-${i}`} alt="video actividad" controls>
                    <source src={recurso.url} type="video/mp4" />
                </video>
        }

        if (recurso.tipo === 2)
            return <img src={recurso.url} width="150" height="100" key={`recurso-${i}`} alt="imagen actividad" />

        return 'paila?'
    }

    const guardarCambios = () => {
        if (actividad === actividadActual) {
            setEditarActividad(false)
            return
        }
        if (!verificarArchivo()) return
        editar({ ...actividadActual, entregado: actividadActual.entregado ?? true })
        setEditarActividad(false)
        setActividadActual(actividad)
    }

    const verificarArchivo = () => {
        if (actividad.tipo === 3) {
            if (!actividadActual.adjunto && !actividadActual.archivoEntrega) {
                verDialogoArchivo()
                return false
            }
        }
        return true
    }

    const seleccionarTipo = (tipo) => {

        let entregado = actividad.entregado ?? false

        if (tipo === 0) return <div className="botones-crear-actividad con-editar">
            <div className="contenedor-marcar">
                <button
                    disabled={true}
                    className="btn-arketipos fondo-borde-verde btn-actividad"
                >
                    <span>{entregado && '✓'}</span>
                </button>
                <span>{`Actividad ${entregado ? '' : 'no '}realizada`}</span>
            </div>
            <button
                type="button"
                className="btn-arketipos fondo-borde-verde btn-actividad"
                onClick={() => setEditarActividad(true)}
            >
                Editar
            </button>
        </div>

        if (tipo === 1) return <div className="botones-crear-actividad con-editar">
            <div className="contenedor-marcar">
                <button
                    className="btn-arketipos fondo-borde-verde btn-actividad"
                    onClick={() => setActividadActual({ ...actividadActual, entregado: !actividadActual.entregado ?? true })}
                >
                    <span>{actividadActual.entregado && '✓'}</span>
                </button>
                <span>{actividadActual.entregado ? 'Actividad Realizada' : 'Marcar como realizada'}</span>
            </div>

            <button
                type="button"
                className="btn-arketipos fondo-borde-verde btn-actividad"
                onClick={() => guardarCambios()}
            >
                Guardar
            </button>
            <button
                type="button"
                className="btn-arketipos btn-actividad"
                onClick={() => {
                    setEditarActividad(false)
                    setActividadActual(actividad)
                }}
            >
                Cancelar
            </button>
        </div>

        if (tipo === 2 || tipo === 3) return <form className="contenedor-respuesta" onSubmit={() => guardarCambios()}>
            <div className="escribir-descripcion">
                <textarea placeholder="Escribe aquí..."
                    onChange={(e) => {
                        setActividadActual({ ...actividadActual, respuesta: e.target.value.replaceAll('\n', '\\n') })
                    }}
                    value={(actividadActual.respuesta ?? '').replaceAll('\\n', '\n')}
                    required={tipo === 2}
                >
                </textarea>
            </div>
            <div className="botones-crear-actividad">
                {
                    tipo === 3 ? <div className="contenedor-adjuntar">
                        <label
                            className="btn-arketipos fondo-borde-verde btn-actividad btn-adjuntar"
                        >
                            Adjuntar
                            <input type="file" required={!actividadActual.archivoEntrega ? true : false}
                                onChange={(e) => setActividadActual(
                                    { ...actividadActual, adjunto: e.target.files[0] }
                                )}
                            />
                        </label>
                        <div className="adjuntos">
                            {actividadActual.archivoEntrega || actividadActual.adjunto ?
                                <div>
                                    <span>
                                        {
                                            actividadActual.adjunto ?
                                                (actividadActual.adjunto.name ?? 'adjunto') :
                                                (actividadActual.archivoEntrega ?
                                                    (actividadActual.archivoEntrega.nombre ?? 'adjunto')
                                                    : '')
                                        }
                                    </span>
                                    <button className="circulo-x" onClick={() => setActividadActual({
                                        ...actividadActual, archivoEntrega: null
                                    })}>x</button>
                                </div> : ''}
                        </div>
                    </div> : <div />
                }
                <button
                    className="btn-arketipos fondo-borde-verde btn-actividad"
                    type="submit"
                    onClick={verificarArchivo}
                >
                    Guardar
                </button>
                <button
                    type="button"
                    className="btn-arketipos btn-actividad"
                    onClick={() => {
                        setEditarActividad(false)
                        setActividadActual(actividad)
                    }}
                >
                    Cancelar
                </button>
            </div>
        </form>

        return ''
    }
    return (
        <div className="con-tareas contenedor-actividades">

            <TextoInterlineado
                className="texto"
                texto={actividad.descripcion}
            // numero={index + 1}
            />
            <div className="contenedor-recursos">
                {actividad.recursos.map((r, i) => {

                    return pintarTipoActividad(r, i)
                })}
            </div>
            {seleccionarTipo(!editarActividad ? 0 : actividad.tipo ?? 0)}
        </div>
    )
}

Actividad.propTypes = {
    actividad: PropTypes.object.isRequired,
    editar: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    verDialogoArchivo: PropTypes.func.isRequired,
}

export default Actividad
