import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { Route, Switch, withRouter } from 'react-router-dom';
import HeaderApp from '../general/HeaderApp';
import DetalleTerapia from "./components/detalleTerapia/DetalleTerapia";
import Menu from "../general/Menu/Menu"
import Resumen from "./components/resumen/Resumen";
import Terapias from "./components/terapias/Terapias";
import Citas from "./../general/citas/Citas";
import Perfil from '../general/Perfil/Perfil';
import { getPaciente, getCitas, getTerapias, getCargandoPaciente, getCargandoTerapias, getCargandoCitas } from "../../reducers/pacienteReducer";
import { getUserInfo } from "../../reducers/sesionReducer";
import * as actions from "../../actions/pacienteActions";
import { CargandoContext } from '../general/CargandoContext/CargandoContext';
import PerfilTerapeuta from './components/perfilTerapeuta/PerfilTerapeuta';
import LoadingIndicator from '../general/loadingIndicator/LoadingIndicator';
import Chat from '../general/Chat';

class Paciente extends Component {

    state = {
        cargando: false
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { citas, getInfoPaciente, getDBCitasPaciente, sesion, paciente, 
            cargandoPaciente, cargandoTerapias, cargandoCitas
        } = this.props;
        if(sesion){
            if (!paciente && sesion.uidDocumento && (!cargandoPaciente || !cargandoTerapias)) {
                getInfoPaciente(sesion.uidDocumento)
            }
            if(!citas && sesion.uidDocumento && !cargandoCitas) {
                getDBCitasPaciente(sesion.uidDocumento)
            }
        }
    }

    render() {
        let { nombre, psicologos } = this.props.paciente ?? {}
        let listaTerapias = this.props.terapias
        let listaTerapeutas = psicologos
        let ruta = this.props.location.pathname.split("/")
        let idTerapia = ruta[ruta.length - 1]
        return (
            <CargandoContext.Provider value={{
                correrIndicadorCarga: () => this.setState({ cargando: true }),
                quitarIndicadorCarga: () => this.setState({ cargando: false }),
            }}>
                <div className="pantalla-pacientes">
                    <HeaderApp />
                    <Menu usuario='paciente' />
                    <hr className='linea-menu' />
                    <div className="contenido-paciente">
                        <Switch>
                            <Route path="/paciente/perfil">
                                <Perfil
                                    usuario='paciente'
                                    profileInfo={this.props.paciente}
                                    updateInfo={actions.updatePacienteProfileInfo}/>
                            </Route>
                            <Route path="/paciente/terapias/:idTerapia">
                                <DetalleTerapia terapia={idTerapia} />
                            </Route>
                            <Route path="/paciente/terapias">
                                <Terapias
                                    idPaciente={this.props.sesion ? this.props.sesion.uidDocumento : ''}
                                    terapias={listaTerapias}
                                />
                            </Route>
                            <Route path="/paciente/citas">
                                <Citas
                                    usuario='paciente'
                                    citas={this.props.citas} />
                            </Route>
                            <Route path="/paciente/terapeuta/:id">
                                <PerfilTerapeuta/>
                            </Route>
                            <Route path="/paciente/chat">
                                <Chat usuario="paciente"/>
                            </Route>
                            <Route path="/paciente">
                                <Resumen nombre={nombre} terapias={listaTerapias} terapeutas={listaTerapeutas} />
                            </Route>
                        </Switch>
                    </div>
                    {
                        this.state.cargando &&
                        <LoadingIndicator
                            color='rgb(46, 197, 115)'/>
                    }
                </div>
            </CargandoContext.Provider>

        );
    }
}

const mapStateToProps = state => ({
    paciente: getPaciente(state),
    citas: getCitas(state),
    sesion: getUserInfo(state),
    terapias: getTerapias(state),
    cargandoPaciente: getCargandoPaciente(state),
    cargandoTerapias: getCargandoTerapias(state),
    cargandoCitas: getCargandoCitas(state),
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Paciente));