import React, { useState, useEffect, useContext, useRef } from 'react';
import { ERROR, SIN_FOTO } from '../../../utilidades/Constantes';
import { CargandoContext } from '../CargandoContext/CargandoContext';
import SkeletonResponsive from '../SkeletonResponsive';
// import PropTypes from 'prop-types';
import PerfilImgAdmin from './../../../assets/ic_foto_perfil_azul.svg';
import PerfilImgPaciente from './../../../assets/ic_foto_perfil_verde.svg';
import PerfilImgTerapeuta from './../../../assets/ic_foto_perfil_naranja.svg';
import { formatoImagen } from '../../../utilidades/formato';
import { ERROR_FORMATO_IMAGEN } from '../../../utilidades/Constantes'
import { cambiarImagenPerfil } from '../../../actions/sesionActions';

const Perfil = ({ profileInfo, updateInfo, usuario }) => {

    let [editar, setEditar] = useState(false)
    let [nombre, setNombre] = useState(null)
    let [correo, setCorreo] = useState(null)
    let [telefono, setTelefono] = useState(null)
    let [descripcion, setDescripcion] = useState(null)
    let [error, setError] = useState(null)

    let seleccionarImagen = useRef()

    let sinFoto;

    switch (usuario) {
        case 'administrador':
            sinFoto = PerfilImgAdmin
            break
        case 'paciente':
            sinFoto = PerfilImgPaciente
            break
        case 'terapeuta':
            sinFoto = PerfilImgTerapeuta
            break
        default:
            break;
    }

    useEffect(() => {
        setNombre(profileInfo ? profileInfo.nombre : null)
        setCorreo(profileInfo ? profileInfo.correo : null)
        setTelefono(profileInfo ? profileInfo.telefono : null)
        setDescripcion(profileInfo ? profileInfo.descripcion : null)
    }, [profileInfo])

    let { correrIndicadorCarga, quitarIndicadorCarga } = useContext(CargandoContext)

    const handleClickCambiarImagen = () => {
        seleccionarImagen.current.click();
    };
    const handleNuevaImagen = async () => {
        if (seleccionarImagen.current.files.length)
            if(formatoImagen(seleccionarImagen.current.files[0])) {
                correrIndicadorCarga()
                let res = await cambiarImagenPerfil(seleccionarImagen.current.files[0], profileInfo.uidDocumento ?? profileInfo.uidDoc, usuario)
                quitarIndicadorCarga()

                if(res === ERROR)
                    setError("Hubo un inconveniente, por favor intentalo más tarde")
            } else {
                setError(ERROR_FORMATO_IMAGEN)
            }
    }

    const handleChange = ({ target }) => {
        switch (target.name) {
            case 'nombre':
                setNombre(target.value)
                break;
            case 'correo':
                setCorreo(target.value)
                break;
            case 'telefono':
                setTelefono(target.value)
                break;
            case 'descripcion':
                setDescripcion(target.value)
                break;
            default:
                break;
        }
    }

    const handleOnSaveClick = async () => {
        if(editar) {
            const { uidDocumento, uidDoc } = profileInfo
            setError(null)
            correrIndicadorCarga()
            let res = await updateInfo({ 
                uidDocumento: uidDocumento ?? uidDoc,
                correo: correo ?? "",
                descripcion: descripcion ?? "" , 
                nombre: nombre ?? "",
                telefono: telefono?? ""
            })
            quitarIndicadorCarga()
            if(res === ERROR) {
                tomarValoresIniciales()
                setError('Hubo un error, por favor intentalo más tarde')
            }
        }
        setEditar(!editar)
    }

    const tomarValoresIniciales = () => {
        setNombre(profileInfo ? profileInfo.nombre : null)
        setCorreo(profileInfo ? profileInfo.correo : null)
        setTelefono(profileInfo ? profileInfo.telefono : null)
        setDescripcion(profileInfo ? profileInfo.descripcion : null)
    }

    return (
            <div className="perfil-admin">
                {
                    profileInfo ? 
                    <div className='seccion-foto-cambiar'>
                        <img
                            className='img-perfil-admin'
                            src={
                                // TODO: Quitar verificacion de null
                                profileInfo.urlFotoPerfil ?
                                    profileInfo.urlFotoPerfil === SIN_FOTO ? sinFoto : profileInfo.urlFotoPerfil:
                                    sinFoto
                            }
                            alt="Imagen de perfil" />
                        <button
                            className={`btn-arketipos btn-${usuario}`}
                            onClick={handleClickCambiarImagen}>Cambia tu foto</button>
                        <input type="file" onChange={handleNuevaImagen}
                           accept="image/*" ref={seleccionarImagen} style={{display: 'none'}}/>
                    </div>:
                    <SkeletonResponsive tipo='rect' className='sk-seccion-foto-cambiar'/>
                }
                <div className="contenedor-perfil">
                    <div className="titulo-perfil-admin">
                        Tu perfil
                        <hr className={`background-${usuario}`}/>
                    </div>
                    <div className="fila-dato">
                        <p className="campo">Nombre:</p>
                        {
                            profileInfo ? 
                                editar ?
                                    <input name="nombre" className={`valor editable editable-${usuario}`} value={nombre} onChange={handleChange}/>:
                                    <p className="valor">{nombre}</p>:
                                    <SkeletonResponsive tipo='rect' className='sk-valor'/>
                        }
                    </div>
                    <div className="fila-dato">
                        <p className="campo">Correo:</p>
                        {
                            profileInfo?
                                editar ?
                                    <input name="correo" className={`valor editable editable-${usuario}`} value={correo} onChange={handleChange}/>:
                                    <p className="valor">{correo}</p>:
                                    <SkeletonResponsive tipo='rect' className='sk-valor'/>
                        }

                    </div>
                    <div className="fila-dato">
                        <p className="campo">Celular:</p>
                        {
                            profileInfo ?
                                editar ?
                                    <input name="telefono" className={`valor editable editable-${usuario}`} value={telefono} onChange={handleChange}/>:
                                    <p className="valor">{telefono || '-'}</p>:
                                    <SkeletonResponsive tipo='rect' className='sk-valor'/>
                        }
                    </div>
                    <div className="fila-dato">
                        <p className="campo">Descripción:</p>
                        {
                            profileInfo ?
                                editar ?
                                    <textarea name="descripcion" rows="8" className={`valor editable editable-${usuario}`}
                                        value={descripcion} onChange={handleChange}/>:
                                    <p className="valor">{descripcion}</p>:
                                <SkeletonResponsive tipo='rect' className='sk-descripcion'/>
                        }
                    </div>
                    {
                        error &&
                        <div className='error'>
                            {error}
                        </div>
                    }
                    {
                        profileInfo ? 
                            <button onClick={handleOnSaveClick} className={`btn-arketipos btn-${usuario}`}>{editar ? "Guardar Cambios" : "Editar Perfil"}</button>:
                            <SkeletonResponsive tipo='rect' className='sk-btn-azul'/>
                    }
                </div>
            </div>
    );
    
}

export default Perfil;