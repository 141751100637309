import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DialogoDosBotones from '../../../general/DialogoDosBotones'

const FormularioServicio = ({onSubmit, integrante, nuevo, eliminar}) => {

    const [documento, setDocumento] = useState(nuevo ? integrante : null)
    const [preguntar, setPregunta] = useState(false)

    const handleChangeHome = (e) => {
        setDocumento({
            ...documento,
            [e.target.name]: e.target.value.replaceAll('\n', '\\n')
        })
    }

    if(documento)
        return (
            <form className="flex flex-column" onSubmit={(e) => {
                delete documento.uidDoc
                onSubmit(e, integrante.uidDoc, documento)
            }}>
                <label className="flex flex-column campo-landing">
                    <span>Nombre</span>
                    <input type="text" name="nombre" onChange={handleChangeHome} value={documento.nombre??''} required/>
                </label>
                
                <label className="flex flex-column campo-landing">
                    <span>Descripción</span>
                    <textarea name="descripcion" onChange={handleChangeHome} value={(documento.descripcion??'').replaceAll("\\n", "\n")} required/>
                </label>
                <br/><br/>
                <b>Arketipo</b>
                <label className="flex opcion-arketipo">
                    <input type="radio" name="arketipo" onChange={handleChangeHome} checked={documento.arketipo === "creador"} value="creador" required/>
                    <span>Creador</span>
                </label>
                <label className="flex opcion-arketipo">
                    <input type="radio" name="arketipo" onChange={handleChangeHome} checked={documento.arketipo === "aventurero"} value="aventurero" required/>
                    <span>Aventurero</span>
                </label>
                <label className="flex opcion-arketipo">
                    <input type="radio" name="arketipo" onChange={handleChangeHome} checked={documento.arketipo === "sabio"} value="sabio" required/>
                    <span>Sabio</span>
                </label>
                <label className="flex opcion-arketipo">
                    <input type="radio" name="arketipo" onChange={handleChangeHome} checked={documento.arketipo === "ciudador"} value="ciudador" required/>
                    <span>Ciudador</span>
                </label>
                <label className="flex opcion-arketipo">
                    <input type="radio" name="arketipo" onChange={handleChangeHome} checked={documento.arketipo === "amigo"} value="amigo" required/>
                    <span>Amigo</span>
                </label>
                <label className="flex opcion-arketipo">
                    <input type="radio" name="arketipo" onChange={handleChangeHome} checked={documento.arketipo === "sonador"} value="sonador" required/>
                    <span>Soñador</span>
                </label><label className="flex opcion-arketipo">
                    <input type="radio" name="arketipo" onChange={handleChangeHome} checked={documento.arketipo === "divertido"} value="divertido" required/>
                    <span>Divertido</span>
                </label>
                <label className="flex opcion-arketipo">
                    <input type="radio" name="arketipo" onChange={handleChangeHome} checked={documento.arketipo === "amante"} value="amante" required/>
                    <span>Amante</span>
                </label>
                <label className="flex opcion-arketipo">
                    <input type="radio" name="arketipo" onChange={handleChangeHome} checked={documento.arketipo === "heroe"} value="heroe" required/>
                    <span>Heroe</span>
                </label><label className="flex opcion-arketipo">
                    <input type="radio" name="arketipo" onChange={handleChangeHome} checked={documento.arketipo === "rebelde"} value="rebelde" required/>
                    <span>Rebelde</span>
                </label>
                <label className="flex opcion-arketipo">
                    <input type="radio" name="arketipo" onChange={handleChangeHome} checked={documento.arketipo === "lider"} value="lider" required/>
                    <span>Lider</span>
                </label>
                <label className="flex opcion-arketipo">
                    <input type="radio" name="arketipo" onChange={handleChangeHome} checked={documento.arketipo === "mago"} value="mago" required/>
                    <span>Mago</span>
                </label>
                <div>
                    <button className="btn-arketipos fondo-azul">Guardar Cambios</button>
                    {!nuevo && <button type="button" className="btn-arketipos boton-eliminar" onClick={()=>setDocumento(null)}>Cancelar</button>}
                </div>
                <br/>
                <hr className="separador-landing separador-landing-gris"/>
                <br/>
            </form>
        )

    return (
        <div className="flex flex-column">
            {preguntar && <DialogoDosBotones 
                titulo="¿Seguro?"
                descripcion="¿Está seguro de querer eliminar este servicio?"
                boton1="Si"
                boton2="No" 
                click2={()=>setPregunta(false)}
                click1={()=>{
                    eliminar(integrante.uidDoc)
                    setPregunta(false)
                }}
                cerrar={()=>setPregunta(false)}
            />}
            <div className="flex flex-column campo-landing">
                <span><b>Nombre</b></span>
                <div>{integrante.nombre??''}</div>
            </div>
            
            <div className="flex flex-column campo-landing">
                <span><b>Descripción</b></span>
                <div>{integrante.descripcion??''}</div>
            </div>
            <br/><br/>
            <div className="flex flex-column campo-landing">
                <span><b>Arketipo</b></span>
                <div>{integrante.arketipo??''}</div>
            </div>
            <div>
                <button className="btn-arketipos fondo-azul" onClick={()=>setDocumento(integrante)}>Editar</button>
                {!nuevo && <button type="button" className="btn-arketipos boton-eliminar" onClick={()=>setPregunta(true)}>Eliminar</button>}
            </div>
            <br/>
            <hr className="separador-landing separador-landing-gris"/>
            <br/>
        </div>
    )
}

FormularioServicio.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    integrante: PropTypes.object.isRequired,
    nuevo: PropTypes.bool.isRequired,
    eliminar: PropTypes.func.isRequired,
}

export default FormularioServicio
