import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getCargandoActividades,
  getPaciente,
  getTerapia,
} from "../../../../reducers/pacienteReducer";
import {
  getDetalleTerapia,
  getActividades,
  guardarActividad,
} from "../../../../actions/pacienteActions";
import { withRouter } from "react-router-dom";
import Actividad from "./Actividad";
import { CargandoContext } from "../../../general/CargandoContext/CargandoContext";
import SkeletonResponsive from "../../../general/SkeletonResponsive";
import { deleteTerapiaActual } from "../../../../actions/terapeutaActions";
import DialogoUnBoton from "../../../general/DialogoUnBoton";

class DetalleTerapia extends React.Component {
  state = {
    dialogoArchivo: false,
  };

  componentDidMount() {
    let { terapia, traerTerapia } = this.props;
    traerTerapia(terapia);
  }

  seleccionarTarea = (tareas, id) => {
    let tarea = null;
    tareas.forEach((tareaActual) => {
      if (tareaActual.uidDoc === id) tarea = tareaActual;
    });
    return tarea;
  };

  componentDidUpdate(prevProps, prevState) {
    let { terapia, detalleTerapia, traerActividades, cargandoActividades } =
      this.props;
    if (detalleTerapia) {
      if (
        detalleTerapia.uidTarea &&
        !detalleTerapia.actividades &&
        !cargandoActividades
      ) {
        traerActividades({
          terapia: terapia,
          tarea: detalleTerapia.uidTarea ?? "",
        });
      }
    }
  }

  guardarEdicion = async (actividad, cargando) => {
    let { terapia, detalleTerapia, paciente } = this.props;

    cargando.correrIndicadorCarga();

    console.log(this.props);

    let tarea = {};
    let tareas = detalleTerapia.tareas ?? [];
    tareas.forEach((t) => {
      if (t.uidDoc === detalleTerapia.uidTarea) {
        tarea = t;
      }
    });

    console.log(tarea);

    await guardarActividad({
      tarea: detalleTerapia.uidTarea,
      terapia: terapia,
      terapeuta: detalleTerapia.idPsicologo,
      paciente: detalleTerapia.idPaciente,
      actividad: actividad,
      correo: {
        nombrePaciente: paciente.nombre ?? "",
        nombreTerapia: detalleTerapia.nombre ?? "",
        nombreTarea: tarea.nombre ?? "",
      },
    });

    cargando.quitarIndicadorCarga();
  };

  pintarActividades = (listaActividades, cargando) =>
    listaActividades
      .sort((a, b) => {
        if ((a.index ?? 0) < (b.index ?? 0)) return -1;
        return 1;
      })
      .map((actividad, i) => {
        console.log("*******", actividad);
        return (
          <Actividad
            actividad={actividad}
            index={i}
            key={`actividad-${i}`}
            editar={(actividad) => this.guardarEdicion(actividad, cargando)}
            verDialogoArchivo={() =>
              this.setState({
                dialogoArchivo: true,
              })
            }
          />
        );
      });

  render() {
    let { detalleTerapia, history, quitarTerapia } = this.props;
    let terapia = detalleTerapia ?? {};
    let tareas = terapia.tareas;
    let actividades = terapia.actividades;

    return (
      <CargandoContext.Consumer>
        {(value) => (
          <div className="detalle-terapia-paciente">
            {this.state.dialogoArchivo && (
              <DialogoUnBoton
                titulo="Falta archivo"
                descripcion="Debes adjuntar un archivo para guardar esta tarea."
                cerrar={() => this.setState({ dialogoArchivo: false })}
                click1={() => this.setState({ dialogoArchivo: false })}
                boton1="Entendido"
                color="verde"
              />
            )}
            <div
              className="fila-atras"
              onClick={() => {
                history.goBack();
                quitarTerapia();
              }}
            >
              {detalleTerapia ? (
                <button className="btn-arketipos btn-paciente btn-verde">
                  ←
                </button>
              ) : (
                <SkeletonResponsive tipo={"rect"} className="sk-btn-atras" />
              )}
              {detalleTerapia ? (
                <h5 className="texto-atras">Tus terapias</h5>
              ) : (
                <SkeletonResponsive tipo={"rect"} className="sk-texto-atras" />
              )}
            </div>
            <div className="contenedor-terapia">
              {detalleTerapia ? (
                <h1 className="titulo-terapias">
                  {terapia.nombre ?? "Cargando"}
                </h1>
              ) : (
                <SkeletonResponsive
                  tipo={"rect"}
                  className="sk-titulo-terapias"
                />
              )}
              {detalleTerapia ? (
                <p className="descripcion-terapia">
                  {terapia.descripcion ?? "Cargando"}
                </p>
              ) : (
                <div className="sk-fila-texto">
                  <SkeletonResponsive
                    tipo={"rect"}
                    className="sk-descripcion-terapia-1"
                  />
                  <SkeletonResponsive
                    tipo={"rect"}
                    className="sk-descripcion-terapia-2"
                  />
                </div>
              )}
              <div className="contenedor-tareas">
                {tareas ? (
                  tareas.length > 0 && actividades?.length > 0 ? (
                    this.pintarActividades(actividades ?? [], value)
                  ) : (
                    <div className="sin-tareas">
                      <p className="texto">
                        Tu terapeuta aún no te ha seleccionado una actividad
                        para realizar
                      </p>
                    </div>
                  )
                ) : (
                  <div>
                    <SkeletonResponsive tipo={"rect"} className="sk-tarea" />
                    <SkeletonResponsive tipo={"rect"} className="sk-tarea" />
                    <SkeletonResponsive tipo={"rect"} className="sk-tarea" />
                    <SkeletonResponsive tipo={"rect"} className="sk-tarea" />
                    <SkeletonResponsive tipo={"rect"} className="sk-tarea" />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </CargandoContext.Consumer>
    );
  }
}

DetalleTerapia.propTypes = {
  terapia: PropTypes.string.isRequired,
  traerTerapia: PropTypes.func,
  quitarTerapia: PropTypes.func,
};

const mapStateToProps = (state) => ({
  detalleTerapia: getTerapia(state),
  cargandoActividades: getCargandoActividades(state),
  paciente: getPaciente(state),
});

const mapDispatchToProps = (dispatch) => ({
  traerTerapia: (datos) => dispatch(getDetalleTerapia(datos)),
  traerActividades: (datos) => dispatch(getActividades(datos)),
  quitarTerapia: (datos) => dispatch(deleteTerapiaActual(datos)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DetalleTerapia)
);
