import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createAd, createMember, deleteAd, deleteMember, getHome, saveHomeDocument, updateAd, updateMember } from '../../../actions/landingActions'
import { getAdsState, getEquipoState, getHomeState, getLoadingHome } from '../../../reducers/landingReducer'
import FormualrioCarrusel from './componentes/FormualrioCarrusel'
import FormularioIntegrante from './componentes/FormularioIntegrante'

const FormualrioHome = ({
    home, equipo, anuncios, traerHome, 
    cargando, guardarHome, actualizarAnuncio, crearAnuncio, eliminarAnuncio, 
    actualizarIntegrante, crearIntegrante, eliminarIntegrante
}) => {

    const [documentoHome, setDocumentoHome] = useState({})
    const [agregarAnuncio, setAgregarAnuncio] = useState(false)
    const [agregarIntegrante, setAgregarIntegrante] = useState(false)
    const [seccionActual, setSeccionActual] = useState(0)

    useEffect(() => {
        if((!home || !equipo || !anuncios) && !cargando) traerHome()

        if(home && !cargando){
            setDocumentoHome({
                tituloGrande: home.tituloGrande,
                tituloPequeno: home.tituloPequeno,
                tituloSegundaSeccion: home.tituloSegundaSeccion,
                textoSegundaSeccion: home.textoSegundaSeccion,
                imagenSegundaSeccion: home.imagenSegundaSeccion,
            })
        }

        // eslint-disable-next-line
    }, [home])

    const handleChangeHome = (e) => {
        setDocumentoHome({
            ...documentoHome,
            [e.target.name]: e.target.value.replaceAll('\n', '\\n')
        })
    }
    const handleChangeFileHome = (e) => {
        if(e.target.files[0])
            setDocumentoHome({
                ...documentoHome,
                imagenArchivo: e.target.files[0],
                urlAnterior: home.imagenSegundaSeccion,
                imagenSegundaSeccion: URL.createObjectURL(e.target.files[0])
            })
    }

    const guardarDocumentoHome = (e) => {
        e.preventDefault()
        guardarHome(documentoHome)
    }

    const guardarAnuncio = (e, id, documento) => {
        e.preventDefault()
        actualizarAnuncio(id, documento)
    }

    const crearNuevoAnuncio = (e, id, documento) => {
        e.preventDefault()
        crearAnuncio(documento)
        setAgregarAnuncio(false)
    }
    const eliminarAd = (id, documento) => {
        eliminarAnuncio(id, documento)
    }

    const guardarIntegrante = (e, id, documento) => {
        delete documento.imagen
        e.preventDefault()
        actualizarIntegrante(id, documento)
    }

    const crearNuevoIntegrante = (e, id, documento) => {
        e.preventDefault()
        crearIntegrante(documento)
        setAgregarIntegrante(false)
    }
    const eliminarMember = (id, documento) => {
        eliminarIntegrante(id, documento)
    }

    return (
        <div className="contenedor-formualrios-landing center ai-start flex-column">

            <hr className="separador-landing"/>

            <div className="center contenedor-opciones-admin-landing opciones-opciones">
                <button onClick={()=>setSeccionActual(0)} className={`opciones-admin-landing base-boton ${seccionActual === 0 && 'seleccionada'}`}>Sección informativa</button>
                <button onClick={()=>setSeccionActual(1)} className={`opciones-admin-landing base-boton ${seccionActual === 1 && 'seleccionada'}`}>Anuncios</button>
                <button onClick={()=>setSeccionActual(2)} className={`opciones-admin-landing base-boton ${seccionActual === 2 && 'seleccionada'}`}>Equipo</button>
            </div>

            <hr className="separador-landing"/>

            {seccionActual === 0 && <form className="flex flex-column" onSubmit={guardarDocumentoHome}>
                <h3>Sección azul</h3>
                <label className="flex flex-column campo-landing">
                    <span>Título </span>
                    <input type="text" name="tituloGrande" onChange={handleChangeHome} value={documentoHome.tituloGrande??''}/>
                </label>
                <label className="flex flex-column campo-landing">
                    <span>Frase</span>
                    <input type="text" name="tituloPequeno" onChange={handleChangeHome} value={documentoHome.tituloPequeno??''}/>
                </label>
                <br/><br/>
                <h3>Sección de infromación de la plataforma</h3>
                <label className="flex flex-column campo-landing">
                    <span>Título</span>
                    <input type="text" name="tituloSegundaSeccion" onChange={handleChangeHome} value={documentoHome.tituloSegundaSeccion??''}/>
                </label>
                <label className="flex flex-column campo-landing">
                    <span>Descripción</span>
                    <textarea name="textoSegundaSeccion" onChange={handleChangeHome} value={(documentoHome.textoSegundaSeccion??'').replaceAll("\\n", "\n")}/>
                </label>
                <label className="flex flex-column campo-landing">
                   <div className="flex ai-center jc-start">
                        <span className="btn-arketipos fondo-terapeuta center">Seleccionar Imagen</span>
                        <span>{ documentoHome.imagenArchivo ? documentoHome.imagenArchivo.name : 'Sin imagen seleccionada'}</span>
                   </div>
                    <input type="file" accept="image/*" name="imagenArchivo" onChange={handleChangeFileHome}/>
                    <br/>
                    <a href={documentoHome.imagenSegundaSeccion??''} target="noreferrer">Ver Imagen</a>
                </label>
                <button className="btn-arketipos fondo-azul">Guardar Cambios</button>
            </form>}
            {/* {seccionActual === 0 && <div><br/><br/></div>}
            {seccionActual === 0 && <hr className="separador-landing"/>}
            <br/><br/> */}
            {seccionActual === 1 && <div>
                <h3>Anuncios</h3>
                {(anuncios??[]).map((anuncio, i) => {
                    return <FormualrioCarrusel 
                        key={`anuncio-${i}`}
                        anuncio={anuncio}
                        nuevo={false}
                        onSubmit={guardarAnuncio}
                        eliminar={eliminarAd}
                    />
                })}
                {agregarAnuncio && <FormualrioCarrusel 
                        anuncio={{}}
                        nuevo={true}
                        onSubmit={crearNuevoAnuncio}
                        eliminar={()=>{}}
                    />}
                <button className="btn-arketipos fondo-azul" onClick={()=>setAgregarAnuncio(!agregarAnuncio)}>
                    {!agregarAnuncio ? 'Agregar nuevo anuncio' : 'Cancelar'}
                </button>
            </div>}

            {/* <br/><br/>
            <hr className="separador-landing"/>
            <br/><br/> */}
            {seccionActual === 2 && <div>
                <h3>Equipo</h3>
                {(equipo??[]).map((integrante, i) => {
                    return <FormularioIntegrante 
                        key={`anuncio-${i}`}
                        integrante={integrante}
                        nuevo={false}
                        onSubmit={guardarIntegrante}
                        eliminar={eliminarMember}
                    />
                })}
                {agregarIntegrante && <FormularioIntegrante 
                        integrante={{}}
                        nuevo={true}
                        onSubmit={crearNuevoIntegrante}
                        eliminar={()=>{}}
                    />}
                <button className="btn-arketipos fondo-azul" onClick={()=>setAgregarIntegrante(!agregarIntegrante)}>
                    {!agregarIntegrante ? 'Agregar nuevo integrante' : 'Cancelar'}
                </button>
            </div>}

        </div>
    )
}

const mapStateToProps = (state) => ({
    home: getHomeState(state),
    equipo: getEquipoState(state),
    anuncios: getAdsState(state),
    cargando: getLoadingHome(state),
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
    traerHome: getHome,
    guardarHome: saveHomeDocument,
    actualizarAnuncio: updateAd,
    crearAnuncio: createAd,
    eliminarAnuncio: deleteAd,
    actualizarIntegrante: updateMember,
    crearIntegrante: createMember,
    eliminarIntegrante: deleteMember,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(FormualrioHome)
