import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPsicologoActual } from '../../../../reducers/adminReducer';
import SkeletonResponsive from '../../../general/SkeletonResponsive';

const PacientesTerapeuta = ({psicologo, history}) => {
    return (
        <div className='contenedor-pacientes-terapeuta'>
            <div className='path-usuario-admin' onClick={()=>history.push(`/administrador/terapeutas/${psicologo.uidDoc}`)}>
                <div className='flecha-decoracion'>
                    <span>&larr;</span>
                </div>
                {
                    psicologo ? 
                        <b>{`Terapeuta: ${psicologo?.nombre}`}</b>:
                        <>
                            <b>{`Terapeuta:`}</b>
                            <SkeletonResponsive tipo='rect' className='sk-nombre-terapeuta'/>
                        </>
                }
            </div>
            {
                psicologo ?
                    psicologo.pacientes?.map(paciente => <div key={paciente.uidDoc} onClick={()=>history.push(`/administrador/terapeutas/${psicologo.uidDoc}/pacientes/${paciente.uidDoc}`)} className='contenedor-paciente'>
                        <b>{paciente.nombre}</b>
                        <div>{`${paciente.numeroTerapias || 0} Terapias`}</div>
                    </div>):
                    [1, 2, 3].map(i => <SkeletonResponsive key={i} tipo='rect' className='sk-contenedor-paciente' borderRadius='0.75em'/>)
            }
        </div>
    )
}

const mapStateToProps = (state, props) => ({
    psicologo: getPsicologoActual(state, props.match.params.id)
});

export default withRouter(connect(mapStateToProps, null)(PacientesTerapeuta))
