import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import SkeletonResponsive from '../../../general/SkeletonResponsive';
import PerfilImg from './../../../../assets/ic_foto_perfil_azul.svg';
import * as actions from '../../../../actions/adminActions';
import { connect } from 'react-redux';
import { CargandoContext } from '../../../general/CargandoContext/CargandoContext';
import { SIN_FOTO } from '../../../../utilidades/Constantes';

const Gestionar = ({ usuarios, pacientes, asignarTerapeutaHandleClick,
                     setUsuarioPorEliminar, activarUsuario, desactivarUsuario,
                     handleAgregarTerapeuta, history  }) => {

    let usuariosCongelados = []
    let usuariosSinCongelar = []

    let { correrIndicadorCarga, quitarIndicadorCarga } = useContext(CargandoContext)

    usuarios && usuarios.forEach( usuario => {
        if(usuario.congelado)
            usuariosCongelados.push(usuario)
        else 
            usuariosSinCongelar.push(usuario)
    })

    const congelarUsuario = async (paciente, uid, usuario) => {
        correrIndicadorCarga()
        await desactivarUsuario({paciente, uid, usuario})
        quitarIndicadorCarga()
    }

    const descongelarUsuario = async (paciente, uid) => {
        correrIndicadorCarga()
        await activarUsuario({paciente, uid})
        quitarIndicadorCarga()
    }

    return (
        usuarios ? 
                <div className='gestionar-panel'>
                    {
                        !pacientes &&
                        <button 
                            className="btn-arketipos btn-agregar-terapeuta"
                            onClick={handleAgregarTerapeuta}>
                            Agregar terapeuta
                        </button>
                    }
                    {
                        usuariosSinCongelar.map((usuario, index) => (
                            <div
                                key={usuario.uidDoc}
                                className="gestionar-item"
                                style={{
                                    borderBottom: index === pacientes.length - 1 ? 'none':'0.01px solid grey' 
                                }}>
                                <div className={pacientes ? 'gestionar-nombre-img':'gestionar-nombre-img-terapeuta'}>
                                    <img className='profile-gestionar-img' src={
                                            // TODO: Quitar verificacion de null
                                            usuario.urlFotoPerfil ?
                                                usuario.urlFotoPerfil === SIN_FOTO ? PerfilImg : usuario.urlFotoPerfil:
                                                PerfilImg
                                        }  alt="Imagen de perfil" />
                                    <div className='nombre-usuario'>{usuario.nombre}</div>
                                </div>
                                {
                                    pacientes ?
                                    <div className='gestionar-item-btns'>
                                        <button id='btn-asignar' className='btn-azul btn-gestionar btn-arketipos'
                                            style={{ visibility: pacientes ? "visible" : "hidden" }}
                                            onClick={() => asignarTerapeutaHandleClick(usuario)}>Asignar</button>
                                        <button id='btn-perfil' className='btn-borde-negro btn-gestionar btn-arketipos' onClick={
                                            () => history.push(`/administrador/pacientes/${usuario.uidDoc}?origen=Pacientes`)
                                        }>Ver perfil</button>
                                        <button id='btn-eliminar' className='btn-gestionar btn-arketipos' onClick={() => congelarUsuario(pacientes, usuario.uidDoc, usuario)}>Congelar</button>
                                    </div>:
                                    <div className='gestionar-item-btns'>
                                        <button id='btn-perfil' className='btn-azul btn-gestionar btn-arketipos' onClick={
                                            () => history.push(`/administrador/terapeutas/${usuario.uidDoc}`)
                                        }>Ver perfil</button>
                                        <button id='btn-eliminar' className='btn-gestionar btn-arketipos' onClick={() => congelarUsuario(pacientes, usuario.uidDoc, usuario)}>Congelar</button>
                                    </div>
                                }
                            </div>
                        ))
                    }
                    {
                        usuariosCongelados.map(usuario => (
                            <div 
                                key={usuario.uidDoc}
                                className="gestionar-item congelado">
                                <div className={pacientes ? 'gestionar-nombre-img':'gestionar-nombre-img-terapeuta'}>
                                    <img 
                                        className='profile-gestionar-img' 
                                        src={
                                            // TODO: Quitar verificacion de null
                                            usuario.urlFotoPerfil ?
                                                usuario.urlFotoPerfil === SIN_FOTO ? PerfilImg : usuario.urlFotoPerfil:
                                                PerfilImg
                                        } 
                                        alt="Imagen de perfil" />
                                    <div className='nombre-usuario'>{`${usuario.nombre} (Congelado)`}</div>
                                </div>
                                {
                                    pacientes ?
                                    <div className='gestionar-item-btns'>
                                        <button id='btn-asignar' className='btn-azul btn-gestionar btn-arketipos'
                                            onClick={() => descongelarUsuario(pacientes, usuario.uidDoc)}>Activar</button>
                                        <button id='btn-perfil' className='btn-borde-negro btn-gestionar btn-arketipos' onClick={
                                            () => history.push(`/administrador/pacientes/${usuario.uidDoc}?origen=Pacientes`)
                                        }>Ver perfil</button>
                                        <button id='btn-eliminar' className='btn-gestionar btn-arketipos' onClick={() => setUsuarioPorEliminar(usuario, pacientes)}>Eliminar</button>
                                    </div>:
                                    <div className='gestionar-item-btns'>
                                        <button id='btn-asignar' className='btn-azul btn-gestionar btn-arketipos'
                                            onClick={() => descongelarUsuario(pacientes, usuario.uidDoc)}>Activar</button>
                                        <button id='btn-perfil' className='btn-azul btn-gestionar btn-arketipos' onClick={
                                            () => history.push(`/administrador/terapeutas/${usuario.uidDoc}`)
                                        }>Ver perfil</button>
                                        <button id='btn-eliminar' className='btn-gestionar btn-arketipos' onClick={() => setUsuarioPorEliminar(usuario, pacientes)}>Eliminar</button>
                                    </div>
                                }
                            </div>
                        ))
                    }
                </div>:
                <div className='gestionar-panel'>
                    {
                        Array.from(Array(10).keys()).map( (valor, index) => (
                                <div key={index} className="gestionar-item" style={{
                                    borderBottom: index === 9 ? 'none':'0.01px solid grey' 
                                }}>
                                    <div className={pacientes ? 'gestionar-nombre-img':'gestionar-nombre-img-terapeuta'}>
                                        <SkeletonResponsive tipo='circle' className='sk-profile-gestionar-img'/>
                                        <SkeletonResponsive tipo='rect' className='sk-nombre-usuario'/>
                                    </div>
                                    {
                                        pacientes ?
                                        <div className='gestionar-item-btns'>
                                            <SkeletonResponsive id='btn-asignar' tipo='rect' className='sk-btn-gestionar'/>
                                            <SkeletonResponsive id='btn-perfil' tipo='rect' className='sk-btn-gestionar'/>
                                            <SkeletonResponsive id='sk-btn-eliminar' tipo='rect' className='sk-btn-gestionar'/>
                                        </div>:
                                        <div className='gestionar-item-btns'>
                                            <SkeletonResponsive id='btn-perfil' tipo='rect' className='sk-btn-gestionar'/>
                                            <SkeletonResponsive id='sk-btn-eliminar' tipo='rect' className='sk-btn-gestionar'/>
                                        </div>
                                    }
                                </div>
                        ))                
                    }
                </div>
    );
}

Gestionar.propTypes = {

};

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);


export default withRouter(connect(null, mapDispatchToProps)(Gestionar));