import {TRAER_CHATS, CARGANDO_CHATS, ERROR_CHATS} from './../utilidades/Constantes';
import { createSelector } from 'reselect';

const INITIAL_STATE = {
    chats: null,
    cargandoChats: false,
    errorChats: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRAER_CHATS:{
            return  {...state, chats: action.payload, cargandoChats: false};
        }
        case CARGANDO_CHATS:{
            return  {...state, cargandoChats: action.payload};
        }
        case ERROR_CHATS:{
            return  {...state, errorChats: action.payload, cargandoChats: false};
        }
        default:
            return state;
    }
}

export const getChats = createSelector(state => state.chatReducer, chatReducer => chatReducer.chats)
export const getCargando = createSelector(state => state.chatReducer, chatReducer => chatReducer.cargandoChats)
export const getError = createSelector(state => state.chatReducer, chatReducer => chatReducer.errorChats)