import firebase from './Firebase'
import {OK, ERROR} from '../utilidades/Constantes'

const database = firebase.database()

export const  consultarDatos = (ruta, funcion) => {
    database.ref().child(ruta).on('value', snap =>{
        funcion(snap.val())
    });
}

export const escribirDatos = async(ruta, datos) => {
    try {
        database.ref(ruta).set(datos)
        return OK
    } catch (error) {
        console.log(error)
        return ERROR
    }
}

export const  escribirDatosUid = async(ruta, datos) => {
    try {
        database.ref(ruta).push().set(datos)
        return OK
    } catch (error) {
        console.log(error)
        return ERROR
    }
}

export const consultarDatosCampoEspecifico = (ruta, campo, valor, dispatch, etiqueta) => {
    try {
        database.ref().child(ruta).orderByChild(campo).equalTo(valor).on('value', snap => {
            dispatch({
                type: etiqueta,
                payload: snap.val()??{}
            })
        });
    } catch (error) {
        console.log(error)
        return ERROR
    }
}