import React from 'react'
import Integrante from './Integrante'
import PropTypes from 'prop-types'
import BotonAgenda from '../../general/BotonAgenda'

const Equipo = ({ integrantes, cargando }) => {

    return (
        <div className="fondo-azul center flex-column contenedor-equipo">
            <div className="letra-blanca bold letra-titulos">Nuestro Equipo</div>
            <div className="flex jc-space-around ai-start contenedor-integrantes">
                {cargando && <Integrante cargando={cargando} integrante={{}} />}
                {cargando && <Integrante cargando={cargando} integrante={{}} />}
                {cargando && <Integrante cargando={cargando} integrante={{}} />}
                {integrantes.map((integrante, i) => {
                    return <Integrante cargando={cargando} integrante={integrante} key={`integrante-${i}`} />
                })}
            </div>
            <div style={{ marginTop: '1rem' }}>
                <BotonAgenda textColor="blue" />
            </div>
        </div>
    )
}

Equipo.propTypes = {
    integrantes: PropTypes.object.isRequired,
    cargando: PropTypes.bool.isRequired,
}


export default Equipo