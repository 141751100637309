import { consultarColeccion, consultarDocumento, consultarDocumentoConSubcoleccionesSinEscuchador, crearDocumentoAutoID, editarDocumento, eliminarDocumento, obtenerDocumento } from '../proxy/FirebaseDB';
import { eliminarArchivoUrl, subirArchivo } from '../proxy/FirebaseStorage';

export const GET_HOME = 'GET_HOME';
export const GET_ADS = 'GET_ADS';
export const GET_TEAM = 'GET_TEAM';

export const GET_SERVICIOS = 'GET_SERVICIOS';
export const GET_CONSULTANCY = 'GET_CONSULTANCY';
export const GET_FORMATION = 'GET_FORMATION';
export const GET_SOCIAL_WORK = 'GET_SOCIAL_WORK';
export const GET_PSYCHOLOGY = 'GET_PSYCHOLOGY';

export const GET_NOSOTROS = 'GET_NOSOTROS';

export const LOADING_HOME = 'LOADING_HOME';

export const LOADING_SERVICIOS = 'LOADING_SERVICIOS';
export const LOADING_NOSOTROS = 'LOADING_NOSOTROS';
export const GUARDANDO_CAMBIO = 'GUARDANDO_CAMBIO';

const RUTA_HOME = 'landing/home'
const RUTA_SERVICIOS = 'landing/servicios'
const RUTA_NOSOTROS = 'landing/nosotros'


export const getHome = () => {
    return async dispatch => {
        dispatch({
            type: LOADING_HOME,
            payload: true
        })

        consultarDocumento(RUTA_HOME, dispatch, GET_HOME)
        consultarColeccion(`${RUTA_HOME}/anuncios`, dispatch, GET_ADS)
        consultarColeccion(`${RUTA_HOME}/equipo`, dispatch, GET_TEAM)

        // consultarDocumentoConSubcoleccionesSinEscuchador(RUTA_HOME, ['anuncios', 'equipo'], dispatch, GET_HOME)
    }
}

export const getHomeLanding = () => {
    return async dispatch => {
        dispatch({
            type: LOADING_HOME,
            payload: true
        })

        consultarDocumentoConSubcoleccionesSinEscuchador(RUTA_HOME, ['anuncios', 'equipo'], dispatch, GET_HOME)
    }
}

export const getServicios = () => {
    return async dispatch => {
        dispatch({
            type: LOADING_SERVICIOS,
            payload: true
        })
    
        consultarDocumento(RUTA_SERVICIOS, dispatch, GET_SERVICIOS)
        consultarColeccion(`${RUTA_SERVICIOS}/consultoria`, dispatch, GET_CONSULTANCY)
        consultarColeccion(`${RUTA_SERVICIOS}/formacion`, dispatch, GET_FORMATION)
        consultarColeccion(`${RUTA_SERVICIOS}/laborSocial`, dispatch, GET_SOCIAL_WORK)
        consultarColeccion(`${RUTA_SERVICIOS}/psicologia`, dispatch, GET_PSYCHOLOGY)

        // consultarDocumentoConSubcoleccionesSinEscuchador(RUTA_SERVICIOS, ['consultoria', 'formacion', 'laborSocial', 'psicologia'], dispatch, GET_SERVICIOS)
    }
}

export const getServiciosLnading = () => {
    return async dispatch => {
        dispatch({
            type: LOADING_SERVICIOS,
            payload: true
        })
    
        consultarDocumentoConSubcoleccionesSinEscuchador(RUTA_SERVICIOS, ['consultoria', 'formacion', 'laborSocial', 'psicologia'], dispatch, GET_SERVICIOS)
    }
}

export const getNosotros = () => {
    return async dispatch => {
        dispatch({
            type: LOADING_NOSOTROS,
            payload: true
        })
        const documento = await obtenerDocumento(RUTA_NOSOTROS)
        dispatch({
            type: GET_NOSOTROS,
            payload: documento
        })
    }
}

export const saveHomeDocument = (documento, nombre) => {
    return async dispatch => {

        dispatch({
            type: GUARDANDO_CAMBIO,
            payload: true
        })

        if(documento.imagenSegundaSeccion){
            let ruta = documento.imagenSegundaSeccion
            if(documento.imagenArchivo){
                await eliminarArchivoUrl(documento.urlAnterior)
                ruta = await subirArchivo(`landing/home/${documento.imagenArchivo.name}`, documento.imagenArchivo)
                delete documento.imagenArchivo
                delete documento.urlAnterior
            }
            documento.imagenSegundaSeccion = ruta
        }
        if(nombre)
            await editarDocumento(`landing/${nombre}`, documento)
        else
            await editarDocumento('landing/home', documento)

        dispatch({
            type: GUARDANDO_CAMBIO,
            payload: false
        })

    }
}

export const updateAd = (id, documento, servicio) => {
    return async dispatch => {

        dispatch({
            type: GUARDANDO_CAMBIO,
            payload: true
        })

        let ruta = documento.imagen
        if(documento.imagenArchivo){
            await eliminarArchivoUrl(documento.urlAnterior)
            if(servicio)
                ruta = await subirArchivo(`landing/servicios/${servicio}/${documento.imagenArchivo.name}`, documento.imagenArchivo)
            else
                ruta = await subirArchivo(`landing/home/anuncios/${documento.imagenArchivo.name}`, documento.imagenArchivo)
            delete documento.imagenArchivo
            delete documento.urlAnterior
        }
        documento.imagen = ruta
        if(servicio)
            await editarDocumento(`landing/servicios/${servicio}/${id}`, documento)
        else
            await editarDocumento(`landing/home/anuncios/${id}`, documento)

        dispatch({
            type: GUARDANDO_CAMBIO,
            payload: false
        })

    }
}

export const createAd = (documento, servicio) => {
    return async dispatch => {

        dispatch({
            type: GUARDANDO_CAMBIO,
            payload: true
        })

        let ruta = ''
        if(documento.imagenArchivo){
            if(documento.urlAnterior) await eliminarArchivoUrl(documento.urlAnterior)
            if(servicio)
                ruta = await subirArchivo(`landing/servicios/${servicio}/${documento.imagenArchivo.name}`, documento.imagenArchivo)
            else
                ruta = await subirArchivo(`landing/home/anuncios/${documento.imagenArchivo.name}`, documento.imagenArchivo)
            delete documento.imagenArchivo
            delete documento.urlAnterior
        }
        documento.imagen = ruta
        if(servicio)
            await crearDocumentoAutoID(`landing/servicios/${servicio}/`, documento)
        else
            await crearDocumentoAutoID(`landing/home/anuncios/`, documento)

        dispatch({
            type: GUARDANDO_CAMBIO,
            payload: false
        })

    }
}
export const deleteAd = (id, documento, servicio) => {
    return async dispatch => {

        dispatch({
            type: GUARDANDO_CAMBIO,
            payload: true
        })

        await eliminarArchivoUrl(documento.imagen)

        if(servicio)
            await eliminarDocumento(`landing/servicios/${servicio}/${id}`)
        else
            await eliminarDocumento(`landing/home/anuncios/${id}`)

        dispatch({
            type: GUARDANDO_CAMBIO,
            payload: false
        })

    }
}

export const updateMember = (id, documento) => {
    return async dispatch => {

        dispatch({
            type: GUARDANDO_CAMBIO,
            payload: true
        })

        let ruta = documento.foto
        if(documento.imagenArchivo){
            await eliminarArchivoUrl(documento.urlAnterior)
            ruta = await subirArchivo(`landing/home/equipo/${documento.imagenArchivo.name}`, documento.imagenArchivo)
            delete documento.imagenArchivo
            delete documento.urlAnterior
        }
        documento.foto = ruta
        await editarDocumento(`landing/home/equipo/${id}`, documento)

        dispatch({
            type: GUARDANDO_CAMBIO,
            payload: false
        })

    }
}

export const createMember = (documento) => {
    return async dispatch => {

        dispatch({
            type: GUARDANDO_CAMBIO,
            payload: true
        })

        let ruta = ''
        if(documento.imagenArchivo){
            if(documento.urlAnterior) await eliminarArchivoUrl(documento.urlAnterior)
            ruta = await subirArchivo(`landing/home/equipo/${documento.imagenArchivo.name}`, documento.imagenArchivo)
            delete documento.imagenArchivo
            delete documento.urlAnterior
        }
        documento.imagen = ruta
        await crearDocumentoAutoID(`landing/home/equipo/`, documento)

        dispatch({
            type: GUARDANDO_CAMBIO,
            payload: false
        })

    }
}
export const deleteMember = (id, documento) => {
    return async dispatch => {

        dispatch({
            type: GUARDANDO_CAMBIO,
            payload: true
        })

        await eliminarArchivoUrl(documento.imagen)

        await eliminarDocumento(`landing/home/equipo/${id}`)

        dispatch({
            type: GUARDANDO_CAMBIO,
            payload: false
        })

    }
}

export const updateService = (id, documento) => {
    return async dispatch => {

        dispatch({
            type: GUARDANDO_CAMBIO,
            payload: true
        })

        await editarDocumento(`landing/servicios/consultoria/${id}`, documento)

        dispatch({
            type: GUARDANDO_CAMBIO,
            payload: false
        })

    }
}

export const createService = (documento) => {
    return async dispatch => {

        dispatch({
            type: GUARDANDO_CAMBIO,
            payload: true
        })

        await crearDocumentoAutoID(`landing/servicios/consultoria/`, documento)

        dispatch({
            type: GUARDANDO_CAMBIO,
            payload: false
        })

    }
}
export const deleteService = (id) => {
    return async dispatch => {

        dispatch({
            type: GUARDANDO_CAMBIO,
            payload: true
        })

        await eliminarDocumento(`landing/servicios/consultoria/${id}`)

        dispatch({
            type: GUARDANDO_CAMBIO,
            payload: false
        })

    }
}