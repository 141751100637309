import React from 'react';
import PropTypes from 'prop-types';

const DialogoDosBotones = ({titulo, descripcion, boton1, boton2, click1, click2, cerrar, color}) => {

    return (
        <div className="fondo-dialogo-generico">
            <div className="fondo-dialogo-rc" onClick={cerrar}/>
            <div className="dialogo">
                <h1 className="titulo-dialogo">{titulo}</h1>
                <div>
                    <p className="descripcion-dialogo">
                        {descripcion}
                    </p>
                </div>
                <div className="botones-dialogo-dos">
                    <button id="btn-primario" type="button" className={`boton boton-dialogo fondo-borde-${color??'azul'}`} onClick={click1}>
                        {boton1}
                    </button>
                    <button id="btn-secundario" type="button" className={`boton boton-dialogo fondo-borde-${color??'azul'}`} onClick={click2}>
                        {boton2}
                    </button>
                </div>
            </div>
        </div>
    )
};

DialogoDosBotones.propTypes = {
    titulo: PropTypes.string.isRequired,
    descripcion: PropTypes.string.isRequired,
    boton1: PropTypes.string.isRequired,
    boton2: PropTypes.string.isRequired,
    click1: PropTypes.func.isRequired,
    click2: PropTypes.func.isRequired,
    cerrar: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired
};

export default DialogoDosBotones;