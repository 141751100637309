import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import { withRouter, Redirect, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SkeletonResponsive from "../../../../general/SkeletonResponsive";
import { getPacienteTerapias, getTerapiaPaciente, getTareasTerapia } from "../../../../../reducers/adminReducer"
import { traerTareasTerapiasPaciente } from "../../../../../actions/adminActions";

const TareasTerapia = (props) => {
    const { history, detalleTerapia, paciente, tareas, traerTareas, esPsicologo } = props
    const { idTerapeuta, idPaciente, idTerapia } = useParams()

    useEffect(() => {
        if (!tareas)
            traerTareas({ paciente: idPaciente, psicologo: idTerapeuta, terapia: idTerapia })
    });

    const pintarTareas = (listaTareas) => (
        listaTareas.map((tareas) => {
            return <div className="con-tareas" key={tareas.uidDoc}>
                <p className="texto">{tareas.nombre}</p>
                <p className="editar"
                    onClick={() =>{ 
                        if(esPsicologo)
                            history.push(`/administrador/terapeutas/${idTerapeuta}/pacientes/${idPaciente}/terapias/${idTerapia}/tareas/${tareas.uidDoc}`)
                        else
                            history.push(`/administrador/pacientes/${idPaciente}/terapeutas/${idTerapeuta}/terapias/${idTerapia}/tareas/${tareas.uidDoc}`)
                    }}>Revisar</p>
            </div>
        }
        ))

    if (!paciente || !detalleTerapia)
        return <Redirect to={esPsicologo ? `/administrador/terapeutas/${idTerapeuta}/pacientes/${idPaciente}`
            : `/administrador/pacientes/${idPaciente}/terapeutas/${idTerapeuta}`} />
    return (
        <div className="contenedor-detalle-terapia">
            <div className="fila-atras" onClick={() => {
                history.goBack();
            }}>
                {detalleTerapia ? <button className="btn-arketipos btn-administrador btn-atras">←</button>
                    : <SkeletonResponsive tipo={'rect'} className='sk-btn-atras' />}
                {detalleTerapia ? <h5 className="texto-atras">{`Terapias ${paciente.nombre}`}</h5>
                    : <SkeletonResponsive tipo={'rect'} className='sk-texto-atras' />}
            </div>
            <div className="titulo-tarea">
                {detalleTerapia ? <h1 className="titulo-terapias">{detalleTerapia.nombre}</h1>
                    : <SkeletonResponsive tipo={'rect'} className='sk-titulo-terapias' />}
            </div>
            {detalleTerapia ? <p className="descripcion-terapia">{detalleTerapia.descripcion}</p>
                : <div className="sk-fila-texto">
                    <SkeletonResponsive tipo={'rect'} className='sk-descripcion-terapia-1' />
                    <SkeletonResponsive tipo={'rect'} className='sk-descripcion-terapia-2' />
                </div>}
            <div className="contenedor-tareas">
                {tareas ?
                    tareas.length > 0 ? pintarTareas(tareas)
                        :
                        <div className="sin-tareas">
                            <p className="texto">No hay tareas creadas</p>
                        </div>
                    : <div>
                        <SkeletonResponsive tipo={'rect'} className='sk-tarea' />
                        <SkeletonResponsive tipo={'rect'} className='sk-tarea' />
                        <SkeletonResponsive tipo={'rect'} className='sk-tarea' />
                        <SkeletonResponsive tipo={'rect'} className='sk-tarea' />
                        <SkeletonResponsive tipo={'rect'} className='sk-tarea' />
                    </div>
                }
            </div>
        </div>
    );
}

TareasTerapia.propTypes = {
    esPsicologo: PropTypes.bool.isRequired,
};


const mapStateToProps = (state, props) => ({
    detalleTerapia: getTerapiaPaciente(state, props.match.params.idPaciente, props.match.params.idTerapeuta, props.match.params.idTerapia),
    tareas: getTareasTerapia(state, props.match.params.idPaciente, props.match.params.idTerapeuta, props.match.params.idTerapia),
    paciente: getPacienteTerapias(state, props.match.params.idPaciente),
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        traerTareas: traerTareasTerapiasPaciente,
    }, dispatch);


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TareasTerapia));
