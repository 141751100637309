import {
    consultarColeccion,
    consultarDocumento,
    consultarColeccionCampoEspecifico,
    editarDocumento,
    consultarDocumentoConSubcolecciones,
    escrituraPorLotes,
    obtenerDocumento, consultarColeccionCampoEspecificoRestricciones
} from '../proxy/FirebaseDB';
import { funcionEnviarCorreo } from '../proxy/FirebaseFunctions';
import { eliminarArchivoUrl, subirArchivo } from '../proxy/FirebaseStorage';
import { TAREA_POR_REVISAR, UPDATE } from '../utilidades/Constantes';
import { rand_code } from '../utilidades/fechas';

export const GET_DB_PACIENTE = 'GET_DB_PACIENTE';
export const GET_DB_CITAS = 'GET_DB_CITAS';
export const GET_DB_TERAPIAS = 'GET_DB_TERAPIAS';
export const GET_DB_DETALLE_TERAPIA = 'GET_DB_DETALLE_TERAPIA';
export const GET_DB_TERAPEUTA = 'GET_DB_TERAPEUTAS';
export const GET_DB_CITAS_PACIENTE = 'GET_DB_CITAS_PACIENTE';
export const CARGANDO_PERFIL_TERAPEUTA = 'CARGANDO_PERFIL_TERAPEUTA';
export const GET_DB_PERFIL_TERAPEUTA = 'GET_DB_PERFIL_TERAPEUTA';
export const GET_DB_ACTIVIDADES_PACIENTE = 'GET_DB_ACTIVIDADES_PACIENTE';
export const CARGANDO_PACIENTE = 'CARGANDO_PACIENTE';
export const CARGANDO_TERAPIAS = 'CARGANDO_TERAPIAS';
export const CARGANDO_CITAS = 'CARGANDO_CITAS';
export const CARGANDO_ACTIVIDADES = 'CARGANDO_ACTIVIDADES';
export const DELETE_TERAPIA_ACTUAL = 'DELETE_TERAPIA_ACTUAL';

export const getCitas = payload => {
    return dispatch => {
        consultarColeccionCampoEspecifico("citas", "idPaciente", payload, dispatch, GET_DB_CITAS)
    }
}

export const getInfoPaciente = payload => {
    return async (dispatch, getState) => {
        let estado = getState().pacienteReducer
        if(!estado.cargandoPaciente && !estado.paciente){
            dispatch({
                type: CARGANDO_PACIENTE,
                payload: true
            })
            consultarDocumentoConSubcolecciones(`pacientes/${payload}`, ['terapias', 'psicologos'], dispatch, GET_DB_PACIENTE)
        }
        if(!estado.cargandoTerapias && !estado.terapias){
            dispatch({
                type: CARGANDO_TERAPIAS,
                payload: true
            })
            consultarColeccionCampoEspecificoRestricciones('terapiasActivas',
                [
                    {campo: 'idPaciente', valor: payload},
                    {campo: 'finalizada', valor: false}
                    ], dispatch, GET_DB_TERAPIAS)
        }
    }
}

export const deleteTerapiaActual = () => ({
    type: DELETE_TERAPIA_ACTUAL,
    payload: null,
})

export const seleccionarTerapia = (payload) => dispatch =>{
    dispatch({
        type: GET_DB_DETALLE_TERAPIA,
        payload: payload
    })
}

export const getDBCitasPaciente = payload => {
    return (dispatch, getState) => {
        let estado = getState().pacienteReducer

        if(!estado.cargandoCitas && !estado.citas){
            dispatch({
                type: CARGANDO_CITAS,
                payload: true
            })
            consultarColeccionCampoEspecifico(`citas`, 'idPaciente', payload, dispatch, GET_DB_CITAS_PACIENTE)
        }
    }
}

export const getDetalleTerapia = payload => {
    return dispatch => {
        consultarDocumentoConSubcolecciones(`terapiasActivas/${payload}`, ['tareas'], dispatch, GET_DB_DETALLE_TERAPIA)
    }
}

export const getActividades = payload => {
    return dispatch => {
        dispatch({
            type: CARGANDO_ACTIVIDADES,
            payload: true
        })
        consultarColeccion(`terapiasActivas/${payload.terapia}/tareas/${payload.tarea}/actividades`, dispatch, GET_DB_ACTIVIDADES_PACIENTE)
    }
}

export const getTerapeuta = payload => {
    return dispatch => {
        consultarDocumento(`psicologos/${payload}`, dispatch, GET_DB_TERAPEUTA)
    }
}

export const updateAdminProfileInfo = payload => {
    return dispatch => {
        let uidAdmin = payload.uidDocumento;
        delete payload.uidDocumento;
        editarDocumento(`usuarios/${uidAdmin}`, payload);
    }
}

export const updatePacienteProfileInfo = async payload => {
    let uidPaciente = payload.uidDocumento
    delete payload.uidDocumento
    
    let res = await escrituraPorLotes([
        {
            ruta: `pacientes/${uidPaciente}`,
            tipo: UPDATE,
            data: payload
        },
        {
            ruta: `usuarios/${uidPaciente}`,
            tipo: UPDATE,
            data: payload
        }
    ])
    
    return res
}

export const consultarPerfilTerapeuta = payload => {
    return async (dispatch, getState) => {
        let state = getState()

        if(state.pacienteReducer.cargandoPerfilTerapeuta)
            return

        dispatch({
            type: CARGANDO_PERFIL_TERAPEUTA,
            payload: true
        })

        return await consultarDocumento(`psicologos/${payload.idPsicologo}`, dispatch, GET_DB_PERFIL_TERAPEUTA)
    }
}

export const guardarActividad = async (payload) => {
    
    let actividad = payload.actividad
    let url = ''
    if(payload.actividad.adjunto){

        let rand_cd = rand_code(6)

        url = await subirArchivo(
            `Terapias/${payload.terapia}/${payload.tarea}/${payload.paciente}/${rand_cd}-${actividad.adjunto.name}`,
            payload.actividad.adjunto
        )
        
        if(actividad.archivoEntrega){
            await eliminarArchivoUrl(actividad.archivoEntrega.url)
        }
        actividad = {
            respuesta: actividad.respuesta??null,
            archivoEntrega: {
                nombre: actividad.adjunto.name,
                url: url
            },
            entregado: true
        }
    }
    else{
        actividad = {
            respuesta: payload.actividad.respuesta??null,
            archivoEntrega: payload.actividad.archivoEntrega??null,
            entregado: payload.actividad.entregado??true
        }
    }
    await editarDocumento(
        `terapiasActivas/${payload.terapia}/tareas/${payload.tarea}/actividades/${payload.actividad.uidDoc}`,
        actividad
    )
    await editarDocumento(
        `terapiasActivas/${payload.terapia}`,
        {revisar: true, tareaActual: false}
    )
    await editarDocumento(
        `psicologos/${payload.terapeuta}/pacientes/${payload.paciente}`,
        {revisar: true, estado: 2}
    )
    traerTerapeutaCorreo(payload.correo, payload.terapeuta)
}


export const traerTerapeutaCorreo = async(correo, id) => {

    let terapeuta = await obtenerDocumento(`psicologos/${id}`)
    funcionEnviarCorreo({
        ...correo,
        nombrePsicologo: terapeuta.nombre,
        email: terapeuta.correo,
        tipo: TAREA_POR_REVISAR
    })
}