import React, { useState } from 'react';
import DialogoCita from './dialogoCita/DialogoCita';
import ItemCita from './itemCita/ItemCita';
import SkeletonResponsive from '../../general/SkeletonResponsive';
// import PropTypes from 'prop-types';

const Citas = ({ citas, pacientes, usuario }) => {

    let [ creandoCita, setCreandoCita ] = useState(false)
    let [ citaEdicion, setCitaEdicion ] = useState(null)

    const mostrarDialogoCita = () => setCreandoCita(true)

    const mostrarDialogoEditarCita = cita => {
        setCitaEdicion(cita)
    }

    const ocultarDialogoCita = () => setCreandoCita(false)

    const ocultarDialogoEditarCita = () => setCitaEdicion(null)

    const renderSkeletonCita = () => {
        return Array.from(Array(4).keys()).map(() => <div className={`contenedor-cita contenedor-cita-${usuario}`}>
        <SkeletonResponsive tipo='rect' className='img sk-img-fecha'/>
        <SkeletonResponsive tipo='rect' className='sk-fecha-cita'/>
        {
            usuario === 'terapeuta' &&
            <>
                <SkeletonResponsive tipo='rect' className='sk-editar-cita'/>
                <SkeletonResponsive tipo='rect' className='sk-eliminar-cita'/>
                <SkeletonResponsive tipo='rect' className={`sk-link-cita sk-link-cita-${usuario}`}/>
            </>
        }
        <SkeletonResponsive tipo='rect' className={`sk-img-link sk-img-link-${usuario}`}/>
        <SkeletonResponsive tipo='circle' className='sk-img-perfil-cita'/>
        <SkeletonResponsive tipo='rect' className='sk-nombre-paciente-cita'/>
      </div>)
    }

    return (
        <div className="contenedor-citas">
            <div className={`titulo-citas titulo-${usuario}`}>
                {
                    usuario === 'terapeuta' && 'Citas'
                }
                {
                    usuario === 'paciente' && 'Próximas citas'
                }
                {
                    usuario === 'terapeuta' && (citas ?
                        <button className={`btn-arketipos btn-agregar-cita ${usuario}`} onClick={mostrarDialogoCita}>Agendar Cita</button>:
                        <SkeletonResponsive tipo='rect' className='sk-btn-agregar-cita'/>)
                }
            </div>
            {
                citas ?
                    citas.length === 0 ?
                        <div className='contenedor-sin-citas'>
                            {
                                usuario === 'terapeuta' ?
                                    'No has agendado ninguna cita':
                                    'No tienes citas programadas'
                            }
                        </div>:
                        citas.map( (cita, index) => <ItemCita key={index} usuario={usuario} mostrarDialogoEditarCita={mostrarDialogoEditarCita} cita ={cita}/>):    
                    renderSkeletonCita()      
            }
            {
                creandoCita &&
                <DialogoCita
                    pacientes={pacientes} 
                    cerrarDialogoHandleClick={ocultarDialogoCita}/>
            }
            {
                citaEdicion &&
                <DialogoCita
                    pacientes={pacientes} 
                    cerrarDialogoHandleClick={ocultarDialogoEditarCita}
                    citaEdicion={citaEdicion}/>
            }
        </div>
    )
}

export default Citas;