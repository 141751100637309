import { consultarDatosCampoEspecifico, escribirDatos, escribirDatosUid } from "../proxy/FirebaseRealTime"
import { CARGANDO_CHATS, TRAER_CHATS } from "../utilidades/Constantes"

export const crearChat = (datos) => async(dispatch) =>{

    await escribirDatosUid('chats', datos)

}

export const enviarMensaje = (id, mensaje) => (dispatch) =>{

    escribirDatos(`chats/${id}/mensajes/${Date.now()}`, mensaje)

}

export const traerChats = (id, tipo) => (dispatch) =>{
    dispatch({
        type: CARGANDO_CHATS,
        payload: true
    })
    consultarDatosCampoEspecifico('chats', tipo?'psicologo':'paciente', id, dispatch, TRAER_CHATS)
}
