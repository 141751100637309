import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import arketipo_amarillo from '../../../assets/intelectual.svg'
import Textos from './components/textos/Textos'
import { getLoadingNosotros, getNosotrosState } from '../../../reducers/landingReducer'
import { getNosotros } from '../../../actions/landingActions'
import { bindActionCreators } from 'redux'
import SkeletonResponsive from '../../general/SkeletonResponsive'
import BotonAgenda from '../general/BotonAgenda'

const Nosotros = ({ nosotros, cargando, traerNosotros }) => {

    useEffect(() => {
        if (!nosotros && !cargando) traerNosotros()
        // eslint-disable-next-line
    }, [])

    const info = nosotros ?? {}
    console.log(info)


    return (
        <div className="contenedor-landing">
            <div className="priemra-division division-gris-nosotros fondo-gris-claro center">
                <div className="imagen-nosotros center">
                    <img src={arketipo_amarillo} alt="arketipo" />
                </div>
                {cargando ? <SkeletonResponsive tipo='text' className="sk-texto-sentido" /> : <div>
                    <div className="letra-grande letra-seccion-nosotros bold">
                        {info.tituloGrande ?? ''}
                    </div>
                    <div className='center'>
                        <BotonAgenda bgColor="yellow" textColor="white" />
                    </div>
                </div>}
            </div>
            <Textos cargando={cargando} textos={nosotros ?? {}} />
            <div style={{ margin: '1rem 0 2rem' }} className='w-full center'>
                <BotonAgenda bgColor="yellow" textColor="white" />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    nosotros: getNosotrosState(state),
    cargando: getLoadingNosotros(state)
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    traerNosotros: getNosotros
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Nosotros)
